import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Transfer, Table } from 'antd';
import difference from 'lodash/difference';

const leftTableColumns = [
  {
    dataIndex: 'entity',
    title: 'Entity',
    render: (entity) => (entity ? entity.name : null),
  },
  {
    dataIndex: 'name',
    title: 'Ranch',
  },
];
const rightTableColumns = leftTableColumns;

const TableTransfer = ({
  leftColumns, rightColumns, loading, ...restProps
}) => (
  <Transfer {...restProps} showSelectAll={false}>
    {({
      direction,
      filteredItems,
      onItemSelectAll,
      onItemSelect,
      selectedKeys: listSelectedKeys,
      disabled: listDisabled,
    }) => {
      const columns = direction === 'left' ? leftColumns : rightColumns;

      const rowSelection = {
        getCheckboxProps: (item) => ({ disabled: listDisabled || item.disabled }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter((item) => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, listSelectedKeys)
            : difference(listSelectedKeys, treeSelectedKeys);
          onItemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          onItemSelect(key, selected);
        },
        selectedRowKeys: listSelectedKeys,
      };

      return (
        <Table
          loading={loading}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={filteredItems}
          size="small"
          // pagination={{ position: 'top' }}
          style={{ pointerEvents: listDisabled ? 'none' : null }}
          onRow={({ key, disabled: itemDisabled }) => ({
            onClick: () => {
              if (itemDisabled || listDisabled) return;
              onItemSelect(key, !listSelectedKeys.includes(key));
            },
          })}
        />
      );
    }}
  </Transfer>
);

const columnShape = PropTypes.shape({
  dataIndex: PropTypes.string,
  title: PropTypes.string,
});

TableTransfer.propTypes = {
  leftColumns: PropTypes.arrayOf(columnShape),
  rightColumns: PropTypes.arrayOf(columnShape),
  loading: PropTypes.bool,
};

TableTransfer.defaultProps = {
  leftColumns: [],
  rightColumns: [],
  loading: false,
};

export default function RanchTransfer(props) {
  const {
    handleChange,
    loading,
    targetKeys,
    ranches,
  } = props;

  const [selectedKeys, setSelectedKeys] = useState([]);

  const onChange = (nextTargetKeys, direction, moveKeys) => {
    handleChange(nextTargetKeys);
  };

  const handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const keyById = (arr) => arr.map((item) => ({ ...item, key: item.id }));

  const sortByName = (arr) => arr.sort((a, b) => {
    if (!a.entity || !a.entity.name) return b;
    if (!b.entity || !b.entity.name) return a;
    return a.entity.name.localeCompare(b.entity.name);
  });

  return (
    <div className="content-container">
      <TableTransfer
        style={{ flex: '1 1', display: 'flex' }}
        listStyle={{ flex: '1 1 200px', display: 'flex', flexDirection: 'column' }}
        loading={loading}
        dataSource={sortByName(keyById(ranches))}
        titles={['Available', 'Visible']}
        targetKeys={targetKeys}
        selectedKeys={selectedKeys}
        onChange={onChange}
        onSelectChange={handleSelectChange}
        showSearch
        filterOption={(inputValue, item) => {
          const inputLower = inputValue.toLowerCase();
          return (
            (item.name && item.name.toLowerCase().indexOf(inputLower) !== -1)
            || (item.entity && item.entity.name
              && item.entity.name.toLowerCase().indexOf(inputLower) !== -1)
          );
        }}
        leftColumns={leftTableColumns}
        rightColumns={rightTableColumns}
      />
    </div>
  );
}
