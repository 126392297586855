import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  PageHeader,
  Layout,
} from 'antd';

import './sensorDetailPage.css';

import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'farmx-redux-core';

const {
  selectSensorErrors,
} = selectors;

export function SensorErrorsPage(props) {
  const {
    match,
  } = props;

  const dispatch = useDispatch();

  const { sensorIdentifier, sensorType } = match.params;
  const sensorParams = ({ type: sensorType, identifier: sensorIdentifier });
  const { type, identifier } = sensorParams;

  const sensorErrors = useSelector((state) => (
    selectSensorErrors(state, sensorType, sensorIdentifier)));

  useEffect(() => {
    if (type && identifier) {
      dispatch(actions.loadSensorErrors({ type, identifier }));
    }
  }, [type, identifier, dispatch]);

  function sensorTitle() {
    const sensorTypeName = type;
    return `[${sensorTypeName}] ${identifier}`;
  }

  function renderPageContent() {
    if (!sensorErrors || !sensorErrors.length) {
      return (
        <div>No Errors found</div>
      );
    }
    return (
      <ul>
        {sensorErrors.map((error) => (
          <li key={error[0]}>
            <p>{error[0]}</p>
          </li>
        ))}
      </ul>
    );
  }

  return (
    <Layout>
      <PageHeader
        title="Validation Errors"
        subTitle={[
          sensorTitle(),
        ]}
      />
      <div className="padded">
        {renderPageContent()}
      </div>
    </Layout>
  );
}

SensorErrorsPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      sensorIdentifier: PropTypes.string,
      sensorType: PropTypes.string,
    }),
  }),
};

SensorErrorsPage.defaultProps = {
  match: {
    params: {},
  },
};
