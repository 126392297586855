import React from 'react';
import RanchTransfer from '../farm/components/RanchTransfer';
import './settings.css';

import { connect } from 'react-redux';
import { actions, selectors } from 'farmx-redux-core';
import { userApi } from 'farmx-api';

const { loadFarmData } = actions;
const {
  selectAllRanches,
  selectFarmDataLoadingState,
} = selectors;
const { getUserAppConfig, updateUserAppConfig } = userApi;

export class AppConfig extends React.Component {

  state = {
    targetRanches: [],
    loading: true,
  };

  componentDidMount = async () => {
    this.props.loadFarmData();

    const response = await getUserAppConfig();
    this.setState({
      targetRanches: response.ranches,
      loading: false,
    });
  }

  handleChange = (targetRanches) => {
    this.setState({ targetRanches });
    updateUserAppConfig({ ranches: targetRanches });
  };

  render () {
    const { ranches, loadingState } = this.props;
    return (
      <div className="column">
        <h3>FarmMap Visible Ranches</h3>
        <RanchTransfer
          loading={loadingState.ranchesLoadingState.loading || this.state.loading}
          ranches={ranches}
          targetKeys={this.state.targetRanches}
          handleChange={this.handleChange}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    ranches: selectAllRanches(state),
    loadingState: selectFarmDataLoadingState(state),
  };  
}

const actionCreators = {
  loadFarmData,
};

export default connect(mapStateToProps, actionCreators)(AppConfig);
