/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import moment from 'moment';
import {
  Link,
} from 'react-router-dom';

import { helpers } from 'farmx-api';

const {
  getSensorUid,
} = helpers;

export const statusCellRenderer = (params) => {
  const status = params.value;
  if (!status) return null;
  let statusText = `${status.total_good} / ${status.total}`;
  if (status.total === 0) statusText = '-';
  const statusHtml = `<a href="/manage/cavalier?gateway=${params.data.address}">${statusText}</a>`;
  return statusHtml;
};

const getDateText = (params) => {
  const { value } = params;
  if (value === undefined || value === null) return null;
  const format = 'h:mma, D MMM YY';
  return moment(value).format(format);
};

const getEventHref = (params, period) => {
  const { sensor_type, identifier, anomaly_counts } = params.data;
  let startDate;
  const endDate = moment().toISOString();
  if (period === '24hr') {
    startDate = moment().subtract(24, 'hours').toISOString();
  } else if (period === '2wk') {
    startDate = moment().subtract(14, 'days').toISOString();
  }
  const href = `sensorType=${sensor_type}&sensorIdentifier=${identifier}&eventType=anomaly&startDate=${startDate}&endDate=${endDate}`;
  return { href, anomaly_counts };
};

export const dateCellRenderer = (params) => {
  const dateText = getDateText(params);
  if (!dateText) return null;
  const dateHtml = `<div>${dateText}</div>`;
  return dateHtml;
};

export const gatewayWeatherDateCellRenderer = (params) => {
  const dateText = getDateText(params);
  if (!dateText) return null;
  const url = `/manage/sensor?sensor_type=weather_station&gateway=${params.data.identifier}`;
  return `<a href="${url}" target="_blank">${dateText}</a>`;
};

export const lastServiceDateCellRenderer = (params) => {
  const dateText = getDateText(params);
  if (!dateText) return null;
  return dateText;
};

export const booleanCellRenderer = (params) => {
  if (params.value === true) {
    return 'yes';
  }
  return 'no';
};

export const locationCellRenderer = (params) => {
  if (!params.data.latitude || !params.data.longitude) return undefined;
  const value = `${params.data.latitude}, ${params.data.longitude}`;
  const gMapsValue = `${params.data.latitude},${params.data.longitude}`;
  return `<a href="https://www.google.com/maps/search/${gMapsValue}" target="_blank">${value}</a>`;
};

export const gatewayLocationCellRenderer = (params) => {
  if (!params.value) return undefined;
  const mapLink = `<a href="/map/gateway/${params.data.identifier}" target="_blank">${params.value}</a>`;
  // const googleMapsLink = '<a href="https://www.google.com/maps/search/' + replaceAll(params.value, " ", "") + '" target="_blank">'+ params.value + '</a>';
  return mapLink;
};

export function GatewayLocationCellRendererComponent(props) {
  const { value, data } = props;
  const mapLink = `/map/gateway/${data.identifier}`;
  return (
    <Link to={mapLink}>
      {value}
    </Link>
  );
}

const getSensorTypesForSensorSummaryField = (field) => {
  if (field === 'sensor_stats_soil') {
    return ['aquacheck_soil', 'pixl_soil'];
  }
  if (field === 'sensor_stats_pressure') {
    return ['water_pressure'];
  }
  // NOTE: ag grid does not support filtering on more than two text options at a time
  return [];
};

export const sensorStatsCellRenderer = (params) => {
  // sensor plan
  const hasPlan = params.data.has_plan;
  const sensorType = params.colDef.field.replace('sensor_stats_', '');
  const sensorTypePlanned = sensorType === 'cavalier' ? sensorType : `${sensorType}_sensor`;
  const plannedCount = hasPlan ? params.data[`planned_${sensorTypePlanned}_count`] : 0;

  const status = params.value;
  if (!status) return null;
  let statusText = `${status.online} / ${status.count} / ${plannedCount}`;
  if (status.count === 0 && plannedCount === 0) statusText = '-';

  const status24Field = `${params.colDef.field}_24`;
  const status24 = params.data[status24Field];
  // const diffCount = status.count - status24.count;
  const diffOnline = status.online - status24.online;
  const status24Text = diffOnline; // `${status24.online} / ${status24.count}`;

  if (status.count !== 0 && diffOnline !== 0) statusText = `${statusText} (${status24Text})`;

  const className = 'sensor-stats-link';
  const isCavalier = params.colDef.field === 'sensor_stats_cavalier';
  const sensorTypePath = (isCavalier) ? 'cavalier' : 'sensor';
  let url = `/manage/${sensorTypePath}?gateway=${params.data.identifier}`;
  if (!isCavalier) {
    const sensorTypes = getSensorTypesForSensorSummaryField(params.colDef.field);
    if (sensorTypes.length) {
      url += `&sensor_type=${sensorTypes.join(',')}`;
    }
  }
  const statusHtml = `<a class="${className}" href="${url}">${statusText}</a>`;
  return statusHtml;
};

const getDefaultVariablesForSensorType = (sensorType) => {
  if (sensorType === 'weather_station') {
    return [
      'air_temp',
      'solar_radiation',
    ];
  }
  return [];
};

export const sensorGraphCellRenderer = (params) => {
  const startDate = moment().startOf('day').subtract(6, 'days');
  const endDate = moment().startOf('day').add(1, 'day');
  const variables = getDefaultVariablesForSensorType(params.data.sensor_type);

  let url = `/manage/graph?sensorType=${params.data.sensor_type}&sensorIdentifier=${params.data.identifier}`;
  url += `&startDate=${startDate.toISOString()}`;
  url += `&endDate=${endDate.toISOString()}`;
  if (variables.length) {
    url += `&variables=${variables.join(',')}`;
  }
  return `<a href="${url}" target="_blank">View Graph</a>`;
};

const sensorDateGraphCellRenderer = (params, variables) => {
  const startDate = moment().startOf('day').subtract(6, 'days');
  const endDate = moment().startOf('day').add(1, 'day');
  let url = `/manage/graph?sensorType=gateway&sensorIdentifier=${params.data.identifier}&variables=${variables.join(',')}`;
  url += `&startDate=${startDate.toISOString()}`;
  url += `&endDate=${endDate.toISOString()}`;
  const dateText = getDateText(params) || '-';
  return `<a href="${url}" target="_blank">${dateText}</a>`;
};

export const powerDateGraphCellRenderer = (params) => {
  const variables = [
    'battery_voltage',
    'battery_current',
    'charge_controller_current',
    'charge_controller_voltage',
  ];
  return sensorDateGraphCellRenderer(params, variables);
};

export const lastOnlineCellRenderer = (params) => {
  const variables = [
    'internet_access',
    'frame_error_rate',
    'cell_rssi',
    'uptime',
  ];
  return sensorDateGraphCellRenderer(params, variables);
};

export const notesCellRenderer = (params) => {
  const sensor = {
    id: params.data.id,
    type: params.data.sensor_type ? params.data.sensor_type : 'gateway',
  };
  const [sensorType, sensorId] = getSensorUid(sensor).split(":");
  const identifier = params.data.identifier;  

  const url = `/manage/notes?sensor=${sensorType}&identifier=${identifier}`;
  return `<a href="${url}" target="_blank">${params.value}</a>`;
};

export const imagesCellRenderer = (params) => {
  const sensor = {
    id: params.data.id,
    type: params.data.sensor_type ? params.data.sensor_type : 'gateway',
  };
  const url = `/manage/images?sensor=${getSensorUid(sensor)}`;
  return `<a href="${url}" target="_blank">${params.value}</a>`;
};

export const entityCellRenderer = (params) => {
  if (!params.data.entity || params.data.entity === 'None') return null;
  const url = `/manage/sensor?entity=${params.data.entity}`;
  return `<a href="${url}" target="_blank">${params.data.entity}</a>`;
};

export const ranchCellRenderer = (params) => {

};

export const blockCellRenderer = (params) => {

};

export const gatewayCellRenderer = (params) => {
  if (!params.data.gateway || params.data.gateway === 'None') return null;
  const url = `/manage/node?gateway=${params.data.gateway}`;
  return `<a href="${url}" target="_blank">${params.data.gateway}</a>`;
};

export const nodeCellRenderer = (params) => {
  if (!params.data.node) return null;
  const url = `/manage/node?identifier=${params.data.node}`;
  return `<a href="${url}" target="_blank">${params.data.node}</a>`;
};

export const cavalierSensorsCellRenderer = (params) => {
  if (!params.data.sensors || !params.data.sensors.length) return 0;
  const url = `/manage/sensor?node=${params.data.identifier}`;
  return `<a href="${url}" target="_blank">${params.data.sensors.length}</a>`;
};

export const editSensorCellRendererHelper = (params, sensorType) => (
  `<a href="/detail/${sensorType}/${params.data.identifier}/" target="_blank">View</a>`
);

export const editBlockCellRenderer = (params) => (
  `<a href="/block/${params.data.id}/" target="_blank">View</a>`
);

export const editSensorCellRendererGateway = (params) => editSensorCellRendererHelper(params, 'gateway');

export const editSensorCellRenderer = (params) => (
  editSensorCellRendererHelper(params, params.data.sensor_type)
);

export const eventSensorCellRender = (params) => {
  const { sensor_type, identifier } = params.data;
  const href = `sensorType=${sensor_type}&sensorIdentifier=${identifier}`;
  return (`<a href="/events?${href}" target="_blank">View Events</a>`);
};

export const alarmCountCellRender = (params) => {
  const { sensor_type, identifier } = params.data;
  const href = `sensorType=${sensor_type}&sensorIdentifier=${identifier}&logLevel=error&active=true`;
  return (`<a href="/events?${href}" target="_blank">Active Errors</a>`);
};

export const dataAnomalyCount24hrCellRenderer = (params) => {
  const { href, anomaly_counts } = getEventHref(params, '24hr');
  return (`<a href="/events?${href}" target="_blank">${anomaly_counts?.data_anomalies_24hr}</a>`);
};

export const dataAnomalyCount2wkCellRenderer = (params) => {
  const { href, anomaly_counts } = getEventHref(params, '2wk');
  return (`<a href="/events?${href}" target="_blank">${anomaly_counts?.data_anomalies_2wk}</a>`);
};

export const sensorAnomalyCount24hrCellRenderer = (params) => {
  const { href, anomaly_counts } = getEventHref(params, '24hr');
  return (`<a href="/events?${href}" target="_blank">${anomaly_counts?.sensor_anomalies_24hr}</a>`);
};

export const sensorAnomalyCount2wkCellRenderer = (params) => {
  const { href, anomaly_counts } = getEventHref(params, '2wk');
  return (`<a href="/events?${href}" target="_blank">${anomaly_counts?.sensor_anomalies_2wk}</a>`);
};

export const softwareVersionCellRenderer = (params) => {
  if (!params.data.software_versions) return null;
  const key = params.colDef.field.replace('_sw', '');
  return params.data.software_versions[key];
};

export const numCavaliersCellRenderer = (params) => {
  const url = `/manage/cavalier?gateway=${params.data.identifier}`;
  return `<a href="${url}" target="_blank">${params.data.num_cavaliers}</a>`;
};

export const numSensorsCellRenderer = (params) => {
  const url = `/manage/sensor?gateway=${params.data.identifier}`;
  return `<a href="${url}" target="_blank">${params.data.num_sensors}</a>`;
};

export const numProvisionedCellRenderer = (params) => {
  const url = `/manage/sensor?gateway=${params.data.identifier}`;
  const text = `${params.data.num_provisioned} / ${params.data.num_sensors}`;
  return `<a href="${url}" target="_blank">${text}</a>`;
};

export const validationErrorsCellRenderer = (params) => {
  const url = `/manage/cavalier?gateway=${params.data.identifier}`;
  const text = `${params.data.validation_errors}`;
  return `<a href="${url}" target="_blank">${text}</a>`;
};

export const ipAddressCellRenderer = (params) => {
  if (!params.value) return undefined;
  const url = `ssh://farmx@${params.value}:220`;
  return `<a href="${url}" target="_blank">${params.value}</a>`;
};

export const uptimeCellRenderer = (params) => {
  if (!params.value) return undefined;
  const seconds = parseFloat(params.value);
  const duration = moment.duration(seconds, 'seconds');
  return duration.humanize();
};

export const updateKeysCellRenderer = (params) => 'Click to update';

export const errorsCellRenderer = (params) => {
  // params.$scope.showValidationErrorsModal = _showValidationErrorsModal;
  if (!params.value.length) {
    return '';
  }
  // params.$scope.errors = params.value;
  // return '<a ng-click="showValidationErrorsModal(errors)" target="_blank">View ' + params.value.length + ' Errors</a>';*/
  const url = `/errors/cavalier/${params.data.identifier}`;
  return `<a href="${url}" target="_blank">${params.value.length}</a>`;
};

export const createNoteCellRenderer = (params) =>
  /*
  params.$scope.showNotesFormModal = _showNotesFormModal;
  params.$scope.sensor = {
      id: params.data.id,
      type: "cavalier",
      identifier: params.data.radio_address,
  }
  return '<a ng-click="showNotesFormModal(sensor)" target="_blank"><i class="fa fa-edit" aria-hidden="true" title="Create Note"></a>';
  */
  null;


export const actionsCellRenderer = (params) =>
  /* params.$scope.showActionsModal = _showActionsModal;
  params.$scope.showNotesFormModal = _showNotesFormModal;
  params.$scope.refreshRow = refreshRow;
  params.$scope.cav = {
      id: params.data.id,
      type: "cavalier",
      identifier: params.data.radio_address,
  }
  params.$scope.sensors = params.data.sensors;
  var editLink = '<a href="/admin/sensor_network/cavaliernode/' + params.data.id + '/" target="_blank">Edit</a>';
  var actionsLink = '<a ng-click="showActionsModal(cav, sensors)" target="_blank"><i class="fa fa-tasks" aria-hidden="true" title="View Actions"></i></a>';
  var createNotesLink = '<a ng-click="showNotesFormModal(cav)" target="_blank"><i class="fa fa-edit" aria-hidden="true" title="Create Note"></i></a>';
  var refreshLink = '<a ng-click="refreshRow(cav)" target="_blank"><i class="fa fa-refresh" aria-hidden="true" title="Refresh Row"></i></a>';
  var spacer = " | ";
  return editLink + spacer + createNotesLink + spacer + actionsLink + spacer + refreshLink; */
  null;


export const imageCellRenderer = (params) => {
  const imageHtml = `<a href='${params.data.image}' target='_blank'><img src='${params.data.image}' style='height: 100px' /></a>`;
  return imageHtml;
};
