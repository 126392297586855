/* global L */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FeatureGroup } from 'react-leaflet';
import {
  Drawer,
  Button,
  Input,
  Select,
  Badge,
} from 'antd';
import { isMobile } from 'react-device-detect';
import { actions } from 'farmx-redux-core';
import explode from '@turf/explode';
import concave from '@turf/concave';
import buffer from '@turf/buffer';
import simplify from '@turf/simplify';
import { Map } from 'farmx-web-ui';
// eslint-disable-next-line no-unused-vars
import Draw from 'leaflet-draw';
import cloneDeep from 'lodash/cloneDeep';
import { FaExpand, FaExclamation } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { logger } from 'farmx-api';
import { plansEditSlice, plansEditStore } from './local-redux';
import { DrawFeatures } from './DrawFeatures';
import {
  createUUID,
  isLat,
  isLng,
  areValidCoords,
  getCoordsTxt,
  mapDataForPatching,
  calculateDynamicBoundaries,
  notifyError,
} from './utils';
import {
  getIcon,
  onFeatureClick,
  options,
  reDraw,
  setDynamicRanchBoundaries,
} from './helpers-map';

const { loadPlanById, patchPlan } = actions;

const {
  setSelectedFeature,
  setNewFeatureType,
  setNameForSelected,
  setSelectedSensorType,
  setSelectedCoordinates,
  setExpandedGeoJSON,
  setFeatureCollection,
  setSelectedObjectDescription,
} = plansEditSlice.actions;

export function PlansEditMap({
  isDrawerVisible,
  hasSelectedFeatureState,
  fGroupRef,
  ranchesGeoJSONState,
  expandedGeoJSONState,
  setViewTypeList,
  brokenMarkers,
  planId,
  selPlan,
  setRanchesGeoJSONState,
}) {
  const dispatch = useDispatch();
  const [isEditingBounds, setIsEditingBounds] = useState(false);
  const displayNoneStyle = { display: 'none' };
  const padding = {
    top: 30,
    left: 30,
    bottom: 30,
    right: 130,
  };

  if (isMobile) {
    padding.top = 10;
    padding.left = 10;
    padding.bottom = 10;
    padding.right = 10;
  }

  if (isDrawerVisible && isMobile) {
    padding.bottom = 300 + 10;
  }
  if (isDrawerVisible && !isMobile) {
    padding.right = 450;
  }

  return (
    <div style={{ width: '100%' }}>
      <Drawer
        className="plans-edit-drawer"
        // eslint-disable-next-line no-nested-ternary
        style={isMobile
          ? { minHeight: isDrawerVisible ? '200px' : '0px' }
          : { marginTop: '50px', minWidth: hasSelectedFeatureState ? '300px' : '0px' }}
        title={hasSelectedFeatureState && hasSelectedFeatureState.feature.properties.type
          ? (
            hasSelectedFeatureState.feature.properties.type.charAt(0).toUpperCase()
            + hasSelectedFeatureState.feature.properties.type.slice(1))
          : ''}
        placement={isMobile ? 'bottom' : 'right'}
        visible={isDrawerVisible}
        key="right"
        mask={false}
        closable={false}
      >
        {hasSelectedFeatureState && hasSelectedFeatureState.feature.properties.type === 'sensor' && (
          <div
            style={{
              marginBottom: '10px',
              width: '100%',
            }}
          >
            <Select
              id="select-sensor-type"
              style={{ width: 252 }}
              placeholder="Type of Sensor"
              value={hasSelectedFeatureState
                && hasSelectedFeatureState.feature.properties.sensorType}
              onChange={(v) => {
                fGroupRef.current.leafletElement.eachLayer((layer) => {
                  if (layer.feature && layer.feature.properties.id
                    === hasSelectedFeatureState.feature.properties.id) {
                    layer.setIcon(getIcon(true, v));
                  }
                });
                plansEditStore.dispatch(setSelectedSensorType(v));
              }}
            >
              {options}
            </Select>
          </div>
        )}

        {hasSelectedFeatureState && ['sensor', 'object'].includes(hasSelectedFeatureState.feature.properties.type) && (
          <>
            <div
              style={{
                marginBottom: '10px',
                width: '100%',
              }}
            >
              <Input
                id="feature-location"
                key="location"
                placeholder="location"
                value={hasSelectedFeatureState
                  && getCoordsTxt(hasSelectedFeatureState.feature.geometry.coordinates)}
                onChange={({ target: { value } }) => {
                  const v = value.trim().split(',');
                  if (v.length === 2) {
                    const [lat, lng] = v;
                    if (isLat(lat) && isLng(lng)) {
                      fGroupRef.current.leafletElement.eachLayer((layer) => {
                        if (layer.feature && layer.feature.properties.id
                          === hasSelectedFeatureState.feature.properties.id) {
                          layer.setLatLng(new L.LatLng(Number(lat), Number(lng)));
                        }
                      });
                      plansEditStore.dispatch(setSelectedCoordinates([lng, lat]));
                    } else {
                      plansEditStore.dispatch(setSelectedCoordinates([value, '']));
                    }
                  } else {
                    plansEditStore.dispatch(setSelectedCoordinates([value, '']));
                  }
                }}
              />
            </div>
          </>
        )}

        <div>
          <Input
            id="feature-name"
            key="name"
            placeholder="Name"
            value={hasSelectedFeatureState
              && hasSelectedFeatureState.feature.properties.name}
            onChange={(event) => {
              plansEditStore.dispatch(setNameForSelected(event.target.value));
            }}
          />
        </div>

        {hasSelectedFeatureState && hasSelectedFeatureState.feature.properties.type === 'object' && (
          <div>
            <Input
              id="feature-description"
              style={{
                marginTop: '10px',
              }}
              key="description"
              placeholder="Description"
              value={hasSelectedFeatureState
                && hasSelectedFeatureState.feature.properties.description}
              onChange={(event) => {
                plansEditStore.dispatch(setSelectedObjectDescription(event.target.value));
              }}
            />
          </div>
        )}

        <div
          style={{
            marginTop: '10px',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            id="drawer-delete-feature"
            style={hasSelectedFeatureState
              && hasSelectedFeatureState.feature.properties.creating
              ? displayNoneStyle
              : {}}
            danger
            disabled={!hasSelectedFeatureState}
            onClick={() => {
              const { selectedFeature } = plansEditStore.getState();
              selectedFeature.editing.disable();
              selectedFeature.off('click', onFeatureClick);
              fGroupRef.current.leafletElement.removeLayer(selectedFeature);

              const fGroupGeoJSON = cloneDeep(fGroupRef.current.leafletElement.toGeoJSON());

              const currBounds = cloneDeep(selPlan.data.meta?.ranchBounds);
              const newBounds = setDynamicRanchBoundaries(
                cloneDeep(fGroupGeoJSON),
                currBounds,
                null,
                null,
              );

              const data = mapDataForPatching({
                dataGeoJSON: cloneDeep(fGroupGeoJSON),
                dataOriginal: selPlan.data.plan,
                ranchBoundsGeoJSON: newBounds,
                featureToRemove: cloneDeep(selectedFeature.toGeoJSON()),
              });

              dispatch(patchPlan(data))
                .then((res) => {
                  if (res.error) {
                    notifyError(res?.error?.message);
                    return;
                  }
                  plansEditStore.dispatch(setSelectedFeature(null));
                  plansEditStore
                    .dispatch(setFeatureCollection(
                      fGroupRef.current.leafletElement.toGeoJSON(),
                    ));
                  dispatch(loadPlanById(planId));
                });
            }}
          >
            Delete
          </Button>
          <Button
            id="drawer-cancel-feature"
            disabled={!hasSelectedFeatureState}
            onClick={() => {
              const { selectedFeature } = plansEditStore.getState();

              fGroupRef.current.leafletElement.eachLayer((layer) => {
                if (layer.feature && layer.feature.properties.id
                  === selectedFeature.feature.properties.id) {
                  layer.editing.disable();
                  layer.off('click', onFeatureClick);
                  fGroupRef.current.leafletElement.removeLayer(layer);
                }
              });
              plansEditStore.dispatch(setSelectedFeature(null));
              dispatch(loadPlanById(planId));
            }}
          >
            Cancel
          </Button>
          <Button
            id="drawer-save-feature"
            type="primary"
            disabled={!hasSelectedFeatureState
              || (hasSelectedFeatureState
                && !hasSelectedFeatureState.feature.properties.name)
              || (hasSelectedFeatureState && hasSelectedFeatureState.feature.properties.type === 'sensor' && !hasSelectedFeatureState.feature.properties.sensorType)
              || (hasSelectedFeatureState && hasSelectedFeatureState.feature.properties.type === 'sensor' && !areValidCoords(hasSelectedFeatureState.feature.geometry.coordinates))}
            onClick={() => {
              const { selectedFeature } = plansEditStore.getState();
              selectedFeature.editing.disable();
              if (selectedFeature.feature.properties.creating) {
                selectedFeature.feature.properties.creating = false;
              }

              const selGeoJSON = selectedFeature.toGeoJSON();
              const fGroupGeoJSON = fGroupRef.current.leafletElement.toGeoJSON();
              const featureCollectionFeaturesLength = fGroupGeoJSON.features.length;
              for (let i = 0; i < featureCollectionFeaturesLength; i += 1) {
                if (fGroupGeoJSON.features[i].properties.id === selGeoJSON.properties.id) {
                  fGroupGeoJSON.features[i].properties.name = selGeoJSON.properties.name;
                  if (selGeoJSON.properties.type === 'sensor') {
                    // eslint-disable-next-line max-len
                    fGroupGeoJSON.features[i].properties.sensorType = selGeoJSON.properties.sensorType;
                  } else if (selGeoJSON.properties.type === 'object') {
                    fGroupGeoJSON.features[i].properties.description = selGeoJSON
                      .properties.description;
                  }
                  break;
                }
              }

              const currBounds = cloneDeep(selPlan.data.meta?.ranchBounds);
              const newBounds = calculateDynamicBoundaries(
                cloneDeep(fGroupGeoJSON),
                currBounds,
                null,
                null,
              );

              const data = mapDataForPatching({
                dataGeoJSON: cloneDeep(fGroupGeoJSON),
                dataOriginal: selPlan.data.plan,
                ranchBoundsGeoJSON: newBounds,
              });

              dispatch(patchPlan(data))
                .then((res) => {
                  if (res.error) {
                    notifyError(res?.error?.message);
                    return;
                  }
                  plansEditStore.dispatch(setSelectedFeature(null));
                  plansEditStore.dispatch(setFeatureCollection(fGroupGeoJSON));
                  dispatch(loadPlanById(planId));
                });
            }}
          >
            Save
          </Button>
        </div>
      </Drawer>
      <Map
        dynamicBoundsGeoJSON={!isEditingBounds && ranchesGeoJSONState
          ? plansEditStore.getState().ranchesGeoJSON
          : undefined}

        expandedGeoJSON={expandedGeoJSONState
          ? plansEditStore.getState().expandedGeoJSON
          : undefined}

        mapBoxPadding={padding}

        onClickDynamicBounds={() => {
          if (hasSelectedFeatureState) {
            return;
          }
          reDraw(fGroupRef, null, true);
          setIsEditingBounds(true);
          const dynBounds = cloneDeep(ranchesGeoJSONState);
          if (dynBounds?.features.length === 1) {
            dynBounds.features[0].geometry.type = 'Polygon';
            dynBounds.features[0].properties = { type: 'bounds' };
            const lDynBounds = new L.GeoJSON(dynBounds);

            lDynBounds.eachLayer((gjLayer) => {
              fGroupRef.current.leafletElement.addLayer(gjLayer);
              gjLayer.editing.enable();
            });
          }
        }}
      >
        <FeatureGroup
          ref={(reactFGref) => {
            if (reactFGref && !fGroupRef.current) {
              // eslint-disable-next-line no-param-reassign
              fGroupRef.current = reactFGref;
              reDraw(fGroupRef, hasSelectedFeatureState);
            }
          }}
        >
          <Button
            ghost={false}
            type="primary"
            size="large"
            icon={<FaExpand style={{ marginTop: '5px' }} />}
            style={{
              position: 'absolute',
              top: '60px',
              left: '24px',
              zIndex: '1',
            }}
            onClick={() => {
              try {
                const gJSON = cloneDeep(fGroupRef.current.leafletElement.toGeoJSON());
                if (!gJSON.features.length) {
                  plansEditStore.dispatch(setExpandedGeoJSON(undefined));
                  return;
                }
                let points;
                const blocks = gJSON.features.filter((f) => f.properties.type === 'block');
                const nonBlocks = gJSON.features.filter((f) => f.properties.type !== 'block');
                if (!blocks.length && nonBlocks.length < 3) {
                  points = explode(buffer({
                    type: 'FeatureCollection',
                    features: nonBlocks,
                  }, 0.01, { units: 'kilometers' }));
                } else {
                  points = explode(gJSON.features
                    .reduce(
                      (acc, curr) => {
                        acc.features.push(curr);
                        return acc;
                      },
                      {
                        type: 'FeatureCollection',
                        features: [],
                      },
                    ));
                }

                const boundary = concave(points);
                const boundaryExpanded = buffer(boundary, 0.01, { units: 'kilometers' });
                const expandGeoJSON = simplify(boundaryExpanded, {
                  tolerance: 0.00001,
                  highQuality: true,
                });
                expandGeoJSON.id = `${Date.now()}`;
                expandGeoJSON.geometry.type = 'MultiLineString';
                const expandedGeoJSON = {
                  type: 'FeatureCollection',
                  features: [],
                };
                expandedGeoJSON.features.push(expandGeoJSON);
                plansEditStore.dispatch(setExpandedGeoJSON(expandedGeoJSON));
              } catch (error) {
                logger.error(`Fit error ${error.message}`);
                plansEditStore.dispatch(setExpandedGeoJSON(undefined));
              }
            }}
          />
          {
            brokenMarkers && !!brokenMarkers.length && (
              <div
                style={{
                  position: 'absolute',
                  top: '110px',
                  left: '24px',
                  zIndex: '2000',
                }}
              >
                <Badge count={(brokenMarkers && brokenMarkers.length) || 0}>
                  <Button
                    ghost={false}
                    type="primary"
                    size="large"
                    icon={<FaExclamation style={{ marginTop: '5px' }} />}
                    onClick={setViewTypeList}
                  />
                </Badge>
              </div>
            )
          }

          {!isEditingBounds && (
            <DrawFeatures
              onEditMove={(e) => {
                const { layer } = e;
                const latLng = layer.getLatLng();
                plansEditStore.dispatch(setSelectedCoordinates([latLng.lng, latLng.lat]));
              }}

              onCreated={(e) => {
                const { layer } = e;
                const layerGeoJSON = layer.toGeoJSON();
                layerGeoJSON.properties.creating = true;
                layerGeoJSON.properties.notSaved = true;
                layerGeoJSON.properties.name = '';
                layerGeoJSON.properties.id = createUUID();
                const { newFeatureType } = plansEditStore.getState();
                layerGeoJSON.properties.type = newFeatureType;
                plansEditStore.dispatch(setNewFeatureType(null));
                (new L.GeoJSON(layerGeoJSON)).eachLayer((gjLayer) => {
                  if (gjLayer.feature.properties.type !== 'block') {
                    gjLayer.setIcon(getIcon());
                  }
                  fGroupRef.current.leafletElement.addLayer(gjLayer);
                  plansEditStore.dispatch(setSelectedFeature(gjLayer));
                });
              }}
            />
          )}
        </FeatureGroup>


      </Map>
      {isEditingBounds && (
        <div
          style={{
            position: 'absolute',
            top: '90%',
            zIndex: '3333',
            width: '100%',
            textAlign: 'center',
          }}
        >
          <Button
            style={{ minWidth: '100px' }}
            ghost={false}
            danger
            type="primary"
            onClick={() => {
              fGroupRef.current.leafletElement.eachLayer((layer) => {
                if (layer?.feature?.properties?.type === 'bounds') {
                  layer.editing.disable();
                  fGroupRef.current.leafletElement.removeLayer(layer);
                  dispatch(loadPlanById(planId))
                    .then((res) => {
                      if (res.error) {
                        notifyError(res?.error?.message);
                      }
                      setIsEditingBounds(false);
                    });
                }
              });
            }}
          >
            Cancel
          </Button>
          <Button
            style={{ minWidth: '100px', marginLeft: '10px' }}
            type="primary"
            onClick={() => {
              fGroupRef.current.leafletElement.eachLayer((layer) => {
                if (layer?.feature?.properties?.type === 'bounds') {
                  layer.editing.disable();
                  const boundsGeoJSON = cloneDeep(layer.toGeoJSON());
                  fGroupRef.current.leafletElement.removeLayer(layer);
                  const fGroupGeoJSON = cloneDeep(fGroupRef.current.leafletElement.toGeoJSON());

                  const newBounds = calculateDynamicBoundaries(
                    fGroupGeoJSON,
                    null,
                    null,
                    boundsGeoJSON,
                  );
                  const data = mapDataForPatching({
                    dataGeoJSON: cloneDeep(fGroupGeoJSON),
                    dataOriginal: selPlan.data.plan,
                    ranchBoundsGeoJSON: newBounds,
                  });
                  dispatch(patchPlan(data))
                    .then((res) => {
                      if (res.error) {
                        notifyError(res?.error?.message);
                        return;
                      }
                      setRanchesGeoJSONState(undefined);
                      setIsEditingBounds(false);
                      dispatch(loadPlanById(planId));
                    });
                }
              });
            }}
          >
            Save
          </Button>
        </div>
      )}
    </div>
  );
}

PlansEditMap.propTypes = {
  isDrawerVisible: PropTypes.bool.isRequired,
  hasSelectedFeatureState: PropTypes.bool.isRequired,
  fGroupRef: PropTypes.shape({
    current: PropTypes.object,
  }).isRequired,
  ranchesGeoJSONState: PropTypes.shape({
    type: PropTypes.string,
    features: PropTypes.array,
  }).isRequired,
  expandedGeoJSONState: PropTypes.shape({
    type: PropTypes.string,
    features: PropTypes.array,
  }).isRequired,
  setViewTypeList: PropTypes.func.isRequired,
  brokenMarkers: PropTypes.arrayOf(PropTypes.object).isRequired,
  planId: PropTypes.number.isRequired,
  selPlan: PropTypes.shape({
    data: PropTypes.object,
  }).isRequired,
  setRanchesGeoJSONState: PropTypes.func.isRequired,
};
