import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { initLogger } from 'farmx-api';
import { store, persistor } from 'farmx-redux-core';
import { PersistGate } from 'redux-persist/integration/react';
import App from './components/app/App';
import * as serviceWorker from './serviceWorker';
import './index.css';

// for dark mode support
// https://levelup.gitconnected.com/adding-dark-mode-to-your-react-app-with-emotion-css-in-js-fc5c0f926838
initLogger();
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
