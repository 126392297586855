import React, {
  useCallback, useEffect, useState,
} from 'react';
import { message } from 'antd';
import { SelectSensorCapabilitiesListProps } from './type';
import SelectSensorAndCapabilities from './SelectSensorAndCapabilities';

const SelectSensorCapabilitiesList = (
  props: SelectSensorCapabilitiesListProps,
) => {
  const {
    values,
    onSensorSelect,
    onCapabilitiesChange,
    // onClear,
    colors,
  } = props;
  const { sensors: sensorsFromProps, capabilities: capabilitiesFromProps } = values;
  const [rows, setRows] = useState(sensorsFromProps?.length || 1);
  const [sensors, setSensors] = useState(sensorsFromProps || []);
  const [capabilities, setCapabilities] = useState(capabilitiesFromProps || []);

  // TODO remove after testing
  // console.log('List selected sensor capabilities', sensors, capabilities);
  // remove if not useful
  // const onClick = useCallback(() => {
  //   onClear();
  // }, [onClear]);

  const onClickSensorSelect = useCallback((index, value) => {
    setSensors((prev: any): any => {
      // check if selected sensor already exists
      const newState = [...prev];
      // check if selected sensor already exists
      if (prev.length && prev[index]) {
        if (value) {
          newState.splice(index, 1, value);
        } else {
          newState.splice(index, 1);
          setCapabilities((prevState) => {
            const data = [...prevState];
            data.splice(index, 1);
            return data;
          });
          setRows((prevRows: number) => (prevRows > 1 ? prevRows - 1 : 1));
        }
        return newState;
      }
      const exists = newState?.filter((sensor) => sensor?.identifier === value?.identifier);
      if (exists.length) {
        // do not allow to select same sensor again
        message.error('Sensor already selected');
        setRows((prevRows: number) => (prevRows > 1 ? prevRows - 1 : 1));
        return prev;
      }

      return [...prev, value];
    });
  }, []);

  useEffect(() => {
    onSensorSelect(sensors);
  }, [onSensorSelect, sensors]);

  const onClickCapabilitiesSelect = useCallback((index, value) => {
    setCapabilities((prev: any): any => {
      let newState = [...prev];
      if (prev.length && prev[index]) {
        if (value && value.length) {
          newState.splice(index, 1, value);
        } else if (prev.length === 1) {
          newState = [];
        } else newState.splice(index, 1, []);
        return newState;
      }
      return [...newState, value];
    });
  }, []);

  useEffect(() => {
    onCapabilitiesChange(capabilities);
  }, [capabilities, onCapabilitiesChange]);

  // remove if not useful
  // const filterOption = useCallback(
  //   (input: any, option: any) => option?.label?.indexOf(input) >= 0, [],
  // );

  const onAdd = useCallback(() => {
    setRows((prev: number) => {
      if (sensors[prev - 1] && capabilities[prev - 1]?.length) return prev + 1;
      return prev;
    });
  }, [sensors, capabilities]);

  const onDelete = useCallback((index) => {
    setSensors((prev: object[]) => {
      const newState = [...prev];
      newState.splice(index, 1);
      return newState;
    });
    setCapabilities((prev: object[]) => {
      const newState = [...prev];
      newState.splice(index, 1);
      return newState;
    });
    setRows((prev: number) => prev - 1);
  }, []);

  const getSensorCapabilitiesRows = useCallback(() => {
    const sensorCapabilities = [];
    let rowIndex = 0;
    while (rowIndex < rows) {
      sensorCapabilities.push(
        <SelectSensorAndCapabilities
          index={rowIndex}
          onSensorSelectChange={onClickSensorSelect}
          onCapabilitiesChange={onClickCapabilitiesSelect}
          onAdd={onAdd}
          onDelete={onDelete}
          colors={colors}
          sensor={sensors[rowIndex]}
          capabilities={capabilities[rowIndex]}
          selectedFilters={[{ type: 'type', value: sensors[rowIndex]?.type, op: 'AND' }]}
        />,
      );
      rowIndex += 1;
    }
    // TODO remove after testing
    // console.log('sensorCapabilities', sensorCapabilities);
    return sensorCapabilities;
  }, [capabilities, colors, onAdd, onClickCapabilitiesSelect, onClickSensorSelect, onDelete, rows, sensors]);

  return (
    <>
      {
        getSensorCapabilitiesRows()
      }
    </>
  );
};

export default SelectSensorCapabilitiesList;
