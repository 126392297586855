import React from 'react';

export default function Page404 () {
  return (
    <div className="column">
      <div className="message-container">
        <h1>404: Page Not Found</h1>
      </div>
    </div>
  )
}
