import React from 'react';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { constants } from 'farmx-api';
import { hooks } from 'farmx-redux-core';

const { useEntity } = hooks;

const getEditUrl = (id) => `${constants.ADMIN_URL}/farm/entity/${id}/`;
function EntityLink({ id }) {
  const entity = useEntity(id);
  const { t } = useTranslation();
  if (!entity) return null;
  return (
    <>
      <Link to={`/manage/sensor/?entity=${entity.name}`}>
        {`${entity.name} [${entity.id}]`}
      </Link>
      &nbsp;
      <Button href={getEditUrl(id)} size="small">{t('Edit')}</Button>
    </>
  );
}

EntityLink.propTypes = {
  id: PropTypes.number.isRequired,
};

export default EntityLink;
