/* global L */
import React, { useState, useEffect } from 'react';
import { withLeaflet } from 'react-leaflet';
import {
  FaMapMarkerAlt,
  FaDrawPolygon,
  FaMapMarker,
} from 'react-icons/fa';
import isEqual from 'react-fast-compare';
import { Button } from 'antd';
import { plansEditStore, plansEditSlice } from './local-redux';
import { getIcon } from './helpers-map';

const { setNewFeatureType } = plansEditSlice.actions;

export const DrawFeatures = withLeaflet((props) => {
  const [hasSelectedFeatureState, setHasSelectedFeatureState] = useState(null);
  const { leaflet: { map } } = props;

  useEffect(() => {
    const subscription = () => {
      const selF = !!plansEditStore.getState().selectedFeature;
      if (!isEqual(hasSelectedFeatureState, selF)) {
        setHasSelectedFeatureState(selF);
      }
    };
    const unsubscribe = plansEditStore.subscribe(subscription);

    map.on('draw:editmove', props.onEditMove);
    map.on('draw:created', props.onCreated);
    return function cleanup() {
      map.off('draw:created', props.onCreated);
      map.off('draw:editmove', props.onEditMove);
      unsubscribe(subscription);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '140px',
          marginTop: '10px',
          marginRight: '5px',
        }}
      >
        <Button
          id="start-drawing-block"
          size="large"
          type="primary"
          ghost={false}
          icon={<FaDrawPolygon style={{ marginTop: '5px' }} />}
          disabled={!!hasSelectedFeatureState}
          onClick={() => {
            plansEditStore.dispatch(setNewFeatureType('block'));
            (new L.Draw.Polygon(map)).enable();
          }}
        />

        <Button
          id="start-drawing-sensor"
          size="large"
          type="primary"
          icon={<FaMapMarkerAlt style={{ marginTop: '5px' }} />}
          disabled={!!hasSelectedFeatureState}
          onClick={() => {
            plansEditStore.dispatch(setNewFeatureType('sensor'));
            (new L.Draw.Marker(map, { icon: getIcon() })).enable();
          }}
        />
        <Button
          id="start-drawing-object"
          size="large"
          type="primary"
          icon={<FaMapMarker style={{ marginTop: '5px' }} />}
          disabled={!!hasSelectedFeatureState}
          onClick={() => {
            plansEditStore.dispatch(setNewFeatureType('object'));
            (new L.Draw.Marker(map, { icon: getIcon() })).enable();
          }}
        />
      </div>
    </div>
  );
});
