
import React from 'react';
import {
  Menu,
  Dropdown,
  Button,
} from 'antd';

import { useDispatch } from 'react-redux';

import { helpers } from 'farmx-api';
import { actions } from 'farmx-redux-core';

import { Link } from 'react-router-dom';

const { loadCavalierStatsSingle, loadSensorStatsSingle, loadGatewayStatsSingle } = actions;

const { getSensorEditUrl } = helpers;

export function SensorActionCellRenderer(props) {
  const { data } = props;
  return (
    <SensorActionMenu
      sensorIdentifier={data.identifier}
      sensorType={data.sensor_type}
      sensorId={data.id}
    />
  );
}
function SensorActionMenu(props) {
  const {
    sensorIdentifier,
    sensorId,
    sensorType,
  } = props;

  const dispatch = useDispatch();

  function refreshData() {
    const sensor = { type: sensorType, identifier: sensorIdentifier };
    switch (sensorType) {
      case 'gateway':
        dispatch(loadGatewayStatsSingle(sensor));
        break;
      case 'cavalier':
        dispatch(loadCavalierStatsSingle(sensor));
        break;
      default:
        dispatch(loadSensorStatsSingle(sensor));
    }
  }

  function actionMenu() {
    const sensor = {
      id: sensorId,
      type: sensorType,
    };
    return (
      <Menu>
        <Menu.Item key="view">
          <Link to={`/detail/${sensorType}/${sensorIdentifier}`}>View Detail</Link>
        </Menu.Item>
        <Menu.Item key="edit">
          <a disabled={!getSensorEditUrl(sensor)} href={getSensorEditUrl(sensor)} target="_blank" rel="noopener noreferrer">Edit</a>
        </Menu.Item>
        {sensorType === 'cavalier' && (
        <Menu.Item key="create-note">
          <Link to={`/notes/create/?sensorType=${sensorType}&sensorIdentifier=${sensorIdentifier}`}>Create Note</Link>
        </Menu.Item>
        )}
        <Menu.Divider />
        <Menu.Item key="refresh" onClick={refreshData}>
          Refresh Data
        </Menu.Item>
      </Menu>
    );
  }

  return (
    <Dropdown overlay={actionMenu}>
      <Button type="primary" style={{ border: 'none', width: '100%', height: '100%' }}>Actions</Button>
    </Dropdown>
  );
}
