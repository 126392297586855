import React from 'react';

export default function Home() {
  return (
    <div className="column">
      <div className="message-container">
        <h2>This is the FarmX Admin site</h2>
        <h3>Open Issues / Tickets:</h3>
        <a href="https://farmx1.atlassian.net/issues/?filter=10925">JIRA Backlog</a>
        <p>
          <span>If you find an issue that is not listed above, please </span>
          <a href="https://farmx1.atlassian.net/secure/CreateIssue.jspa?pid=10400">open a JIRA ticket</a>
          <span> in the FF project and assign to Robin</span>
        </p>

        <h2>Useful Links</h2>
        <p><a href="https://farmx1.atlassian.net/secure/CreateIssue.jspa">Create a JIRA ticket</a></p>
        <p><a href="https://farmx1.atlassian.net/secure/admin/ConfigureCustomField!default.jspa?customFieldId=10833">Add a Customer Site to JIRA</a></p>

        <p>JIRA dashboards:</p>
        <ul>
          <li><a href="https://farmx1.atlassian.net/secure/Dashboard.jspa?selectPageId=10225#">Weekly Dashboard</a></li>
          <li><a href="https://farmx1.atlassian.net/secure/Dashboard.jspa?selectPageId=10214#">Self Triage Dashboard</a></li>
        </ul>
        <p>Field Ops dashboards:</p>
        <ul>
          <li><a href="https://farmx1.atlassian.net/secure/Dashboard.jspa?selectPageId=10211#">Field Ops Dashboard</a></li>
          <li><a href="https://farmx1.atlassian.net/secure/Dashboard.jspa?selectPageId=10212#">Ops Next Week Dashboard</a></li>
        </ul>
      </div>
    </div>
  );
}
