import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Moment from 'react-moment';
import moment from 'moment';
import {
  PageHeader,
  Divider,
  List,
  Tooltip,
  Spin,
  Button,
  Select,
  DatePicker,
  Input,
} from 'antd';
import { userApi } from 'farmx-api';
import InfiniteScroll from 'react-infinite-scroller';
import { FaSyncAlt, FaTimes, FaSlidersH } from 'react-icons/fa';
import {
  BlockSelect,
  SensorSelect,

  ActiveTag,
  RanchBlockTag,
  EventCategorySelect,
  EventCategoryTag,
  EventLogLevelIcon,
  LogLevelSelect,
  EventTypeSelect,
  EventTypeTag,

} from 'farmx-web-ui';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { selectors, actions } from 'farmx-redux-core';
import { useSelector, useDispatch } from 'react-redux';


import 'react-spring-bottom-sheet/dist/style.css';
import './EventsPage.css';
import { isMobile } from 'react-device-detect';

const { setRanchBlockSelection, setBlocks } = actions;

const { selectSensor } = selectors;


const { Search } = Input;
export default function EventsPage() {
  const location = useLocation();
  const history = useHistory();
  const { search, path } = location;
  const dispatch = useDispatch();
  const sheetRef = useRef(null);

  const { t } = useTranslation();

  // loading is used for any data loading
  const [loading, setLoading] = useState(false);
  // refreshing is used for reloading list from start
  const [refreshing, setRefreshing] = useState(false);
  const [events, setEvents] = useState([]);
  const [totalEvents, setTotalEvents] = useState(null);
  const [nextPage, setNextPage] = useState(null);

  // filters
  const [block, setBlock] = useState(null);
  const [logLevel, setLogLevel] = useState(null);
  const [sensor, setSensor] = useState(null);
  const sensorIdentifier = sensor && sensor.identifier;
  const sensorType = sensor && sensor.type;
  const [active, setActive] = useState(null);
  const [eventCategory, setEventCategory] = useState(null);
  const [eventType, setEventType] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [dateStart, dateEnd] = dateRange || [];
  const [cancelToken, setCancelToken] = useState(null);
  // Bottom sheet
  const sensorObj = useSelector((state) => selectSensor(state, sensor
    && sensor.type, sensor && sensor.identifier));
  const [isBottomSheetVisible, setIsBottomSheetVisible] = useState(true);
  const [searchStr, setSearchStr] = useState('');

  const hasMore = nextPage !== null;

  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const defaultActive = urlParams.get('active')
      ? urlParams.get('active') === 'true'
      : null;
    const defaultBlock = urlParams.get('blockId');
    const defaultBlocks = defaultBlock ? [Number(defaultBlock)] : null;
    const defaultLogLevel = urlParams.get('logLevel');
    const defaultSensorIdentifier = urlParams.get('sensorIdentifier');
    const defaultSensorType = urlParams.get('sensorType');
    const defaultEventType = urlParams.get('eventType');
    const defaultStartDate = urlParams.get('startDate');
    const defaultEndDate = urlParams.get('endDate');
    const defaultSensor = defaultSensorIdentifier
      && defaultSensorType && {
      identifier: defaultSensorIdentifier,
      type: defaultSensorType,
    };
    if (defaultActive) {
      setActive(defaultActive);
    }
    if (defaultLogLevel) {
      setLogLevel(defaultLogLevel);
    }
    if (defaultBlocks) {
      setBlock(defaultBlocks);
    }
    if (defaultSensor) {
      setSensor(defaultSensor);
    }
    if (defaultStartDate && defaultEndDate) {
      setDateRange([moment(defaultStartDate), moment(defaultEndDate)]);
    }
    if (defaultEventType) {
      setEventType(defaultEventType);
    }
    history.replace(path);
  }, [search, history, path]);

  const refresh = useCallback(() => {
    if (cancelToken) cancelToken.cancel();
    const source = userApi.getCancelToken();
    setCancelToken(source);

    setRefreshing(true);
    setLoading(true);
    const params = {
      blockId: block,
      sensorType,
      sensorIdentifier,
      logLevel,
      active,
      eventCategory,
      eventType,
      dateStart,
      dateEnd,
      cancelToken: source.token,
      q: searchStr,
    };
    userApi.loadUserEvents(params).then((response) => {
      const { data } = response;
      const { results, count, next } = data;
      setEvents(results);
      setRefreshing(false);
      setLoading(false);
      setTotalEvents(count);
      if (next) {
        setNextPage(next);
      } else {
        setNextPage(undefined);
      }
    });
  }, [
    cancelToken,
    block,
    sensorType,
    sensorIdentifier,
    logLevel,
    active,
    eventCategory,
    eventType,
    dateStart,
    dateEnd,
    searchStr,
  ]);

  const handleInfiniteOnLoad = useCallback(() => {
    if (!nextPage) return;
    if (cancelToken) cancelToken.cancel();
    const source = userApi.getCancelToken();
    setCancelToken(source);
    setLoading(true);
    const searchParams = new URLSearchParams(nextPage.split('?')[1]);
    const cursor = searchParams.get('cursor');
    userApi
      .loadUserEvents({
        cursor,
        blockId: block,
        sensorType,
        sensorIdentifier,
        logLevel,
        active,
        eventCategory,
        eventType,
        dateStart,
        dateEnd,
        cancelToken: source.token,
        q: searchStr,
      })
      .then((response) => {
        const { data } = response;
        const { results, count, next } = data;
        setEvents(events.concat(results));
        setLoading(false);
        setTotalEvents(count);
        if (next) {
          setNextPage(next);
        } else {
          setNextPage(undefined);
        }
      });
  }, [
    cancelToken,
    nextPage,
    events,
    block,
    sensorType,
    sensorIdentifier,
    logLevel,
    active,
    eventCategory,
    eventType,
    dateStart,
    dateEnd,
    searchStr,
  ]);

  // NOTE: this should handle initial load
  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    block,
    sensorType,
    sensorIdentifier,
    logLevel,
    active,
    eventCategory,
    eventType,
    dateStart,
    dateEnd,
    searchStr,
  ]);

  function renderTotalEvents() {
    if (totalEvents !== null) {
      return `Loaded ${events.length} of ${totalEvents}`;
    }
    return null;
  }

  function getTitle() {
    return [
      t('Events'),
      <Button key="refresh" type="link" onClick={refresh} disabled={loading}>
        <FaSyncAlt className={loading ? 'fa-spin' : ''} />
      </Button>,
    ];
  }

  function renderHeaderExtra() {
    return isMobile
      ? [
        <span>{renderTotalEvents()}</span>,
        <Button
          key="filter"
          type="default"
          icon={<FaSlidersH className="event-filter-icon" />}
          onClick={() => setIsBottomSheetVisible(true)}
        >
          {t('Filter')}
        </Button>,
      ]
      : renderTotalEvents();
  }

  function renderFooter() {
    return null;
  }

  function renderAvatar(event) {
    return <EventLogLevelIcon value={event.logLevel} />;
  }

  function renderDate(event) {
    return (
      <Tooltip
        title={(
          <Moment format="YYYY-MM-DD h:mm:ss A" local>
            {event.createdAt}
          </Moment>
        )}
        placement="bottom"
      >
        <span>
          <Moment fromNow>{event.createdAt}</Moment>
          {' - '}
          <Moment format="YYYY-MM-DD h:mma">{event.createdAt}</Moment>
        </span>
      </Tooltip>
    );
  }

  function renderFilterDetails() {
    return (
      <div className="events-filter-details">
        {eventCategory && (
          <div>
            <span className="list-header">{`${t('Category')}: `}</span>
            <span className=".list-header-value">{eventCategory}</span>
          </div>
        )}
        {logLevel && (
          <div>
            <span className="list-header">{`${t('Severity')}: `}</span>
            <span className=".list-header-value">{logLevel}</span>
          </div>
        )}
        {active && (
          <div>
            <span className="list-header">{`${t('Active')}: `}</span>
            <span className=".list-header-value">{active}</span>
          </div>
        )}
        {sensorObj && (
          <div>
            <span className="list-header">{`${t('Devices')}: `}</span>
            <span className=".list-header-value">
              {sensorObj && sensorObj.name}
            </span>
          </div>
        )}
        {searchStr && (
          <div>
            <span className="list-header">{`${t('Search')}: `}</span>
            <span className=".list-header-value">{searchStr}</span>
          </div>
        )}
      </div>
    );
  }

  function renderFilters() {
    return (
      <div className="event-list-filters" id="event-list-section">
        <BlockSelect
          className="block-select"
          placeholder={t('Block')}
          onChange={(b) => setBlock(b)}
          value={block}
          applyFilter={false}
          useAllBlockData
        />
        <SensorSelect
          sensorType={sensorType}
          sensorIdentifier={sensorIdentifier}
          onChange={setSensor}
          placeholder={t(isMobile ? 'Devices' : 'Sensors')}
          applyFilter
          useAllFarmData
        />
        <LogLevelSelect
          value={logLevel}
          placeholder={t('Severity')}
          onChange={(value) => setLogLevel(value)}
        />
        <EventCategorySelect
          value={eventCategory}
          placeholder={t('Category')}
          onChange={(value) => setEventCategory(value)}
        />
        <EventTypeSelect
          value={eventType}
          placeholder={t('Type')}
          onChange={(value) => setEventType(value)}
        />
        <Select
          placeholder={t('Active')}
          options={[
            { value: true, label: t('Active') },
            { value: false, label: t('Inactive') },
          ]}
          value={active}
          allowClear
          onChange={setActive}
          getPopupContainer={(trigger) => trigger.parentNode}
        />
        {isMobile && (
          <Search
            placeholder={t('Search')}
            allowClear
            onSearch={(d) => setSearchStr(d)}
            className="event-list-item-full-width"
          />
        )}
        <DatePicker.RangePicker
          showTime={{ format: 'HH:mm' }}
          format="YYYY-MM-DD HH:mm"
          onChange={(d) => setDateRange(d)}
          value={dateRange}
          onOk={null}
          className="event-list-item-full-width"
          dropdownClassName="event-page-datepicker"
          getPopupContainer={isMobile ? null : (trigger) => trigger.parentNode}
        />
      </div>
    );
  }

  function renderExtra(event) {
    return (
      <div className="flex-row">
        <EventCategoryTag value={event.eventCategory} />
        <EventTypeTag value={event.eventType} />
        <ActiveTag value={event.active} />
        {/* {isMobile && <NotificationShareButton showTitle notification={event} />} */}
      </div>
    );
  }

  function renderEvent(event) {
    return (
      <List.Item key={event.id} actions={null} extra={renderExtra(event)}>
        <List.Item.Meta
          avatar={renderAvatar(event)}
          title={(
            <div className="event-description">
              <span>{event.description}</span>
              <RanchBlockTag
                ranchName={event?.ranchDetails?.ranchName}
                blockName={event?.blockDetails?.blockName}
              />
            </div>
          )}
          description={renderDate(event)}
        />
      </List.Item>
    );
  }

  return (
    <div className="scrollable-page single-column-page padded">
      <Helmet>
        <title>{t('Events')}</title>
      </Helmet>

      {isMobile && (
        <BottomSheet
          ref={sheetRef}
          open={isBottomSheetVisible}
          blocking={false}
          onDismiss={() => {
            setIsBottomSheetVisible(false);
          }}
          snapPoints={({ maxHeight, minHeight }) => [minHeight, maxHeight * 0.5, maxHeight]}
          defaultSnap={({ snapPoints }) => snapPoints[0]}
          header={(
            <div className="bottom-sheet-header-container">
              <div className="filters-header">{t('Filter Events')}</div>
              <div className="bottom-sheet-header">
                <Button
                  shape="circle"
                  icon={<FaTimes />}
                  onClick={() => setIsBottomSheetVisible(false)}
                />
              </div>
            </div>
          )}
          scrollLocking={false}
        >
          {renderFilters()}
        </BottomSheet>
      )}
      <InfiniteScroll
        initialLoad={false}
        pageStart={0}
        loadMore={handleInfiniteOnLoad}
        hasMore={!loading && hasMore}
        useWindow={false}
      >
        <PageHeader
          className="event-header"
          title={getTitle()}
          extra={renderHeaderExtra()}
        />
        {isMobile && renderFilterDetails()}
        {!isMobile && renderFilters()}
        <Divider />
        <div className="event-list-container">
          <div className="event-list">
            <List
              itemLayout="vertical"
              size="large"
              dataSource={events}
              loading={refreshing}
              footer={renderFooter()}
              renderItem={(event) => renderEvent(event)}
            >
              {loading && hasMore && (
                <div className="loading-container">
                  <Spin />
                </div>
              )}
            </List>
          </div>
        </div>
      </InfiniteScroll>
    </div>
  );
}
