import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { actions, selectors } from 'farmx-redux-core';
import { createSelector } from '@reduxjs/toolkit';
import Moment from 'react-moment';
import cloneDeep from 'lodash/cloneDeep';
import {
  Table,
  Layout,
  PageHeader,
  Button,
  Typography,
} from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { notifyError } from './plans-edit/utils';

const {
  loadRanchDetail,
  loadPlansByRanchId,
  loadPlanById,
  updatePlan,
} = actions;
const { ranchSelectors } = selectors;

const selectRanchById = (ranchId) => createSelector(
  [
    (state) => ranchSelectors.selectById(state, ranchId),
  ],
  (ranch) => {
    const result = {
      loading: true,
      data: { ranch: {} },
      error: false,
    };

    if (!ranch || ranch.loading) {
      return result;
    }

    result.loading = false;

    if (ranch.error) {
      result.error = ranch.error;
      return result;
    }
    result.error = false;
    result.data.ranch = ranch;

    return result;
  },
);

function compareByDate(a, b) {
  const aDateString = a.date_plan_created;
  const bDateString = b.date_plan_created;
  if (!aDateString && !bDateString) {
    return 0;
  }

  if (!aDateString && bDateString) {
    return 1;
  }

  if (aDateString && !bDateString) {
    return -1;
  }

  const aTimestamp = Date.parse(aDateString);
  const bTimestamp = Date.parse(bDateString);

  if (aTimestamp < bTimestamp) {
    return 1;
  }
  if (aTimestamp > bTimestamp) {
    return -1;
  }

  return 0;
}

const selectPlansByRanchesId = (ranchId) => createSelector(
  [
    (state) => state.farmData.plans,
  ],
  (plans) => {
    const result = {
      loading: true,
      data: { plans: [] },
      error: false,
    };

    if (!plans || plans.loading) {
      return result;
    }

    result.loading = false;

    if (plans.error) {
      result.error = plans.error;
      return result;
    }

    result.error = false;

    Object
      .keys(plans.entities)
      .forEach((k) => {
        if (plans.entities[k].ranch === ranchId) {
          const o = {
            ...plans.entities[k],
            active: false,
            key: plans.entities[k].id,
          };
          result.data.plans.push(o);
        }
      });

    if (result.data.plans.length) {
      result.data.plans.sort(compareByDate);
      result.data.plans[0].active = true;
    }

    return result;
  },
);

const columns = (history, dispatch) => [
  {
    title: 'Date',
    dataIndex: 'date_plan_created',
    key: 'date',
    render: (v) => <Moment format="YYYY-MM-DD HH:mm">{v}</Moment>,
  },
  {
    title: 'Active',
    dataIndex: 'active',
    key: 'active',
    render: (v) => (v ? <CheckCircleOutlined style={{ color: '#49aa19', marginLeft: '12px', fontSize: '22px' }} /> : ''),
  },
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
  },
  {
    title: 'Action',
    dataIndex: 'id',
    key: 'id',
    render: (id) => (
      <>
        <Button
          id={`clone-plan-${id}`}
          style={{ width: '80px' }}
          onClick={() => {
            dispatch(loadPlanById(id)).then((res) => {
              if (res.error) {
                notifyError(res?.error?.message);

                return;
              }
              const dataOriginal = cloneDeep(res.payload);
              const dataClone = { id };
              dataClone.sensors = dataOriginal.sensors
                .map(({
                  type,
                  name,
                  location,
                  state,
                }) => ({
                  type, name, location, state,
                }));

              dataClone.objects = dataOriginal.objects
                .map(({
                  location,
                  name,
                  description,
                }) => ({ location, name, description }));

              dataClone.blocks = dataOriginal.blocks
                .map(({
                  block,
                  name,
                  bounds,
                }) => ({ block, name, bounds }));

              dispatch(updatePlan(dataClone)).then((resUpdate) => {
                if (resUpdate.error) {
                  notifyError(resUpdate?.error?.message);
                  return;
                }
                if (resUpdate?.payload?.id) {
                  history.push(`/installation-plan/edit/${resUpdate?.payload?.id}`);
                  return;
                }
                notifyError('Unkown error.');
              });
            });
          }}
          type="primary"
        >
          Clone
        </Button>
        <Button
          style={{ marginLeft: '10px', width: '80px' }}
          id={`edit-plan-${id}`}
          onClick={() => {
            history.push(`/installation-plan/edit/${id}`);
          }}
          type="primary"
        >
          Edit
        </Button>
      </>
    ),
  },
];

export function RanchPlans({ match: { params }, history }) {
  const ranchId = Number(params.ranch_id);
  const dispatch = useDispatch();
  const selRanch = useSelector((state) => selectRanchById(ranchId)(state));
  const selPlans = useSelector((state) => selectPlansByRanchesId(ranchId)(state));
  const [didDispatch, setDidDispatch] = useState(false);
  const [didShowRanchError, setDidShowRanchError] = useState(false);
  const [didShowPlansError, setDidShowPlansError] = useState(false);

  useEffect(() => {
    dispatch(loadRanchDetail(ranchId));

    dispatch(loadPlansByRanchId(ranchId)).then(() => { setDidDispatch(true); });
  }, [dispatch, ranchId]);

  useEffect(() => {
    if (didDispatch && !didShowRanchError && !selRanch?.loading && selRanch?.error) {
      notifyError(selRanch?.error?.message);
      setDidShowRanchError(true);
    }
  }, [selRanch, didShowRanchError, didDispatch]);

  useEffect(() => {
    if (didDispatch && !didShowPlansError && !selPlans?.loading && selPlans?.error) {
      notifyError(selPlans?.error?.message);
      setDidShowPlansError(true);
    }
  }, [selPlans, didShowPlansError, didDispatch]);

  return (
    <Layout className="entitylist-page" style={{ overflowX: 'scroll' }}>

      {!selRanch.loading && !selRanch.error && !selPlans.loading && !selPlans.error && (
        <>
          <PageHeader
            title={(selRanch.data.ranch && selRanch.data.ranch.name) || 'Ranch'}
          />
          <div style={{ paddingLeft: '1.8em' }}>
            <div style={{ display: 'flex' }}>
              <Typography.Title level={3}>Plans</Typography.Title>
              <Button
                key="new-plan"
                id="create-new-plan"
                type="primary"
                disabled={!(selRanch.data.ranch && selRanch.data.ranch.entity) || selRanch.loading}
                onClick={() => {
                  history.push(`/installation-plan/new/${ranchId}`);
                }}
                style={{ marginLeft: '2em', width: '80px' }}
              >
                New
              </Button>
            </div>

          </div>
          <Table
            dataSource={selPlans.data.plans}
            columns={columns(history, dispatch)}
            pagination={{ pageSize: 100 }}
            loading={selPlans.loading}
          />
        </>
      )}
    </Layout>
  );
}

RanchPlans.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      ranch_id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
