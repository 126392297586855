import React from 'react';
import { Breadcrumb } from 'antd';
import { helpers } from 'farmx-api';
import { hooks } from 'farmx-redux-core';

const { useRanch } = hooks;
const { getBreadcrumbTitle } = helpers;

function RanchBreadcrumbItem(props) {
  const farmObject = useRanch(props.id);
  if (!farmObject) return null;
  return (
    <Breadcrumb.Item>{getBreadcrumbTitle("ranch", farmObject)}</Breadcrumb.Item>
  )
}

RanchBreadcrumbItem.__ANT_BREADCRUMB_ITEM = true;

export default RanchBreadcrumbItem;
