import React, { useState, useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { AppTemplate, PrivateRoute, UserMenu } from 'farmx-web-ui';
import { authService } from 'farmx-api';
import { actions } from 'farmx-redux-core';
import { useDispatch } from 'react-redux';
import Page404 from './Page404';
import Sidebar from './Sidebar';
import FarmXLogo from '../../assets/images/farmx_logo_white_30.png';
import { ROUTES } from './routes';

const { loadFarmData } = actions;

export default function Main() {
  const [userInfo, setUserUnfo] = useState();
  const dispatch = useDispatch();

  // To load farm data for ranch and block list for admin-web
  // This will be removed in the future.
  useEffect(() => {
    if (userInfo) {
      dispatch(loadFarmData());
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    authService.getUserInfo().then(setUserUnfo);
  }, [setUserUnfo]);

  return (
    <AppTemplate
      sidebarComponent={Sidebar}
      headerMenu={<UserMenu userInfo={userInfo} />}
      logo={FarmXLogo}
      title="FarmX Admin"
      theme="dark"
    >
      <Switch>
        {Object.values(ROUTES).map(({
          component,
          exact,
          key,
          path,
        }) => (
          <PrivateRoute
            adminRequired
            authService={authService}
            component={component}
            exact={exact}
            key={key}
            path={path}
          />
        ))}
        <PrivateRoute
          adminRequired
          authService={authService}
          component={Page404}
        />
      </Switch>
    </AppTemplate>
  );
}
