import React from 'react';
import PropTypes from 'prop-types';
import {
  Link,
  withRouter,
} from 'react-router-dom';
import { matchPath } from 'react-router';
import { Menu } from 'antd';
import { CollapsableSidebar } from 'farmx-web-ui';
// icon imports
import {
  FaChartLine,
  FaMapMarkedAlt,
  FaStickyNote,
  FaBroadcastTower,
  FaWifi,
  FaMapPin,
  FaList,
  FaHome,
  FaSearch,
  FaFileCsv,
  FaBell,
} from 'react-icons/fa';
import { ROUTES, ROUTE_KEYS } from './routes';

import './sidebar.css';

const { SubMenu } = Menu;

const getMatchedKey = (location) => {
  const matchedKey = Object.values(ROUTES).find((route) => matchPath(location.pathname, route));
  if (matchedKey === undefined) return undefined;
  return matchedKey.key;
};

class Sidebar extends React.Component {
  collapseIfMobile = () => {
    const { isMobile, onCollapse } = this.props;
    if (isMobile) onCollapse();
  }

  render() {
    const { location, collapsed, onCollapse } = this.props;
    return (
      <CollapsableSidebar
        collapsed={collapsed}
        onCollapse={onCollapse}
        location={location}
      >
        <Menu className="nav-menu" theme="dark" selectedKeys={[getMatchedKey(location)]} mode="inline" onClick={this.collapseIfMobile}>
          <Menu.Item key={ROUTE_KEYS.ROOT}>
            <Link to="/">
              <FaHome />
              <span className="menu-title">Home</span>
            </Link>
          </Menu.Item>
          <Menu.Item key={ROUTE_KEYS.SEARCH}>
            <Link to="/search">
              <FaSearch />
              <span className="menu-title">Search</span>
            </Link>
          </Menu.Item>
          <Menu.Item key={ROUTE_KEYS.MAP_ROOT}>
            <Link to="/map">
              <FaMapMarkedAlt />
              <span className="menu-title">Map</span>
            </Link>
          </Menu.Item>
          <Menu.Item key={ROUTE_KEYS.GRAPH}>
            <Link to="/manage/graph">
              <FaChartLine size="15px" />
              <span className="menu-title">Graph</span>
            </Link>
          </Menu.Item>
          <SubMenu
            key="/manage"
            title={(
              <span>
                <FaList size="15px" />
                <span className="menu-title">Stats</span>
              </span>
            )}
          >
            <Menu.Item key={ROUTE_KEYS.GATEWAY_STATS}>
              <Link to="/manage/gateway">
                <FaBroadcastTower size="15px" />
                <span className="menu-title">Gateway</span>
              </Link>
            </Menu.Item>
            <Menu.Item key={ROUTE_KEYS.NODE_STATS}>
              <Link to={ROUTE_KEYS.NODE_STATS}>
                <FaWifi size="15px" />
                <span className="menu-title">Node</span>
              </Link>
            </Menu.Item>
            <Menu.Item key={ROUTE_KEYS.SENSOR_STATS}>
              <Link to="/manage/sensor">
                <FaMapPin size="15px" />
                <span className="menu-title">Sensor</span>
              </Link>
            </Menu.Item>
            <Menu.Item key={ROUTE_KEYS.BLOCK_STATS}>
              <Link to={ROUTE_KEYS.BLOCK_STATS}>
                <FaMapPin size="15px" />
                <span className="menu-title">Blocks</span>
              </Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key={ROUTE_KEYS.EVENTS}>
            <Link to="/events">
              <FaBell size="15px" />
              <span className="menu-title">Events</span>
            </Link>
          </Menu.Item>
          <Menu.Item key={ROUTE_KEYS.MANAGE_NOTES}>
            <Link to="/manage/notes">
              <FaStickyNote size="15px" />
              <span className="menu-title">Notes</span>
            </Link>
          </Menu.Item>
          <Menu.Item key={ROUTE_KEYS.REPORTS}>
            <Link to={ROUTE_KEYS.REPORTS}>
              <FaFileCsv size="15px" />
              <span className="menu-title">Reports</span>
            </Link>
          </Menu.Item>
        </Menu>
      </CollapsableSidebar>
    );
  }
}

Sidebar.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  collapsed: PropTypes.bool,
  isMobile: PropTypes.bool,
  onCollapse: PropTypes.func,
};


Sidebar.defaultProps = {
  location: { pathname: '' },
  collapsed: false,
  isMobile: false,
  onCollapse: () => { },
};

export default withRouter(Sidebar);
