import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { SensorSelect } from 'farmx-web-ui';
import SensorDataChart from './SensorDataChart';
import { SensorCapabilitySelect } from '../sensor';
import './EditableSensorDataChart.css';

export default function EditableSensorDataChart(props) {
  const {
    sensor,
    variable,
    startDate,
    endDate,
    deletable,
    onDelete,
    size,
    selectedFilters,
  } = props;

  const [chartSensor, setChartSensor] = useState(sensor);
  const [chartVariable, setChartVariable] = useState(variable);

  const sizeClass = `chart-${size}`;

  return (
    <div className={`chart-container ${sizeClass}`}>
      <div className="chart-header-container">
        <div className="chart-header-form">
          <div className="chart-sensor">
            <SensorSelect
              sensorType={chartSensor.type}
              sensorIdentifier={chartSensor.identifier}
              onChange={setChartSensor}
              selectedFilters={selectedFilters}
              useAllFarmData
            />
          </div>
          <div className="chart-variables">
            <SensorCapabilitySelect
              sensor={chartSensor}
              value={chartVariable}
              onChange={setChartVariable}
              mode={null}
            />
          </div>
          <div className="chart-units" />
          <div className="right-controls">
            {deletable
              ? (
                <Button
                  type="ghost"
                  onClick={onDelete}
                >
                  <CloseOutlined />
                </Button>
              )
              : null}
          </div>
        </div>
      </div>
      <div className="chart-body">
        <SensorDataChart
          startDate={startDate}
          endDate={endDate}
          variables={[chartVariable]}
          sensor={chartSensor}
          compact
        />
      </div>
    </div>
  );
}

EditableSensorDataChart.propTypes = {
  ...SensorDataChart.propTypes,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  deletable: PropTypes.bool,
  onDelete: PropTypes.func,
};

EditableSensorDataChart.defaultProps = {
  ...SensorDataChart.defaultProps,
  size: 'md',
  deletable: false,
  onDelete: () => {},
};
