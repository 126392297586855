/* global L */
import React from 'react';
import { icons, sensorTitles } from 'farmx-web-ui';
import cloneDeep from 'lodash/cloneDeep';
import { Select } from 'antd';
import { plansEditSlice, plansEditStore } from './local-redux';
import { calculateDynamicBoundaries } from './utils';

const { Option } = Select;

export const getIcon = (selected, type, isBlockCheck, notInsideBlock) => {
  const opts = {};
  opts.markerColor = '#fff';
  opts.iconColor = '#000';
  opts.extraClassesSvg = 'marker-svg-extra-class';
  if (isBlockCheck && notInsideBlock) {
    opts.extraClassesSvg = 'marker-svg-not-in-block';
  }
  let selectedClass = '';
  if (selected === 1) {
    selectedClass = 'marker-is-selected';
  }
  if (selected === 2) {
    selectedClass = 'marker-not-selected';
  }

  return L.VectorMarkers.icon({
    icon: !type ? 'icon-radio-waves' : icons[type],
    prefix: 'icon',
    markerColor: opts.markerColor,
    iconColor: opts.iconColor,
    dataTestId: 'feature.id',
    selected,
    extraClassesSvg: opts.extraClassesSvg,
    selectedClass,
  });
};

const {
  setSelectedFeature,
  setRanchesGeoJSON,
  setExpandedGeoJSON,
} = plansEditSlice.actions;

export const onFeatureClick = (e) => {
  if (!plansEditStore.getState().selectedFeature) {
    plansEditStore.dispatch(setSelectedFeature(e.target));
  }
};

export const setDynamicRanchBoundaries = (
  gJSON,
  planBounds,
  planLocation,
  manuallyEditedBoundsFeature,
) => {
  const ranchesGeoJSON = calculateDynamicBoundaries(
    gJSON,
    planBounds,
    planLocation,
    manuallyEditedBoundsFeature,
  );

  if (ranchesGeoJSON) {
    plansEditStore.dispatch(setRanchesGeoJSON(ranchesGeoJSON));
    plansEditStore.dispatch(setExpandedGeoJSON(undefined));
  }

  return ranchesGeoJSON;
};

const shouldSpiderfy = (c) => c.getChildCount() < 7;

const prepareMarkerClusterIcon = (cluster) => {
  const childCount = cluster.getChildCount();

  let c = ' marker-cluster-';
  if (childCount < 10) {
    c += 'small';
  } else if (childCount < 100) {
    c += 'medium';
  } else {
    c += 'large';
  }

  const markers = cluster.getAllChildMarkers();

  return new L.DivIcon({
    html: `<div><span>${markers.length}</span></div>`,
    className: `marker-cluster${c}`,
    iconSize: new L.Point(40, 40),
  });
};

const marketClusterGroup = L.markerClusterGroup({
  maxClusterRadius: 50,
  unspiderfyOnMapClick: false,
  shouldSpiderfy,
  animate: false,
  iconCreateFunction: prepareMarkerClusterIcon,
  spiderLegPolylineOptions: {
    weight: 1.5, color: '#eee', opacity: 0.8,
  },
  polygonOptions: {
    stroke: false,
    fill: false,
  },
});

export const reDraw = (fGroupRef, hasSelectedFeatureState, editingBounds) => {
  if (!fGroupRef.current) {
    return;
  }

  marketClusterGroup.clearLayers();
  fGroupRef.current.leafletElement.eachLayer((layer) => {
    if (layer.feature) {
      layer.off('click', onFeatureClick);
      fGroupRef.current.leafletElement.removeLayer(layer);
    } else {
      // console.log('MARKER CLUSTER LAYER');
    }
  });
  const featureCollection = cloneDeep(plansEditStore.getState().featureCollection);

  if (!featureCollection || (featureCollection && !Object.keys(featureCollection).length)) {
    return;
  }

  if (featureCollection && hasSelectedFeatureState) {
    let notFoundSelected = true;
    const featureCollectionFeaturesLength = featureCollection.features.length;
    for (let i = 0; i < featureCollectionFeaturesLength; i += 1) {
      if (featureCollection.features[i].properties.id
        === hasSelectedFeatureState.feature.properties.id) {
        notFoundSelected = false;
        break;
      }
    }

    if (notFoundSelected) {
      featureCollection.features.push(cloneDeep(hasSelectedFeatureState.toGeoJSON()));
    }

    (new L.GeoJSON(featureCollection)).eachLayer((gjLayer) => {
      const isSelected = gjLayer.feature.properties.id
        === hasSelectedFeatureState.feature.properties.id
        ? 1
        : 2;
      if (gjLayer.feature.properties.type === 'sensor' && gjLayer.feature.properties.sensorType !== 'gateway') {
        gjLayer.setIcon(getIcon(
          isSelected,
          gjLayer.feature.properties.sensorType,
          true,
          !gjLayer.feature.properties.block,
        ));
      } else if (gjLayer.feature.properties.type === 'sensor') {
        gjLayer.setIcon(getIcon(isSelected, gjLayer.feature.properties.sensorType));
      } else if (gjLayer.feature.properties.type !== 'block') {
        gjLayer.setIcon(getIcon(isSelected));
      }

      fGroupRef.current.leafletElement.addLayer(gjLayer);
      if (gjLayer.feature.properties.id === hasSelectedFeatureState.feature.properties.id) {
        gjLayer.editing.enable();
      }
      gjLayer.on('click', onFeatureClick);
    });

    return;
  }

  const itemsGeoJSON = featureCollection.features.reduce(
    (acc, curr) => {
      if (curr.properties.type !== 'block') {
        acc.markers.features.push(curr);
        return acc;
      }
      acc.blocks.features.push(curr);
      return acc;
    },
    {
      blocks: {
        type: 'FeatureCollection',
        features: [],
      },
      markers: {
        type: 'FeatureCollection',
        features: [],
      },
    },
  );

  const mcLayer = L.geoJSON(itemsGeoJSON.markers, {
    pointToLayer: (feature, latlng) => {
      let markerIcon;
      if (feature.properties.type === 'sensor' && feature.properties.sensorType !== 'gateway') {
        markerIcon = getIcon(
          false,
          feature.properties.sensorType,
          true,
          !feature.properties.block,
        );
      } else if (feature.properties.type === 'sensor') {
        markerIcon = getIcon(false, feature.properties.sensorType);
      } else {
        markerIcon = getIcon();
      }

      // eslint-disable-next-line new-cap
      const marker = new L.marker(latlng, { icon: markerIcon });
      if (!editingBounds) {
        marker.on('click', onFeatureClick);
      }
      return marker;
    },
  });
  marketClusterGroup.addLayer(mcLayer);
  fGroupRef.current.leafletElement.addLayer(marketClusterGroup);

  (new L.GeoJSON(itemsGeoJSON.blocks)).eachLayer((gjLayer) => {
    fGroupRef.current.leafletElement.addLayer(gjLayer);
    // eslint-disable-next-line no-underscore-dangle
    gjLayer._path.classList.add(gjLayer.feature.properties.type);
    // eslint-disable-next-line no-underscore-dangle,no-param-reassign
    gjLayer._path.id = `${gjLayer.feature.properties.type}-${gjLayer.feature.properties.id}`;
    if (!editingBounds) {
      gjLayer.on('click', onFeatureClick);
    }
  });
};

export const options = Object.keys(sensorTitles)
  .map((key) => <Option id={key} key={key} value={key}>{sensorTitles[key].singular}</Option>);
