import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { actions, selectors } from 'farmx-redux-core';
import { createSelector } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom';
import {
  Table,
  Layout,
  PageHeader,
  Button,
  Typography,
} from 'antd';
import { notifyError } from './plans-edit/utils';

const { loadEntityList, loadRanchList } = actions;
const {
  selectRanchesLoadingState,
  selectEntitiesLoadingState,
  selectRanchesForEntityId,
  selectEntityById,
} = selectors;

const selectRanchesByEntity = (entityId) => createSelector(
  [
    selectEntitiesLoadingState,
    selectRanchesLoadingState,
    (state) => selectRanchesForEntityId(state, entityId),
    (state) => selectEntityById(state, entityId),
  ],
  (entitiesProgress, ranchesProgress, ranchesByEntity, entity) => {
    const result = {
      loading: true,
      data: { entity: {}, ranches: [] },
      error: false,
    };

    if (entitiesProgress.loading || ranchesProgress.loading) {
      return result;
    }

    result.loading = false;

    if (entitiesProgress.error || ranchesProgress.error) {
      result.error = entitiesProgress.error || ranchesProgress.error;
      return result;
    }

    result.data.entity = entity;
    result.data.ranches = ranchesByEntity.map((ranch) => ({
      key: ranch.id,
      id: ranch.id,
      name: ranch.name,
      live: ranch.dummy ? 'No' : 'Yes',
      revisions: 1,
    }));
    return result;
  },
);

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => String(a.name).localeCompare(b.name),
  },
  {
    title: 'Live',
    dataIndex: 'live',
    key: 'live',
  },
  {
    title: 'Revisions',
    dataIndex: 'revisions',
    key: 'revisions',
  },
];

export function EntityDetail({ match: { params } }) {
  const entityId = Number(params.entity_id);
  const history = useHistory();
  const dispatch = useDispatch();
  const selData = useSelector((state) => selectRanchesByEntity(entityId)(state));
  const [didDispatch, setDidDispatch] = useState(false);

  useEffect(() => {
    dispatch(loadEntityList());
    dispatch(loadRanchList()).then(() => { setDidDispatch(true); });
  }, [dispatch]);

  useEffect(() => {
    if (didDispatch && selData?.error) {
      notifyError(selData?.error?.message);
    }
  }, [selData, didDispatch]);

  return (
    <Layout className="entitylist-page" style={{ overflowX: 'scroll' }}>

      {!selData.error && (
        <>
          <PageHeader
            title={(selData.data.entity && selData.data.entity.name) || 'Entity'}
            extra={[
              <Button
                type="primary"
                key="edit-entity"
                href={`https://map.farmx.co/admin/farm/entity/${entityId}`}
                disabled={selData.loading}
                style={{ width: '80px' }}
              >
                Edit
              </Button>,
            ]}
          />
          <div style={{ paddingLeft: '1.8em' }}>
            <Typography.Paragraph>
              {selData.data.entity && (selData.data.entity.address || 'No address')}
            </Typography.Paragraph>
            <div style={{ display: 'flex' }}>
              <Typography.Title level={3}>Ranches</Typography.Title>
              <Button
                style={{ marginLeft: '10px', width: '80px' }}
                type="primary"
                key="new-ranch"
                href="https://map.farmx.co/admin/farm/ranch/add"
                disabled={selData.loading}
              >
                New
              </Button>
            </div>

          </div>
          <Table
            dataSource={selData.data.ranches}
            columns={columns}
            pagination={{ pageSize: 100 }}
            loading={selData.loading}
            onRow={(record) => ({
              onClick: () => {
                history.push(`/ranch-plans/${record.id}`);
              },
            })}
          />
        </>
      )}
    </Layout>
  );
}

EntityDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      entity_id: PropTypes.string,
    }),
  }).isRequired,
};
