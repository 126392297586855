import React from 'react';
import { Helmet } from 'react-helmet';
import { CustomerReportForm } from './CustomerReportForm';
import { RanchBlockReportForm } from './RanchBlockReportForm';
import { AnomalySummaryTable } from './AnomalySummaryTable';

function ReportsPage() {
  return (
    <div className="column">
      <Helmet>
        <title>Reports</title>
      </Helmet>
      <div className="message-container">
        <h2>Reports</h2>
        <h3>Customer Report</h3>
        <p>Select users below to download a summary of their activity.</p>
        <CustomerReportForm />
        <h3>Ranch Report</h3>
        <p>
          Select a ranch below to download a summary of
          automation software usage in each of its blocks.
        </p>
        <RanchBlockReportForm />
        <h3>Anomaly Summary</h3>
        <AnomalySummaryTable />
      </div>
    </div>
  );
}

export default ReportsPage;
