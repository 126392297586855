import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Layout,
  PageHeader,
  Button,
  Input,
  Modal,
} from 'antd';
import isEqual from 'react-fast-compare';
import cloneDeep from 'lodash/cloneDeep';
import { FaList, FaMapMarkedAlt } from 'react-icons/fa';
import { logger } from 'farmx-api';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'farmx-redux-core';
import { createSelector } from '@reduxjs/toolkit';

import './plans-edit.css';
import { plansEditSlice, plansEditStore } from './local-redux';
import { mapDataForPatching, notifyError, sortAndGroupBy } from './utils';
import { onFeatureClick, reDraw, setDynamicRanchBoundaries } from './helpers-map';
import { PlansEditMap } from './PlansEditMap';
import { PlansEditList } from './PlansEditList';

const { loadPlanById, patchPlan } = actions;
const { plansSelectors } = selectors;

const {
  setSelectedFeature,
  resetStore,
  setFeatureCollection,
} = plansEditSlice.actions;

const featureCollectionInitial = {};

const selectPlanById = (planId) => createSelector(
  [
    (state) => plansSelectors.selectById(state, planId),
  ],
  (plan) => {
    const result = {
      loading: true,
      data: {
        originalPlan: {},
        plan: {},
        geoJSON: undefined,
        sensorsNoLocation: [],
        objectsNoLocation: [],
        blocksNoLocation: [],
        meta: {
          ranchName: undefined,
          ranchBounds: undefined,
          ranchLocation: undefined,
        },
      },
      error: false,
    };
    if (!plan || plan.loading) {
      return result;
    }
    result.loading = false;

    if (plan.error) {
      result.error = plan.error;
      return result;
    }
    result.data.originalPlan = cloneDeep(plan);
    result.data.plan = cloneDeep(plan);

    result.data.meta.ranchName = result.data.plan.ranch_name || 'No name';
    result.data.meta.ranchBounds = result.data.plan.ranch_bounds || undefined;
    result.data.meta.ranchLocation = result.data.plan.ranch_location || undefined;

    const sensorsGeoJSON = result.data.plan.sensors.reduce(
      (acc, curr) => {
        const feature = {
          type: 'Feature',
          properties: cloneDeep(curr),
          geometry: cloneDeep(curr.location),
        };
        feature.properties.name = feature.properties.name ? feature.properties.name : 'No name';
        feature.properties.type = 'sensor';
        feature.properties.sensorType = curr.type;
        if (curr.location) {
          acc.sensorsWithLocation.features.push(feature);
          return acc;
        }
        acc.sensorsNoLocation.push(feature);
        return acc;
      },
      {
        sensorsWithLocation: {
          type: 'FeatureCollection',
          features: [],
        },
        sensorsNoLocation: [],
      },
    );

    result.data.sensorsNoLocation = sensorsGeoJSON.sensorsNoLocation;

    const objectsGeoJSON = result.data.plan.objects.reduce(
      (acc, curr) => {
        const feature = {
          type: 'Feature',
          properties: cloneDeep(curr),
          geometry: cloneDeep(curr.location),
        };
        feature.properties.name = feature.properties.name ? feature.properties.name : 'No name';
        feature.properties.type = 'object';

        if (curr.location) {
          acc.objectsWithLocation.features.push(feature);
          return acc;
        }
        acc.objectsNoLocation.push(feature);
        return acc;
      },
      {
        objectsWithLocation: {
          type: 'FeatureCollection',
          features: [],
        },
        objectsNoLocation: [],
      },
    );

    result.data.objectsNoLocation = objectsGeoJSON.objectsNoLocation;

    const blocksGeoJSON = result.data.plan.blocks.reduce(
      (acc, curr) => {
        const feature = {
          type: 'Feature',
          properties: cloneDeep(curr),
          geometry: cloneDeep(curr.bounds),
        };
        feature.properties.name = feature.properties.name ? feature.properties.name : 'No name';
        feature.properties.type = 'block';
        feature.properties.id = curr.id;
        feature.properties.block = curr.block;
        if (curr.bounds) {
          acc.blocksWithLocation.features.push(feature);
          return acc;
        }
        acc.blocksNoLocation.push(feature);
        return acc;
      },
      {
        blocksWithLocation: {
          type: 'FeatureCollection',
          features: [],
        },
        blocksNoLocation: [],
      },
    );

    result.data.blocksNoLocation = blocksGeoJSON.blocksNoLocation;

    sensorsGeoJSON.sensorsWithLocation.features = sensorsGeoJSON.sensorsWithLocation.features
      .concat(objectsGeoJSON.objectsWithLocation.features)
      .concat(blocksGeoJSON.blocksWithLocation.features);

    result.data.geoJSON = sensorsGeoJSON.sensorsWithLocation;

    return result;
  },
);
export function PlansEdit({ match: { params } }) {
  const planId = Number(params.plan_id);
  const dispatch = useDispatch();

  const [viewType, setViewType] = useState('map');

  const fGroupRef = useRef(null);
  const [blockListData, setBlockListData] = useState([]);
  const [sensorListData, setSensorListData] = useState([]);
  const [sensorOkListData, setSensorOkListData] = useState([]);
  const [sensorErrListData, setSensorErrListData] = useState([]);
  const [objectListData, setObjectListData] = useState([]);
  const [nestedListData, setNestedListData] = useState([]);
  const [featureCollectionState, setFeatureCollectionState] = useState(undefined);
  const [featureCollectionBufState, setFeatureCollectionBufState] = useState(undefined);
  const [ranchesGeoJSONState, setRanchesGeoJSONState] = useState(undefined);
  const [expandedGeoJSONState, setExpandedGeoJSONState] = useState(undefined);
  const [hasSelectedFeatureState, setHasSelectedFeatureState] = useState(false);
  const [hasSelectedFeatureBufState, setHasSelectedFeatureBufState] = useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [fCollectionFetchedBufState, setFCollectionFetchedBufState] = useState(undefined);
  const [plansRancNameState, setPlansRancNameState] = useState('');
  const [plansRancNameBufState, setPlansRancNameBufState] = useState('');
  const [isNameModalVisible, setIsNameModalVisible] = useState(false);

  useEffect(() => {
    const subscription = () => {
      const {
        selectedFeature,
        ranchesGeoJSON,
        expandedGeoJSON,
        featureCollection,
      } = plansEditStore.getState();

      if (!isEqual(hasSelectedFeatureState, selectedFeature)) {
        setHasSelectedFeatureState(cloneDeep(selectedFeature));
      }
      if (!isEqual(ranchesGeoJSONState, ranchesGeoJSON)) {
        setRanchesGeoJSONState(cloneDeep(ranchesGeoJSON));
      }
      if (!isEqual(expandedGeoJSONState, expandedGeoJSON)) {
        setExpandedGeoJSONState(cloneDeep(expandedGeoJSON));
      }
      if (!isEqual(featureCollectionState, featureCollection)) {
        setFeatureCollectionState(cloneDeep(featureCollection));
      }
    };
    const unsubscribe = plansEditStore.subscribe(subscription);

    plansEditStore.dispatch(setFeatureCollection(featureCollectionInitial));
    return function cleanup() {
      if (fGroupRef.current && fGroupRef.current.leafletElement) {
        fGroupRef.current.leafletElement.eachLayer((layer) => {
          layer.off('click', onFeatureClick);
        });
      }

      plansEditStore.dispatch(resetStore());
      unsubscribe(subscription);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setHasSelectedFeatureState,
    setRanchesGeoJSONState,
    setExpandedGeoJSONState,
    setFeatureCollectionState,
  ]);

  const [didDispatch, setDidDispatch] = useState(false);
  useEffect(() => {
    dispatch(loadPlanById(planId)).then(() => { setDidDispatch(true); });
  }, [dispatch, planId]);

  const selPlan = useSelector((state) => selectPlanById(planId)(state));

  // Primary entry poin where data from server gets added
  useEffect(() => {
    const { loading, error } = selPlan;

    if (loading) {
      return;
    }

    if (didDispatch && error) {
      notifyError(error?.message);
      return;
    }

    const { geoJSON, meta } = selPlan.data;

    let boundsNotSet = true;
    if (geoJSON && !isEqual(geoJSON, fCollectionFetchedBufState)) {
      plansEditStore.dispatch(setFeatureCollection(geoJSON));
      setFCollectionFetchedBufState(geoJSON);
      setDynamicRanchBoundaries(
        cloneDeep(geoJSON),
        meta?.ranchBounds,
        meta?.ranchLocation,
      );
      boundsNotSet = false;
    }

    if (plansRancNameState !== meta.ranchName) {
      setPlansRancNameState(cloneDeep(meta.ranchName));
    }

    const {
      ranchesGeoJSON,
    } = plansEditStore.getState();

    if (boundsNotSet
      && ranchesGeoJSON?.features[0]
      && !isEqual(
        selPlan?.data?.meta?.ranchBounds?.coordinates,
        ranchesGeoJSON?.features[0].geometry?.coordinates,
      )
    ) {
      setDynamicRanchBoundaries(
        cloneDeep(geoJSON),
        meta?.ranchBounds,
        meta?.ranchLocation,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selPlan, didDispatch]);

  useEffect(() => {
    if (plansRancNameState !== plansRancNameBufState) {
      setPlansRancNameBufState(cloneDeep(plansRancNameState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plansRancNameState]);

  useEffect(() => {
    if (!hasSelectedFeatureState && hasSelectedFeatureBufState) {
      setHasSelectedFeatureBufState(undefined);
    }
    if (hasSelectedFeatureState && !hasSelectedFeatureBufState) {
      setHasSelectedFeatureBufState(hasSelectedFeatureState);
    }
  }, [hasSelectedFeatureState, hasSelectedFeatureBufState]);

  useEffect(() => {
    setIsDrawerVisible(Boolean(hasSelectedFeatureState));
  }, [hasSelectedFeatureState]);

  useEffect(() => {
    if (!isEqual(featureCollectionBufState, featureCollectionState)) {
      reDraw(fGroupRef);

      setFeatureCollectionBufState(featureCollectionState);

      const blocks = featureCollectionState.features
        .filter((f) => f.properties.type === 'block')
        .sort(sortAndGroupBy);

      setBlockListData(blocks);

      const sensorsOkData = featureCollectionState.features
        .filter((f) => f.properties.type === 'sensor')
        .sort(sortAndGroupBy);

      const sensorsErrData = cloneDeep(selPlan.data.sensorsNoLocation)
        .sort(sortAndGroupBy);

      const sensorsData = cloneDeep(sensorsOkData)
        .concat(selPlan.data.sensorsNoLocation)
        .sort(sortAndGroupBy);

      setSensorListData(sensorsData);
      setSensorOkListData(sensorsOkData);
      setSensorErrListData(sensorsErrData);

      setObjectListData(
        featureCollectionState.features
          .filter((f) => f.properties.type === 'object')
          .sort(sortAndGroupBy),
      );

      const nestedFeatures = cloneDeep(blocks)
        .map((blck) => {
          const block = blck;
          block.properties.markers = [];
          return block;
        });

      nestedFeatures.push({
        properties: {
          noBlock: true,
          type: 'block',
          name: 'No block',
          markers: [],
        },
      });
      const nestedList = featureCollectionState.features
        .filter((f) => f.properties.type !== 'block')
        .reduce(
          (acc, f) => {
            const accLength = acc.length;
            if (!f.properties.block) {
              acc[accLength - 1].properties.markers.push(f);
              return acc;
            }

            for (let i = 0; i < accLength; i += 1) {
              if (acc[i].properties.id === f.properties.block.id) {
                acc[i].properties.markers.push(f);
                return acc;
              }
            }
            logger.error('Marker not included to anywhere');
            return acc;
          },
          nestedFeatures,
        );

      nestedList.sort(sortAndGroupBy);
      const nestedListLength = nestedList.length;
      for (let i = 0; i < nestedListLength; i += 1) {
        nestedList[i].properties.markers.sort(sortAndGroupBy);
      }

      setNestedListData(nestedList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureCollectionState]);

  useEffect(() => {
    if (viewType === 'map') {
      // alert('THAT EFFECT')
      reDraw(fGroupRef, hasSelectedFeatureBufState);
    } else {
      plansEditStore.dispatch(setSelectedFeature(null));
      fGroupRef.current = null;
    }
  }, [viewType, hasSelectedFeatureBufState]);

  return (
    <Layout className="plans-edit" style={{ overflowX: 'scroll' }}>
      <Modal
        title="Ranch Name"
        visible={isNameModalVisible}
        onOk={() => {
          const data = mapDataForPatching({
            dataGeoJSON: featureCollectionState,
            dataOriginal: selPlan.data.plan,
            ranchName: plansRancNameBufState,
            ranchBoundsGeoJSON: ranchesGeoJSONState,
          });

          dispatch(patchPlan(data))
            .then((res) => {
              if (res.error) {
                notifyError(res?.error?.message);
                return;
              }
              setIsNameModalVisible(false);
              dispatch(loadPlanById(planId));
            });
        }}
        onCancel={() => {
          setIsNameModalVisible(false);
          setPlansRancNameBufState(selPlan.data.meta.ranchName);
        }}
      >
        <div>
          <div>
            <Input
              style={{ width: '150px' }}
              value={plansRancNameBufState}
              onChange={(e) => {
                setPlansRancNameBufState(e.target.value);
              }}
            />
          </div>
        </div>
      </Modal>
      <PageHeader
        title="Edit Installation Plan"
        extra={[
          <div key="1" style={{ paddingTop: '2px', display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: '10px' }}>{plansRancNameBufState}</div>
            <Button
              style={{ width: '80px' }}
              type="primary"
              onClick={() => {
                setIsNameModalVisible(true);
              }}
            >
              Edit
            </Button>
          </div>,
        ]}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
        }}
      >
        <div
          style={{
            height: '100%',
            width: '100%',
            minHeight: '100%',
            minWidth: '100%',
            position: 'relative',
            display: 'flex',
          }}
        >
          <div
            className="list-switch-package"
            style={{
              position: 'absolute',
              top: '0',
              left: '14px',
              padding: '10px',
              zIndex: '1000',
            }}
          >
            <Button
              type={viewType === 'map' ? 'primary' : 'secondary'}
              value="map"
              icon={<FaMapMarkedAlt style={{ marginTop: '4px' }} />}
              size="large"
              data-test-id="switch-to-mapview"
              onClick={() => {
                if (viewType !== 'map') {
                  setViewType('map');
                }
              }}
            />
            <Button
              type={viewType === 'list' ? 'primary' : 'secondary'}
              value="list"
              icon={<FaList style={{ marginTop: '4px' }} />}
              size="large"
              data-test-id="switch-to-listview"
              onClick={() => {
                if (viewType !== 'list') {
                  setViewType('list');
                }
              }}
            />
          </div>
          {viewType === 'list' && (
            <PlansEditList
              {...{
                blockListData,
                sensorListData,
                sensorOkListData,
                sensorErrListData,
                objectListData,
                nestedListData,
                setViewType,
                featureCollectionBufState,
                ranchesGeoJSONState,
                planId,
                selPlan,
              }}
            />
          )}
          {viewType === 'map' && (
            <PlansEditMap
              {...{
                isDrawerVisible,
                hasSelectedFeatureState,
                fGroupRef,
                ranchesGeoJSONState,
                expandedGeoJSONState,
                setViewTypeList: () => setViewType('list'),
                brokenMarkers: (selPlan && selPlan.data.sensorsNoLocation) || [],
                planId,
                selPlan,
                setRanchesGeoJSONState,
              }}
            />
          )}
        </div>
      </div>
    </Layout>
  );
}

PlansEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      ranch_id: PropTypes.string,
      plan_id: PropTypes.string,
    }),
  }).isRequired,
};
