import React, { useState, useEffect } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { sensorApi } from "farmx-api";

import "./SoilDemoPage.css";

const { loadPixlRecentData, loadSensorDetail } = sensorApi;

const DataTable = () => {
  const params = useParams();
  const { sensorIdentifier } = params;
  const [soilData, setSoilData] = useState(null);
  const [sensorId, setSensorId] = useState(null);

  useEffect(() => {
    if (sensorIdentifier) {
      loadSensorDetail("pixl_soil", sensorIdentifier)
        .then((sensorDetailResponse) => {
          const { data: sensorDetail } = sensorDetailResponse;
          setSensorId(sensorDetail.id);
        })
        .catch((err) => {
          console.log("Unable to fetch sensor details", err);
        });
    }
  }, [sensorIdentifier, setSensorId]);

  useEffect(() => {
    let intervalId;

    const fetchData = () => {
      if (sensorId) {
        loadPixlRecentData(sensorId)
          .then((data) => {
            setSoilData(data.data);
          })
          .catch((err) => {
            console.log("Unable to load sensor data", err);
          });
      }
    };

    if (sensorIdentifier) {
      fetchData(); // Call immediately on sensorId change or component mount
      intervalId = setInterval(fetchData, 1000); // Then call every 1 second
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId); // Clear interval on unmount or sensorId change
      }
    };
  }, [sensorId, setSoilData]);

  const transposeData = (sensorData) => {
    var dataTransposed = [[], [], [], [], [], [], [], []];
    for (var i = 0; i < sensorData.length; i++) {
      for (var d = 0; d < 4; d++) {
        var param = "vwc_" + (d + 1);
        dataTransposed[d].push(sensorData[i][param]);
      }
    }
    return dataTransposed;
  };

  const renderTableData = (soilDataTransposed, showDataLabels) => {
    return soilDataTransposed.map((row, index) => (
      <tr key={index}>
        {row.map((cell, cellIndex) => {
          const value = cell * 100;
          return (
            <td key={cellIndex} style={{ backgroundColor: getColor(value) }}>
              {showDataLabels && (
                <div class="labels">
                  <div class="depth-label">{(index + 1) * 6} in</div>
                  <div class="data-label">{formatValue(Number(value))}</div>
                </div>
              )}
            </td>
          );
        })}
      </tr>
    ));
  };

  const colorScale = function(value) {
    // value from 0 to 100
    var hue = ((value / 100) * 120).toString(10);
    return ["hsl(", hue, ", 90%, 70%)"].join("");
  };

  const getColor = function(percent) {
    percent = percent * 2;
    if (percent > 100) percent = 100;
    if (percent < 0) percent = 0;
    return colorScale(percent);
  };

  const formatValue = function(percent) {
    if (percent > 100) percent = 100;
    if (percent < 0) percent = 0;
    return percent.toFixed(1) + "%";
  };

  if (!soilData) {
    return <LoadingOutlined className="demo-page-loader" />;
  }

  const sensorData = soilData.data;
  const dataLatest = [sensorData[0]];
  const dataRest = sensorData.slice(1);
  dataRest.reverse();

  const dataRestTransposed = transposeData(dataRest);
  const dataLatestTransposed = transposeData(dataLatest);

  return (
    <div class="soil-demo-container">
      <div class="sensor-label">{sensorIdentifier}</div>
      <table id="data" class="soil-data-table">
        <tbody>{renderTableData(dataRestTransposed, false)}</tbody>
      </table>
      <table id="data-latest" class="soil-data-table-latest">
        <tbody>{renderTableData(dataLatestTransposed, true)}</tbody>
      </table>
      <div class="time-label">Viewing the last 30 min of data</div>
    </div>
  );
};

export default DataTable;
