import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import { isEqual } from 'lodash';
import { helpers } from 'farmx-api';
import { isMobile } from 'react-device-detect';
import { usePrevious } from '../../../hooks';
import { columnDefs as columnDefinitions, mobileColumnDefinitions } from '../columnDefs';
import './table.css';

const { fieldToHeader } = helpers;

const columnStateFromColumns = (columns) => {
  if (columns instanceof Array) {
    return columns.map((value) => ({ colId: value }));
  }
  return [];
};

const anomalyColumnKeys = {
  anomalyType: 'anomaly_type',
  numIdentified: 'identified',
  numInvestigated: 'investigated',
  numAddressed: 'addressed',
};

const filterOptions = [
  'contains',
  {
    displayKey: 'blank',
    displayName: 'Blank',
    test: (filterValue, cellValue) => (
      cellValue === undefined || cellValue === null || cellValue === '' || cellValue === '-'
    ),
    hideFilterInput: true,
  },
  {
    displayKey: 'nonEmpty',
    displayName: 'Non Empty',
    test: (filterValue, cellValue) => (
      cellValue !== undefined && cellValue !== null && cellValue !== '' && cellValue !== '-'
    ),
    hideFilterInput: true,
  },
  'equals',
  'notEqual',
  'lessThan',
  'lessThanOrEqual',
  'greaterThan',
  'greaterThanOrEqual',
  'inRange',
];

function buildColumns(columnArr, showIndex) {
  let data = ['index', ...columnArr];
  const finalColumnDefs = isMobile ? mobileColumnDefinitions : columnDefinitions;
  if (!showIndex) data = columnArr;
  return data.map((value) => ({
    field: value,
    headerName: fieldToHeader(showIndex ? value : anomalyColumnKeys[value]),
    ...finalColumnDefs[value],
    filterParams: {
      filterOptions,
    },
  }));
}

const DataTable = React.forwardRef((props, gridRef) => {
  const {
    columns,
    columnsVisible,
    rowData,
    filters,
    sort,
    frameworkComponents,
    sizeToFit,
    filtersChangedCallback,
    loading,
    showIndex,
    pagination,
    onGridReady,
    onPaginationChanged,
  } = props;

  const previousFilters = usePrevious(filters);
  const previousSort = usePrevious(sort);

  // handle dark mode
  const darkMedia = window.matchMedia('(prefers-color-scheme: dark)');
  const [isDark, setIsDark] = useState(darkMedia.matches);
  const agTheme = isDark ? 'ag-theme-balham-dark' : 'ag-theme-balham';

  useEffect(() => {
    // Add listener to update styles
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => setIsDark(e.matches));
    // Remove listener
    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', () => { });
    };
  }, [setIsDark]);

  const columnDefs = buildColumns(columns, showIndex);
  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    // https://www.ag-grid.com/react-data-grid/filter-set-data-updates/#setting-new-data
    filterParams: { newRowsAction: 'keep' },
  };

  function sizeToFitFn() {
    gridRef.current.api.sizeColumnsToFit();
  }

  const autoSizeAll = useCallback(() => {
    const allColumnIds = [];
    gridRef.current.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.colId);
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
  }, [gridRef]);

  function autoSize() {
    autoSizeAll(false);
    if (sizeToFit) {
      sizeToFitFn();
    }
  }

  function updateIndex() {
    const params = { force: true };
    gridRef.current.api.refreshCells(params);
  }

  function onSortChanged() {
    updateIndex();
  }

  /*
   * Load filters into from ag grid, and return to callback
   * currently this is called an extra time
   */
  const onFilterChanged = () => {
    const filterModel = gridRef.current.api.getFilterModel();
    if (filtersChangedCallback) {
      filtersChangedCallback(filterModel);
    }
    updateIndex();
  };

  // update columns visible, and column order
  useEffect(() => {
    gridRef.current.columnApi.setColumnsVisible(columns, false);
    const columnsVisibleToSet = columnsVisible || columns;
    gridRef.current.columnApi.setColumnsVisible(columnsVisibleToSet, true);
    const newColState = columnStateFromColumns(['index', ...columnsVisibleToSet]);
    gridRef.current.columnApi.applyColumnState({
      state: newColState,
      applyOrder: true,
    });
    autoSizeAll(false);
  }, [columns, columnsVisible, gridRef, autoSizeAll]);

  // handle changes in filter props
  useEffect(() => {
    if (!isEqual(filters, previousFilters)) {
      gridRef.current.api.setFilterModel(filters);
      gridRef.current.api.onFilterChanged();
    }
  }, [filters, previousFilters, gridRef]);

  // handle changes in sort props
  useEffect(() => {
    if (!isEqual(sort, previousSort)) {
      gridRef.current.columnApi.applyColumnState({ state: sort });
    }
  }, [sort, previousSort, gridRef]);

  function onFirstDataRendered() {
    gridRef.current.api.setFilterModel(filters);
    gridRef.current.api.onFilterChanged();
    gridRef.current.columnApi.applyColumnState({ state: sort });
    autoSize();
  }

  // show/hide loading UI based on loading state
  useEffect(() => {
    if (loading) {
      gridRef.current.api.showLoadingOverlay();
    } else {
      gridRef.current.api.hideOverlay();
    }
  }, [gridRef, loading]);

  return (
    <div
      className={`${agTheme} ag-grid-container`}
    >
      <AgGridReact
        ref={gridRef}
        containerStyle={{
          height: !showIndex ? 'calc(100% - 485px)' : '100%',
          width: '100%',
        }}
        columnDefs={columnDefs}
        rowData={rowData}
        defaultColDef={defaultColDef}
        onFirstDataRendered={onFirstDataRendered}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Loading...</span>'}
        frameworkComponents={frameworkComponents}
        getRowStyle={(params) => {
          if (params.data.errors && params.data.errors.length > 0) {
            return { background: 'rgba(255, 0, 0, .9)' };
          }
          if (params.node.rowPinned) {
            return { 'font-weight': '500' };
          }
          return null;
        }}
        pagination={pagination}
        onGridReady={onGridReady}
        onPaginationChanged={onPaginationChanged}
      />
    </div>
  );
});

DataTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string),
  columnsVisible: PropTypes.arrayOf(PropTypes.string),
  rowData: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  filters: PropTypes.object,
  sort: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  frameworkComponents: PropTypes.object,
  sizeToFit: PropTypes.bool,
  filtersChangedCallback: PropTypes.func,
  loading: PropTypes.bool,
  showIndex: PropTypes.bool,
  pagination: PropTypes.bool,
  onGridReady: PropTypes.func,
  onPaginationChanged: PropTypes.func,
};

DataTable.defaultProps = {
  columns: [],
  columnsVisible: null,
  rowData: [],
  filters: {},
  sort: null,
  frameworkComponents: null,
  sizeToFit: false,
  filtersChangedCallback: () => { },
  loading: false,
  showIndex: true,
  pagination: false,
  onGridReady: null,
  onPaginationChanged: null,
};

export default DataTable;
