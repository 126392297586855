import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { sensorApi } from 'farmx-api';
import SensorNotesPageComponent from './SensorNotesPage';

const { getSensorNotes } = sensorApi;

const getPlaceholderItems = (count) => {
  const items = [];
  for (let i = 0; i < count; i += 1) {
    items.push({ id: i });
  }
  return items;
};

/**
 * getFilterOptions
 * Prioritizes ag-grid filters over url filters and returns object that can be passed
 * to getSensorNotes api.
 * @param {*} filters - filters from ag-grid table
 * @param {*} urlFilters - filters from url
 * @returns {}
 */
const getFilterOptions = (filters, urlFilters) => {
  const filterOptions = {};
  const {
    sensor, identifier, id, user, action,
  } = urlFilters;

  filterOptions.type = (filters.sensorType && filters.sensorType.filter)
    ? filters.sensorType.filter : sensor;

  filterOptions.identifier = (filters.sensorIdentifier && filters.sensorIdentifier.filter)
    ? filters.sensorIdentifier.filter : identifier;

  filterOptions.sensorId = (filters.sensorId && filters.sensorId.filter)
    ? filters.sensorId.filter : id;

  filterOptions.user = (filters.user && filters.user.filter)
    ? filters.user.filter : user;

  filterOptions.action = (filters.action && filters.action.filter)
    ? filters.action.filter : action;

  return filterOptions;
};

const SensorNotesPageWrapper = (props) => {
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [urlFilters, setUrlFilters] = useState({});
  const flags = useRef({
    paginationEffect: true,
    filterEffect: true,
  });

  /**
   * Fetch data from the new notes api.
   */
  const fetchData = useCallback(async (queryFilters) => {
    setLoading(true);
    const filterOptions = getFilterOptions(filters, (queryFilters || urlFilters));
    filterOptions.page = currentPage + 1;
    try {
      const response = await getSensorNotes(filterOptions);
      /**
       * placeHolderData is passed as data to actual <AgGrid /> component.
       * <AgGrid /> component supports pagination - client side and server side.
       * But server side is available only in enterprise edition.
       *
       * In our use-case we wanted to mock server-side using client side model.
       * Since with client side pagination we need to pass entire data set to the component
       * therefore we are creating a placeholderData which contains
       * - actual data (displayed on the screen) and
       * - dummy data for the pagination to render properly.
       *
       * For example if we have total of 1000 records, placeholderData contains
       * 100 actual records(returned by the api) and 900 dummy records.
       * When the next page is requested by user, we replace the dummy records with the actual
       * records.
       */
      const placeHolderData = getPlaceholderItems(response.data.count);
      placeHolderData.splice(
        currentPage * 100,
        response.data.results.length,
        ...response.data.results,
      );
      setData(placeHolderData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [currentPage, filters, urlFilters]);

  /**
   * Filter change listener.
   */
  const onFiltersChanged = (userFilters) => {
    if (Object.keys(filters).length === 0 && Object.keys(userFilters).length === 0) return;
    setCurrentPage(0);
    setFilters(userFilters);
  };

  const onGridReady = (gridApiObj) => {
    setGridApi(gridApiObj.api);
  };

  /**
   * Page change listener
   */
  const onPaginationChanged = () => {
    if (!gridApi) return;
    const currentPageNumber = gridApi.paginationGetCurrentPage();
    setCurrentPage(currentPageNumber);
  };

  /**
   * Fetch data when page is changed
   */
  useEffect(() => {
    if (currentPage === null) return;
    if (flags.current.paginationEffect) {
      flags.current.paginationEffect = false;
      return;
    }
    fetchData();
    // eslint-disable-next-line
  }, [currentPage]);

  /**
   * Fetch data when filters are changed.
   */
  useEffect(() => {
    if (flags.current.filterEffect) {
      flags.current.filterEffect = false;
      return;
    }
    fetchData();
    // eslint-disable-next-line
  }, [filters]);

  /**
   * Using this block from the legacy SensorNotesPage component.
   * Extracts query params and fetch data with the filters.
   */
  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const queryFilters = {};

    for (const [key, value] of params.entries()) {
      queryFilters[key] = value;
    }
    setUrlFilters(queryFilters);
    // load notes when queryFilters object is not empty
    if (!isEmpty(queryFilters)) {
      fetchData(queryFilters);
    }
    // eslint-disable-next-line
  }, [props.location.search]);

  return (
    <SensorNotesPageComponent
      lastUpdated={Date.now()}
      data={data}
      loadData={fetchData}
      loading={loading}
      filtersChanged={onFiltersChanged}
      onGridReady={onGridReady}
      onPaginationChanged={onPaginationChanged}
      filters={urlFilters}
    />
  );
};

export default connect()(SensorNotesPageWrapper);
