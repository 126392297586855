import React from 'react';
import PropTypes from 'prop-types';

import {
  PageHeader,
  Layout,
  Button,
  Form,
  Select,
  message,
  Input,
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import './sensorForm.css';

import { sensorApi } from 'farmx-api';
import { connect } from 'react-redux';
import { actions, selectors } from 'farmx-redux-core';
import { SensorSelect } from 'farmx-web-ui';

const { selectAllSensors } = selectors;
const { postSensorNote } = sensorApi;

const sensorActions = [
  {
    name: 'Other',
    key: 'other',
  },
  {
    name: 'Replace Battery',
    key: 'replace-battery',
  },
  {
    name: 'Maintenance',
    key: 'maintenance',
  },
  {
    name: 'Inspect',
    key: 'inspect',
  },
  {
    name: 'Update Firmware',
    key: 'update-firmware',
  },
  {
    name: 'Damage',
    key: 'damage',
  },
];

export class CreateNotePage extends React.Component {
  formRef = React.createRef();

  onFinish = async (values) => {
    const { notes, action, sensor } = values;
    const { type, identifier } = sensor;
    const success = await postSensorNote({
      sensorType: type,
      sensorIdentifier: identifier,
      action,
      text: notes,
      date: new Date(),
    });

    if (success) {
      message.success('Note created');
      this.formRef.current.resetFields();
    } else {
      message.error('Create note failed');
    }
  };

  render() {
    const {
      location: {
        state: {
          showBack,
        } = { showBack: false },
      },
    } = this.props;
    const {
      history,
      location,
      sensors,
      loadSensors,
    } = this.props;
    const { search } = location;
    const params = new URLSearchParams(search);
    const sensorIdentifier = params.get('sensorIdentifier');
    const sensorType = params.get('sensorType');
    const sensor = { type: sensorType, identifier: sensorIdentifier };
    return (
      <Layout>
        <PageHeader
          title="Create Note"
          onBack={() => history.goBack()}
          backIcon={
            showBack
              ? <ArrowLeftOutlined /> : false
          }
        />
        <div className="page-body">
          <Form
            ref={this.formRef}
            className="page-form"
            onFinish={this.onFinish}
            initialValues={{ sensor }}
          >
            <Form.Item
              // BUG: currently there is a bug where empty form does not
              // show errors (it doesnt validate though)
              label="Choose Sensor"
              name="sensor"
              rules={[
                {
                  required: true,
                  message: 'Please select sensor!',
                },
                () => ({
                  validator(rule, value) {
                    if (
                      value === undefined
                      || value.type === undefined
                      || value.identifier === undefined
                    ) {
                      return new Error('Please select sensor!');
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              validateTrigger="onSubmit"
            >
              <SensorSelect
                sensorType={sensor ? sensor.type : undefined}
                sensorIdentifier={sensor ? sensor.identifier : undefined}
                items={sensors}
                loadItems={loadSensors}
                useAllFarmData
              />
            </Form.Item>
            <Form.Item
              label="Choose action"
              name="action"
              rules={[{ required: true, message: 'Please select action!' }]}
            >
              <Select placeholder="Select action">
                {sensorActions.map((action) => (
                  <Select.Option
                    key={action.key}
                    value={action.key}
                  >
                    {action.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Notes"
              name="notes"
              rules={[{ required: false }]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">Confirm</Button>
            </Form.Item>
          </Form>
        </div>
      </Layout>
    );
  }
}

CreateNotePage.propTypes = {
  sensors: PropTypes.arrayOf(
    PropTypes.shape({
      identifier: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  loadSensors: PropTypes.func.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({}),
    search: PropTypes.string,
  }),
};

CreateNotePage.defaultProps = {
  sensors: [],
  history: {
    goBack: () => {},
  },
  location: {
    state: {
      showBack: false,
      sensor: null,
    },
  },
};

const mapStateToProps = (state, props) => {
  const sensors = selectAllSensors(state);
  return { sensors };
};

const mapDispatchToProps = {
  loadSensors: actions.loadAllSensors,
  // createNoteAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNotePage);

/* import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import {
  PageHeader,
  Layout,
  Button,
  Icon,
  Form,
  Select,
  message,
} from 'antd';

import './sensorForm.css';
import SensorFormSelect from './SensorFormSelect';

import { sensorApi } from 'farmx-api';
import { loadSensorDetailAction } from '../actions';

const { getSensorKey } = sensorApi;

const sensorsSelector = createSelector(
  state => state.sensorsData,
  state => state.sensors.items
);

export function CreateNotePage({ form }) {
  const history = useHistory();
  const location = useLocation();
  const { pathname, search } = location;
  const sensors = sensorsSelector();

  const {
    state: {
      showBack,
    } = { showBack: false },
  } = location;

  const params = new URLSearchParams(search);
  const sensorIdentifier = params.get('sensorIdentifier');
  const sensorType = params.get('sensorType');

  const foundSensor = useSelector((state) => {
    console.log(state);
    const { sensorsData: { sensors: { items }}} = state;
    console.log(items);
    if (!items || !sensorType || !sensorIdentifier) {
      return null;
    }
    const sensorKey = getSensorKey({ type: sensorType, identifier: sensorIdentifier });
    console.log(sensorKey, items[sensorKey]);
    return items[sensorKey] || null;
  });

  function handleSubmit() {

  }

  const [sensor, setSensor] = useState(foundSensor);

  useEffect(() => {
    loadSensorDetailAction();
  }, [sensorIdentifier, sensorType]);

  console.log(sensor);

  const { getFieldDecorator } = form;

  return (
    <Layout>
      <PageHeader
        title="Remove Sensor"
        onBack={() => history.goBack()}
        backIcon={
          showBack ?
          <Icon type="arrow-left" /> : false
        }
      >
      </PageHeader>
      <div className="page-body">
        <Form className="page-form" onSubmit={handleSubmit}>
          <Form.Item
            label="Choose Sensor"
          >
            {
              getFieldDecorator('sensor', {
                initialValue: sensor,
                // BUG: currently there is a bug where empty form does not show errors (it doesnt validate though)
                validateTrigger: 'onSubmit',
                rules: [{
                  required: true,
                  message: 'Please select sensor!',
                },
                {
                  type: 'object',
                  validator: (rule, value) => value !== undefined && value.type !== undefined && value.identifier !== undefined,
                  message: 'Please select sensor!'
                }],
              })(
              <SensorFormSelect
                sensor={sensor}
                sensors={sensors}
                // isFetching={this.props.isFetching}
                // loadSensors={this.props.loadSensors}
              />
            )}
          </Form.Item>
          <Form.Item
            label="Choose removed/withdrawn"
          >
            {
              getFieldDecorator('newState', {
                rules: [{ required: true, message: 'Please select removed/withdrawn!' }],
              })(
                <Select placeholder="Select new state">
                  <Select.Option value="withdrawn">Withdrawn</Select.Option>
                  <Select.Option value="removed">Removed</Select.Option>
                </Select>
              )
            }
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">Confirm</Button>
          </Form.Item>
        </Form>
      </div>
    </Layout>
  );
}

export default Form.create({ name: 'create-note' })(CreateNotePage);
*/
