import React, { useEffect, useMemo } from 'react';
import { Typography } from 'antd';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'farmx-redux-core';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import './blockDetailPage.css';

export const BlockDetailPage = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = params || {};
  const blockDetails = useSelector((state) => selectors.selectSensorStats(state, 'block', id));
  const {
    loading, name, areaAcres, controlEnabled, schedulingEnabled,
  } = blockDetails || {};

  useEffect(() => {
    if (id) {
      dispatch(actions.loadBlockStatsSingle({ type: 'block', identifier: id }));
    }
  }, [dispatch, id]);
  const title = useMemo(() => {
    if (name) {
      return `${t('Block Detail')} - ${name}`;
    }
    return t('Block Detail');
  }, [name, t]);

  return (
    <div className="block-detail-page">
      <Typography.Title level={3}>{title}</Typography.Title>
      {loading ? (
        <LoadingOutlined className="block-page-loader" />
      ) : (
        <div>
          <div className="block-detail-display-single">
            <Typography.Text>{`${t('Id')}:`}</Typography.Text>
            <Typography.Text>{id}</Typography.Text>
          </div>
          <div className="block-detail-display-single">
            <Typography.Text>{`${t('Area acres')}:`}</Typography.Text>
            <Typography.Text>{areaAcres}</Typography.Text>
          </div>
          <div className="block-detail-display-single">
            <Typography.Text>{`${t('Control Enabled')}:`}</Typography.Text>
            <Typography.Text>{controlEnabled}</Typography.Text>
          </div>
          <div className="block-detail-display-single">
            <Typography.Text>{`${t('Scheduling Enabled')}:`}</Typography.Text>
            <Typography.Text>{schedulingEnabled}</Typography.Text>
          </div>
        </div>
      )}
    </div>
  );
};
