import React from 'react';
import { hooks } from 'farmx-redux-core';
import { Link } from 'react-router-dom';
import { sensorPropType } from '../propTypes';

const { useSensor } = hooks;

const getSensorTitle = (item) => {
  if (item === undefined) return null;
  if (item === null) return null;
  if (item.hostname) return item.hostname;
  const name = item.name || '<No name>';
  const identifier = item.identifier || 'No identifier';
  if (item.id) {
    return `${name} (${identifier}) [${item.id}]`;
  }
  return null;
};

function SensorLink({ sensor }) {
  const sensorLoaded = useSensor(sensor);
  if (!sensorLoaded) return null;
  return (
    <Link to={`/detail/${sensorLoaded.type}/${sensorLoaded.identifier}`}>
      {getSensorTitle(sensorLoaded)}
    </Link>
  );
}

SensorLink.propTypes = {
  sensor: sensorPropType.isRequired,
};

export default SensorLink;
