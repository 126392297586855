import { Button, Form, Input, Modal } from "antd";
import React from "react";
import { useTranslation } from 'react-i18next';

//@ts-ignore
import { SensorSelect, RanchSelect, BlockSelect } from 'farmx-web-ui';

interface SensorCopySoilMoisturePropertiesFormProps {
    sensors: any;     
    on_submit_copy_form: (selected_sensors: any[], selected_ranch: any, selected_block: any) => void;
    disabled?: boolean;
}

export const SensorCopySoilMoisturePropertiesForm: React.FC<SensorCopySoilMoisturePropertiesFormProps> = ({ sensors, on_submit_copy_form, disabled }) => {
  const { t } = useTranslation();
  const [selected_sensors, setSelectedSensors] = React.useState<any[]>([]);
  const [selected_ranch, setSelectedRanch] = React.useState<any>(null);
  const [selected_block, setSelectedBlock] = React.useState<any>(null);

  const onSelectSensor = (sensors: any[]) => {
    if (!sensors || sensors.length === 0) {
      setSelectedSensors([]);
    } else {
      const formattedSensors = sensors.map(sensor => ({
        'sensor_identifier': sensor.identifier,
        'sensor_type': sensor.type
      }));
      setSelectedSensors(formattedSensors);
    }
  }

  const onSelectRanch = (ranch: any) => {
    if(ranch == undefined) setSelectedRanch(null)
    else setSelectedRanch(ranch)
  }

  const onSelectBlock = (block: any) => {
    if(block == undefined) setSelectedBlock(null)
    else setSelectedBlock(block)
  }
  
  return (
    <Form 
      disabled={disabled}
      onFinish={() => {
      if(selected_sensors.length === 0 && selected_ranch == null && selected_block == null) {
        Modal.error({
          title: t('Error'),
          content: t('Please select a sensor, block, or ranch.')
        });
        return;
      }
      Modal.confirm({
        title: t('Confirm copy'),
        content: t('Are you sure you want to copy the soil moisture properties of the selected sensor? This action will overwrite the existing soil moisture properties of the selected sensor, block, or ranch.'),
        onOk: () => {
          on_submit_copy_form(selected_sensors, selected_ranch, selected_block)
          // close the modal
          return new Promise((resolve, reject) => {
            resolve(null);
          });
        }
      });
    }}>
      <p style={{fontStyle: 'italic'}}>{t("Copy the selected sensor's soil moisture properties to another sensor, an entire block, or an entire ranch.")}</p>
      <p style={{fontStyle: 'italic'}}> {t("This will copy the 'wilting_point_#', 'field_capacity_#', 'refill_percent_#', 'soil_type_#' fields from the source sensor to the targets.")}</p>
      <br />
      <Form.Item label={t("Select sensor:")}>
        <SensorSelect
          sensorTypeConstraint={['aquacheck_soil', 'pixl_soil']}
          mode="multiple"
          sensorType={undefined}
          sensorIdentifier={undefined}
          items={sensors}
          loadItems={null}
          onChange={onSelectSensor}
          placeholder={t('Select sensor')}
          useAllFarmData
        />
      </Form.Item>
      <Form.Item label={t("Select block:")}>
        <BlockSelect
          className="block-select"
          placeholder={t('Select block')}
          onChange={onSelectBlock}
          applyFilter={false}
          useAllBlockData
        />
      </Form.Item>
      <Form.Item label={t("Select ranch:")}>
        <RanchSelect
            onChange={onSelectRanch}
            placeholder={t('Select ranch')}
            loadItems={() => null}
            useAllRanchData
          />
      </Form.Item>
      <Form.Item>
            <Button type="primary" htmlType="submit">{t('Confirm')}</Button>
        </Form.Item>
    </Form>
  )
}