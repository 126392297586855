/* eslint-disable no-param-reassign */
import { createSlice, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import area from '@turf/area';
import booleanContains from '@turf/boolean-contains';

const initialState = {
  featureCollection: undefined,
  selectedFeature: undefined,
  newFeatureType: undefined,
  featureBeforeEditGeoJSON: undefined,
  ranchesGeoJSON: undefined,
  expandedGeoJSON: undefined,
};

export const plansEditSlice = createSlice({
  name: 'plansEdit',
  initialState,
  reducers: {
    resetStore(state) {
      Object.assign(state, initialState);
    },
    setSelectedFeature(state, { payload }) {
      state.selectedFeature = payload;
      state.featureBeforeEditGeoJSON = payload ? cloneDeep(payload.toGeoJSON()) : undefined;
    },
    setNewFeatureType(state, { payload }) {
      state.newFeatureType = payload;
    },
    setNameForSelected(state, { payload }) {
      if (state.selectedFeature) {
        state.selectedFeature.feature.properties.name = payload;
      }
    },
    setSelectedSensorType(state, { payload }) {
      if (state.selectedFeature) {
        state.selectedFeature.feature.properties.sensorType = payload;
      }
    },
    setSelectedObjectDescription(state, { payload }) {
      if (state.selectedFeature) {
        state.selectedFeature.feature.properties.description = payload;
      }
    },
    setSelectedCoordinates(state, { payload }) {
      if (state.selectedFeature) {
        state.selectedFeature.feature.geometry.coordinates = payload;
      }
    },
    setRanchesGeoJSON(state, { payload }) {
      state.ranchesGeoJSON = payload;
    },
    setExpandedGeoJSON(state, { payload }) {
      state.expandedGeoJSON = payload;
    },
    setFeatureCollection(state, { payload }) {
      if (!payload || !Object.keys(payload).length) {
        state.featureCollection = undefined;
        return;
      }
      const featureCollection = cloneDeep(payload)
        .features
        .reduce(
          (acc, feature, _, arr) => {
            if (feature.properties.type === 'block') {
              feature.properties.squareMeters = area(feature);
              feature.properties.acres = Number(
                (feature.properties.squareMeters / 4046.86).toFixed(1),
              );
            } else {
              feature.properties.block = false;
              const arrLength = arr.length;
              for (let i = 0; i < arrLength; i += 1) {
                const f = arr[i];
                if (f.properties.type === 'block' && booleanContains(f, feature)) {
                  feature.properties.block = {
                    name: f.properties.name,
                    id: f.properties.id,
                  };
                  break;
                }
              }
            }
            acc.features.push(feature);
            return acc;
          },
          {
            type: 'FeatureCollection',
            features: [],
          },
        );

      state.featureCollection = featureCollection;
    },
    updateFeature(state, { payload }) {
      // eslint-disable-next-line prefer-destructuring
      const id = payload.properties.id;
      payload.properties.notSaved = true;
      const featuresLength = state.featureCollection.features.length;
      for (let i = 0; i < featuresLength; i += 1) {
        if (state.featureCollection.features[i].properties.id === id) {
          state.featureCollection.features[i] = payload;
          return;
        }
      }
    },
  },
});

export const plansEditStore = configureStore({
  reducer: plansEditSlice.reducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  }),
});
