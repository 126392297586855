import React from 'react';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { constants } from 'farmx-api';
import { hooks } from 'farmx-redux-core';

const { useRanch } = hooks;

const getEditUrl = (id) => `${constants.ADMIN_URL}/farm/ranch/${id}/`;
function RanchLink({ id }) {
  const ranch = useRanch(id);
  const { t } = useTranslation();
  if (!ranch) return null;
  return (
    <>
      <Link to={`/manage/sensor/?ranch=${ranch.name}`}>
        {`${ranch.name} [${ranch.id}]`}
      </Link>
      &nbsp;
      <Button href={getEditUrl(id)} size="small">{t('Edit')}</Button>
    </>
  );
}

RanchLink.propTypes = {
  id: PropTypes.number.isRequired,
};

export default RanchLink;
