import React from 'react';
import { List } from 'antd';
import { hooks } from 'farmx-redux-core';

import { SensorAttributeListItem } from './SensorAttributeListItem';
import { sensorPropType } from '../propTypes';

const { useSensor } = hooks;


export default function SensorDetailTable(props) {
  const { sensor: sensorProps } = props;
  const sensor = useSensor(sensorProps);
  return (
    <List>
      <SensorAttributeListItem attr="name" sensor={sensor} force />
      <SensorAttributeListItem attr="type" sensor={sensor} force />
      <SensorAttributeListItem attr="identifier" sensor={sensor} force />
      <SensorAttributeListItem attr="id" sensor={sensor} force />
      <SensorAttributeListItem attr="entity" sensor={sensor} force />
      <SensorAttributeListItem attr="ranch" sensor={sensor} force />
      <SensorAttributeListItem attr="block" sensor={sensor} force />
      <SensorAttributeListItem attr="gateway" sensor={sensor} />
      <SensorAttributeListItem attr="node" sensor={sensor} />
    </List>
  );
}

SensorDetailTable.propTypes = {
  sensor: sensorPropType.isRequired,
};
