import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import axios from 'axios';
import center from '@turf/center';
import {
  Layout,
  PageHeader,
  Button,
  Select,
  Input,
  Typography,
} from 'antd';
import { actions, selectors } from 'farmx-redux-core';
import { Map } from 'farmx-web-ui';
import { useHistory } from 'react-router';
import { notifyError } from './plans-edit/utils';

const { Text } = Typography;

const { createPlan } = actions;

const accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

function useValueDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay],
  );

  return debouncedValue;
}

/**
 * https://docs.mapbox.com/api/search/
 * @param {String} searchString
 */
async function geoCodeFetch(searchString) {
  try {
    const { data } = await axios.create({ timeout: 10000 }).get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(`${searchString}`)}.json`,
      {
        params: { access_token: accessToken },
        withCredentials: false,
      },
    );
    return data;
  } catch (error) {
    return error;
  }
}

const { loadRanchDetail } = actions;

const { ranchSelectors } = selectors;

const selectRanchData = (ranchId) => createSelector(
  [
    (state) => ranchSelectors.selectById(state, ranchId),
  ],
  (ranch) => {
    const result = {
      valid: false,
      ranch: {},
      loading: true,
      error: false,
    };

    if (!ranch) {
      return result;
    }

    if (ranch.loading) {
      return result;
    }

    result.loading = false;

    if (ranch.error) {
      result.error = ranch.error;
      return result;
    }

    result.ranch = ranch;

    result.valid = true;
    return result;
  },
);

export function PlansNew({ match: { params } }) {
  const history = useHistory();
  const ranchId = Number(params.ranch_id);
  const [didDispatch, setDidDispatch] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadRanchDetail(ranchId)).then(() => { setDidDispatch(true); });
  }, [ranchId, dispatch]);

  const selRanchData = useSelector((state) => selectRanchData(ranchId)(state));

  useEffect(() => {
    if (didDispatch && !selRanchData?.loading && selRanchData?.error) {
      notifyError(selRanchData?.error?.message);
    }
  }, [selRanchData, didDispatch]);

  const [name, setName] = useState(undefined);
  const [singleMarkerGeoJSON, setSingleMarkerGeoJSON] = useState(undefined);

  const [searchTerm, setSearchTerm] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const debouncedSearchTerm = useValueDebounce(searchTerm, 500);

  const [options, setOptions] = useState([]);
  const [location, setLocation] = useState(undefined);
  const [areOptionsOpen, setAreOptionsOpen] = useState(false);
  const [notValidated, setNotValidated] = useState(true);
  const [ranchData, setRanchData] = useState(undefined);

  useEffect(
    () => {
      if (!debouncedSearchTerm) {
        return;
      }
      ((async () => {
        setIsSearching(true);
        const res = await geoCodeFetch(debouncedSearchTerm);
        if (res instanceof Error) {
          notifyError(res?.message);
        } else if (res.features instanceof Array && res.features.length) {
          const f = res.features[0];
          setOptions([<Select.Option key={`${f.center[1]}, ${f.center[0]}`}>{f.place_name}</Select.Option>]);
          setAreOptionsOpen(true);
        }
        setIsSearching(false);
      })());
    },
    [debouncedSearchTerm],
  );

  useEffect(
    () => {
      if (selRanchData.valid && !isEqual(selRanchData.ranch, ranchData)) {
        setRanchData(selRanchData.ranch);
        if (selRanchData.ranch.bounds) {
          try {
            const loc = center(selRanchData.ranch.bounds).geometry.coordinates;
            setLocation(`${loc[1]}, ${loc[0]}`);
            setSingleMarkerGeoJSON({
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [loc[1], loc[0]],
              },
              properties: {
                name: 'Selected',
              },
            });
            // eslint-disable-next-line no-empty
          } catch (error) { }
        }
      }
    },
    [selRanchData, ranchData],
  );

  useEffect(
    () => {
      if (selRanchData.valid && name && location && notValidated) {
        setNotValidated(false);
        return;
      }
      if (!(selRanchData.valid && name && location) && !notValidated) {
        setNotValidated(true);
      }
    },
    [notValidated, selRanchData, name, location],
  );

  return (
    <Layout className="entitylist-page" style={{ overflowX: 'scroll' }}>
      <PageHeader
        title={!selRanchData.loading && !selRanchData.error ? `Create Installation Plan${ranchData && ranchData.name && ` for ${ranchData.name}`}` : 'Create Installation Plan'}
      />
      {!selRanchData.loading && !selRanchData.error && (
        <div
          style={{
            paddingLeft: '1.8em',
            paddingRight: '1.8em',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
          }}
        >
          <div>
            <Text>Name</Text>
            <Input
              style={{ marginBottom: '1em' }}
              id="new-plan-name"
              value={name}
              onChange={(e) => {
                setName(e.target.value.trimStart());
              }}
            />
            <Text>Location</Text>
            <Select
              loading={isSearching}
              showSearch
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              open={areOptionsOpen}
              style={{ marginBottom: '1em', width: '100%' }}
              value={location}
              onFocus={() => {
                setLocation(undefined);
              }}
              onSearch={(search) => {
                setSingleMarkerGeoJSON(undefined);
                setOptions([]);
                setLocation(undefined);
                const str = search.trim();
                if ((/(.*[a-z]){2}/i).test(str)) {
                  setSearchTerm(str);
                } else if ((/^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/).test(str)) {
                  const latLngArray = str.split(',');
                  latLngArray[0] = Number(latLngArray[0].trim());
                  latLngArray[1] = Number(latLngArray[1].trim());
                  const sLatLng = `${latLngArray[0]}, ${latLngArray[1]}`;
                  setSingleMarkerGeoJSON({
                    type: 'Feature',
                    geometry: {
                      type: 'Point',
                      coordinates: [latLngArray[0], latLngArray[1]],
                    },
                    properties: {
                      name: 'Selected',
                    },
                  });
                  setOptions([<Select.Option key={sLatLng}>{sLatLng}</Select.Option>]);
                  setLocation(sLatLng);
                }
              }}
              onChange={(val) => {
                const latLngArray = val.split(',');
                latLngArray[0] = Number(latLngArray[0].trim());
                latLngArray[1] = Number(latLngArray[1].trim());
                const sLatLng = `${latLngArray[0]}, ${latLngArray[1]}`;
                setSingleMarkerGeoJSON({
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: [latLngArray[0], latLngArray[1]],
                  },
                  properties: {
                    name: 'Selected',
                  },
                });
                setOptions([<Select.Option key={sLatLng}>{sLatLng}</Select.Option>]);
                setLocation(sLatLng);
                setAreOptionsOpen(false);
              }}
            >
              {options}
            </Select>
          </div>
          <div style={{ flexGrow: '4', height: '100%', width: '100%' }}>
            <Map
              singleMarkerGeoJSON={singleMarkerGeoJSON}
              onClickMap={(e) => {
                const sLatLng = `${e.latlng.lat}, ${e.latlng.lng}`;
                setSingleMarkerGeoJSON({
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: [e.latlng.lat, e.latlng.lng],
                  },
                  properties: {
                    name: 'Selected',
                  },
                });
                setOptions([<Select.Option key={sLatLng}>{sLatLng}</Select.Option>]);
                setLocation(sLatLng);
                setAreOptionsOpen(false);
              }}
            />
          </div>

          <div
            style={{
              marginBottom: '1em',
              marginTop: '1em',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              type="secondary"
              id="cancel-new-plan"
              style={{ width: '80px' }}
              onClick={() => {
                setOptions([]);
                setLocation(undefined);
                setSingleMarkerGeoJSON(undefined);
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              id="save-new-plan"
              style={{ width: '80px' }}
              disabled={notValidated}
              onClick={() => {
                const latLngArray = location.split(',');
                let ranchLocation = null;
                if (latLngArray.length === 2) {
                  latLngArray[0] = Number(latLngArray[0].trim());
                  latLngArray[1] = Number(latLngArray[1].trim());
                  ranchLocation = {
                    type: 'Point',
                    coordinates: [latLngArray[0], latLngArray[1]],
                  };
                }

                dispatch(createPlan({
                  ranch: ranchId,
                  ranch_bounds: ranchData && ranchData.bounds ? ranchData.bounds : null,
                  ranch_location: ranchLocation,
                  ranch_name: name,
                  blocks: [],
                  objects: [],
                  sensors: [],
                }))
                  .then((res) => {
                    if (res.error) {
                      notifyError(res?.error?.message);

                      return;
                    }
                    if (res?.payload?.id) {
                      history.push(`/installation-plan/edit/${res.payload.id}`);
                    }
                  });
              }}
            >
              Create
            </Button>
          </div>
        </div>
      )}

    </Layout>
  );
}

PlansNew.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      ranch_id: PropTypes.string,
      entity_id: PropTypes.string,
    }),
  }).isRequired,
};
