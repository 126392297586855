import { isEmpty } from 'lodash';
import { sensorApi, helpers, userApi } from 'farmx-api';

const { fieldToHeader } = helpers;

export const getName = (sensorDetails) => {
  const { type: sType, identifier: sIdentifier, name: sName } = sensorDetails;
  if (!isEmpty(sName)) return sName;
  return `${sType}/${sIdentifier}`;
};

/**
 * Processes an array of data points to include gaps where data are missing.
 * If the time difference between consecutive timestamps is more than 1 hour,
 * inserts a data point with null value to indicate a gap.
 *
 * @param {Array} d - Array of data points where each point is [timestamp, value].
 * @returns {Array} Processed array of data points including data gaps [timestamp, value].
 */
const processDataGap = (d) => {
  const processedData = [];
  d.forEach((v, i) => {
    if (i !== 0) {
      const prevTimestamp = d[i - 1][0];
      const currentTimestamp = v[0];
      const timeDifference = currentTimestamp - prevTimestamp;

      // Insert null value if time difference is more than 1 hour (3600 * 1000 milliseconds)
      if (timeDifference > 3600 * 1000) {
        const averageTimestamp = (prevTimestamp + currentTimestamp) / 2;
        processedData.push([averageTimestamp, null]);
      }
    }
    processedData.push(v);
  });
  return processedData;
};

// build config for existing api data
export function buildConfig(seriesData, mergeAxes, compact) {
  if (!seriesData) return {};

  const yAxisByUnit = {};
  const allSeries = Object.entries(seriesData).map(([key, value], i) => {
    // TODO remove after testing
    console.log('key', key, 'value', value);
    if (!value.length) return undefined;
    const chartData = { ...value[0] };
    if (!chartData.data.length) return undefined;

    let { data: variableData } = chartData;

    // detect values as strings and convert
    if (typeof variableData[0][1] === 'string') {
      variableData = variableData.map((datum) => [datum[0], parseFloat(datum[1])]);
    }

    // fix units of %
    if (chartData.units === '%') {
      variableData = variableData.map((datum) => [datum[0], 100 * datum[1]]);
      chartData.min *= 100;
      chartData.max *= 100;
    }

    // if int, dont set decimal places, otherwise set to 2
    const isFloat = variableData[0][1] % 1 !== 0;
    const decimals = isFloat ? 2 : undefined;

    const chartUnits = chartData.units || '';
    const axisKey = mergeAxes ? chartUnits : chartUnits + key;
    const regex = /&deg;/g;
    const units = chartUnits.replace(regex, 'º');
    const unitsStr = ` (${units})`;
    const variableName = fieldToHeader(key);
    const legendTitle = variableName + unitsStr;

    let yAxis = yAxisByUnit[axisKey];
    if (!yAxis) {
      const axisTitle = variableName + unitsStr;
      yAxis = {
        title: {
          text: axisTitle,
        },
        id: axisKey,
        opposite: !compact,
        min: parseFloat(chartData.min),
        max: parseFloat(chartData.max),
      };
      yAxisByUnit[axisKey] = yAxis;
    } else {
      yAxis.title.text = units;
      yAxis.min = Math.min(yAxis.min, chartData.min);
      yAxis.max = Math.max(yAxis.max, chartData.max);
    }

    if (compact) {
      yAxis.title = null;
      yAxis.labels = {
        x: 0,
        y: -2,
        align: 'left',
      };
    }

    // handle custom coloring for percent max wetness
    let zones;
    if (chartData.variable.startsWith('percent_max_wetness')) {
      zones = [{
        color: '#e8101e',
        value: 0,
      }, {
        color: '#faad14',
        value: 50,
      }, {
        color: 'rgb(61, 200, 115)',
        value: 100,
      }, {
        color: '#e8101e',
      }];
    }

    return {
      id: `series-${i}`,
      actualKey: key,
      name: legendTitle,
      zones,
      type: 'line',
      data: processDataGap(variableData),
      yAxis: axisKey,
      tooltip: {
        valueDecimals: decimals,
      },
    };
  });
  return {
    yAxis: Object.values(yAxisByUnit),
    series: allSeries.filter(Boolean),
  };
}

function getYminMax(data) {
  const values = data.filter((d) => !isNaN(d[1])).map((d) => Number(d[1]));
  const min = Math.min(...values);
  const max = Math.max(...values);
  return { min, max };
}

// config for new api version data
export function buildConfigV1(seriesData, mergeAxes, compact) {
  if (!seriesData) return {};

  const yAxisByUnit = {};
  const allSeries = Object.entries(seriesData).map(([key, value], i) => {
    // TODO remove after testing
    // console.log('key', key, 'value', value);
    if (isEmpty(value) || !value.data?.length) return undefined;
    const chartData = { ...value };
    if (!chartData?.data?.length) return undefined;

    let { data: variableData } = chartData;

    // detect values as strings and convert
    if (typeof variableData[0][1] === 'string') {
      variableData = variableData.map((datum) => [datum[0], parseFloat(datum[1])]);
    }
    const { min, max } = getYminMax(variableData[0].data);
    chartData.min = min;
    chartData.max = max;

    // fix units of %
    if (chartData.units === '%') {
      variableData = variableData.map((datum) => [datum[0], 100 * datum[1]]);
      chartData.min *= 100;
      chartData.max *= 100;
    }

    // if int, dont set decimal places, otherwise set to 2
    const isFloat = variableData[0][1] % 1 !== 0;
    const decimals = isFloat ? 2 : undefined;

    const chartUnits = chartData.units || '';
    const axisKey = mergeAxes ? chartUnits : chartUnits + key;
    const regex = /&deg;/g;
    const units = chartUnits.replace(regex, 'º');
    const unitsStr = ` (${units})`;
    const variableName = fieldToHeader(key);
    const legendTitle = variableName + unitsStr;

    let yAxis = yAxisByUnit[axisKey];
    if (!yAxis) {
      const axisTitle = variableName + unitsStr;
      yAxis = {
        title: {
          text: axisTitle,
        },
        id: axisKey,
        opposite: !compact,
        min: parseFloat(chartData.min),
        max: parseFloat(chartData.max),
      };
      yAxisByUnit[axisKey] = yAxis;
      console.log('yAxis', yAxis);
      console.log('chartData', chartData);
    } else {
      yAxis.title.text = units;
      yAxis.min = Math.min(yAxis.min, chartData.min);
      yAxis.max = Math.max(yAxis.max, chartData.max);
    }

    if (compact) {
      yAxis.title = null;
      yAxis.labels = {
        x: 0,
        y: -2,
        align: 'left',
      };
    }

    // handle custom coloring for percent max wetness
    let zones;
    if (chartData.variable.startsWith('percent_max_wetness')) {
      zones = [{
        color: '#e8101e',
        value: 0,
      }, {
        color: '#faad14',
        value: 50,
      }, {
        color: 'rgb(61, 200, 115)',
        value: 100,
      }, {
        color: '#e8101e',
      }];
    }

    return {
      id: `series-${i}`,
      actualKey: key,
      variableName,
      unitsStr,
      name: `${legendTitle} ${variableData?.[0].gateway}`,
      zones,
      type: 'line',
      data: variableData,
      yAxis: axisKey,
      tooltip: {
        valueDecimals: decimals,
      },
    };
  }).reduce((acc, series) => {
    if (series) {
      const { data } = series;
      data && data.length && data.forEach((d, j) => {
        acc.push({
          ...series,
          id: `series-${series.variableName} ${d.gateway}`,
          data: processDataGap(d.data),
          name: `${series.variableName} ${d.gateway} ${series.unitsStr}`,
          yAxis: `${series.yAxis}${d.gateway}`,
        });
        yAxisByUnit[`${series.yAxis}${d.gateway}`] = {
          ...yAxisByUnit[series.yAxis], min: d.min, max: d.max, id: `${series.yAxis}${d.gateway}`,
        };
      });
      delete yAxisByUnit[series.yAxis];
    }
    return acc;
  }, []);
  return {
    yAxis: Object.values(yAxisByUnit),
    series: allSeries.filter(Boolean),
  };
}
