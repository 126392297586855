import React from 'react';
import PropTypes from 'prop-types';
import {
  PageHeader,
  Layout,
  Button,
  Form,
  message,
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import './sensorForm.css';

import { connect } from 'react-redux';
import { actions, selectors } from 'farmx-redux-core';
import { SensorSelect } from 'farmx-web-ui';

const {
  selectAllSensors,
} = selectors;

export class SensorReplacePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sensorType: undefined,
    };
    this.formRef = React.createRef();
  }

  onFinish = async (values) => {
    const { replaceSensor } = this.props;
    const { newSensor, oldSensor } = values;
    const { identifier: newSensorIdentifier } = newSensor;
    const { type: sensorType, identifier: oldSensorIdentifier } = oldSensor;
    const success = await replaceSensor({
      sensorType,
      oldSensorIdentifier,
      newSensorIdentifier,
    });
    // Check if async action is fulfilled
    if (success.type.split('/').at(-1) === 'fulfilled') {
      message.success('Sensor replaced');
      this.formRef.current.resetFields();
    } else {
      message.error('Sensor replace failed');
    }
  };

  onSensorChange = (sensor) => {
    const sensorType = sensor ? sensor.type : undefined;
    this.setState({
      sensorType,
    });
  }

  render() {
    const {
      sensors,
      loadSensors,
      history,
      location: {
        state: {
          showBack,
          sensor,
        } = { showBack: false, sensor: null },
      },
    } = this.props;
    const { sensorType } = this.state;
    return (
      <Layout>
        <PageHeader
          title="Replace Sensor"
          onBack={() => history.goBack()}
          backIcon={
            showBack
              ? <ArrowLeftOutlined /> : false
          }
        />
        <div className="page-body">
          <Form
            ref={this.formRef}
            className="page-form"
            onFinish={this.onFinish}
            initialValues={{
              newSensor: sensor,
              oldSensor: sensor,
            }}
          >
            <Form.Item
              label="Choose Sensor to Replace"
              name="oldSensor"
              validateTrigger="onSubmit"
              rules={[
                {
                  required: true,
                  message: 'Please select sensor!',
                },
                () => ({
                  validator(rule, value) {
                    if (
                      value === undefined
                      || value.type === undefined
                      || value.identifier === undefined
                    ) {
                      return new Error('Please select sensor!');
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <SensorSelect
                sensorType={sensor ? sensor.type : undefined}
                sensorIdentifier={sensor ? sensor.identifier : undefined}
                items={sensors}
                onChange={this.onSensorChange}
                loadItems={loadSensors}
                useAllFarmData
              />
            </Form.Item>
            <Form.Item
              label="Choose Replacement Sensor"
              name="newSensor"
              validateTrigger="onSubmit"
              rules={[
                {
                  required: true,
                  message: 'Please select sensor!',
                },
                () => ({
                  validator(rule, value) {
                    if (
                      value === undefined
                      || value.type === undefined
                      || value.identifier === undefined
                    ) {
                      return new Error('Please select sensor!');
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <SensorSelect
                items={sensors}
                loadItems={loadSensors}
                sensorTypeConstraint={(sensorType || (sensor && sensor.type))
                  ? [sensorType || sensor.type] : undefined}
                useAllFarmData
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">Confirm</Button>
            </Form.Item>
          </Form>
        </div>
      </Layout>
    );
  }
}

SensorReplacePage.propTypes = {
  sensors: PropTypes.arrayOf(
    PropTypes.shape({
      identifier: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  loadSensors: PropTypes.func.isRequired,
  replaceSensor: PropTypes.func.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({}),
  }),
};

SensorReplacePage.defaultProps = {
  sensors: [],
  history: {
    goBack: () => {},
  },
  location: {
    state: {
      showBack: false,
      sensor: null,
    },
  },
};

const mapStateToProps = (state) => {
  const sensors = selectAllSensors(state);
  return { sensors };
};

const mapDispatchToProps = {
  loadSensors: actions.loadAllSensors,
  replaceSensor: actions.replaceSensor,
};

export default connect(mapStateToProps, mapDispatchToProps)(SensorReplacePage);
