import React from 'react';
import {
  Dropdown,
  Button,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import SensorActionsMenu from './SensorActionsMenu';

export default class SensorActionsDropdown extends React.Component {
  render () {
    return (
      <Dropdown overlay={<SensorActionsMenu sensor={this.props.sensor} />}>
        <Button>Actions <DownOutlined /></Button>
      </Dropdown>
    );
  }
}
