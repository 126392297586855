import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Select, List } from 'antd';
import { sensorApi, helpers } from 'farmx-api';
import { SensorTypeTag } from 'farmx-web-ui';
import { sensorPropType } from '../propTypes';

const {
  getSensorKey,
} = sensorApi;
const { sanitizeSearchString } = helpers;
const { Option } = Select;

export default function SensorIdentifierSelect(props) {
  const {
    sensors,
    showSensorType,
    selectedSensor,
    isFetching,
    onChange,
    placeholder,
  } = props;

  function getPlaceholder() {
    if (selectedSensor) return selectedSensor.split('/')[-1];
    return placeholder;
  }

  function getValue(value) {
    if (value.name) return `${value.identifier} ${value.name}`;
    return value.identifier;
  }

  const getOptions = useCallback(() => sensors.filter((e) => getSensorKey(e)).map(
    (value) => (
      <Option
        key={getSensorKey(value)}
        label={value.name || value.identifier}
        style={{ borderBottom: '1px solid #eee' }}
        data={getValue(value)}
      >
        <List.Item style={{ padding: '0px' }}>
          <List.Item.Meta
            title={value.name || 'No name'}
            description={value.identifier}
          />
          <div>
            {showSensorType
              ? <SensorTypeTag sensorType={value.type} />
              : null}
          </div>
        </List.Item>
      </Option>
    ),
  ), [sensors, showSensorType]);

  function filterOption(input, option) {
    if (!option.data) return false;
    const lowerCaseOption = sanitizeSearchString(option.data);
    return lowerCaseOption.indexOf(sanitizeSearchString(input)) >= 0;
  }

  return (
    <Select
      className="sensor-identifier-select"
      showSearch
      style={{ flex: 1 }}
      placeholder={getPlaceholder()}
      optionLabelProp="label"
      value={selectedSensor}
      dropdownMatchSelectWidth={false}
      dropdownStyle={{ minWidth: '350px' }}
      notFoundContent={isFetching ? 'loading...' : 'No sensors found'}
      filterOption={filterOption}
      onChange={onChange}
    >
      {getOptions()}
    </Select>
  );
}

SensorIdentifierSelect.propTypes = {
  sensors: PropTypes.arrayOf(sensorPropType),
  showSensorType: PropTypes.bool,
  selectedSensor: PropTypes.string,
  isFetching: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

SensorIdentifierSelect.defaultProps = {
  sensors: [],
  showSensorType: false,
  selectedSensor: undefined,
  isFetching: false,
  onChange: () => {},
  placeholder: 'Select Sensor',
};
