import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import './graph.css';

import {
  PageHeader,
  Button,
  Input,
  DatePicker,
  message,
} from 'antd';
import {
  ArrowLeftOutlined, LeftOutlined, RightOutlined, SwapOutlined,
} from '@ant-design/icons';
import { FaDownload } from 'react-icons/fa';
import { sensorApi } from 'farmx-api';
import moment from 'moment';
import isEqual from 'react-fast-compare';
import axios from 'axios';
import SensorDataChart from './SensorDataChart';
import SensorBreadCrumb from '../sensor/components/SensorBreadcrumb';
import { getName } from './helper';
import SelectSensorCapabilitiesList from './SelectSensorCapabilitiesList';

const {
  getSensorObjectFromSensorKeyId,
  getSensorKeyId,
} = sensorApi;

const parseSensorFromParam = (sensorParam) => {
  if (sensorParam === undefined || sensorParam === null) return undefined;
  return getSensorObjectFromSensorKeyId(sensorParam);
};

export class GraphPage extends React.Component {
  constructor(props) {
    super(props);
    const { location } = props;
    const params = new URLSearchParams(location.search);
    const sensorType = params.get('sensorType');
    const sensorIdentifier = params.get('sensorIdentifier');
    const startDate = params.get('startDate');
    const endDate = params.get('endDate');

    let sensor = {}; // kept to support existing usecases, but needs to be deprecated in future.
    let sensors = [];
    const capabilities = [];
    const types = sensorType?.split(',');
    const identifiers = sensorIdentifier?.split(',');
    if (types && types?.length
      && identifiers?.length) {
      types.forEach((type, index) => {
        sensors.push({ type, identifier: identifiers[index] });
        let variablesStr;
        if (!index) variablesStr = params.get('variables');
        else variablesStr = params.get(`variables${index}`);
        const variables = variablesStr ? variablesStr.split(',') : [];
        capabilities.push(variables);
      });
    }

    if (!sensorIdentifier && !sensorType) {
      const sensorParam = params.get('sensor');
      const parsedSensor = parseSensorFromParam(sensorParam);
      if (parsedSensor) {
        sensor = parsedSensor;
        sensors = [sensor];
      }
    }

    this.state = {
      startDate: startDate ? moment(startDate) : null,
      endDate: endDate ? moment(endDate) : null,
      capabilities,
      sensor,
      sensors,
      mergeAxes: true,
      selectedFilters: [],
      colors: [],
      isLoading: false,
      newColors: [],
      rows: 1,
    };
  }

  componentDidMount = () => {
    // clear url params on load
  }

  componentDidUpdate = (prevProps, prevState) => {
    const {
      startDate, endDate, capabilities, sensor, sensors,
    } = this.state;
    let urlNeedsUpdate = false;
    if (prevState.startDate !== startDate) urlNeedsUpdate = true;
    if (prevState.endDate !== endDate) urlNeedsUpdate = true;
    if (!isEqual(prevState.capabilities, capabilities)) urlNeedsUpdate = true;
    if (!isEqual(prevState.sensors, sensors)) {
      urlNeedsUpdate = true;
      if (prevState.sensor.type !== sensor.type) {
        this.setState({ capabilities: [] });
      }
    }
    if (urlNeedsUpdate) {
      this.updateUrl();
    }
  }

  updateUrl = () => {
    const { location, history } = this.props;
    const { pathname } = location;
    history.push({
      pathname,
      search: this.getParamStr(),
    });
  }

  setSelectedFilters = (dat) => {
    this.setState({
      selectedFilters: dat,
    });
  }

  onSensorSelect = (sensors) => {
    // TODO remove after testing
    // console.log('sensors set', sensors);
    this.setState({
      sensors,
    });
  }

  onCapabilitiesChange = (capabilities) => {
    // TODO remove after testing
    // console.log('set capabilities', capabilities, option);
    this.setState({
      capabilities,
    });
  }

  onDatesChanged = (dates) => {
    this.setState({
      startDate: dates[0],
      endDate: dates[1],
    });
  }

  getParamStr = () => {
    const {
      startDate,
      endDate,
      sensor: sensorData,
      sensors,
      capabilities,
    } = this.state;
    const params = {};

    // create sensor params
    if (sensorData && sensorData.type && sensorData.id && !sensorData.identifier) {
      params.sensor = getSensorKeyId(sensorData);
    } else
    if (sensors) {
      sensors.forEach((sensor, i) => {
        if (sensor && sensor.type) {
          if (i === 0) params.sensorType = sensor.type;
          else params.sensorType += `,${sensor.type}`;
        }
        if (sensor && sensor.identifier) {
          if (i === 0) params.sensorIdentifier = sensor.identifier;
          else params.sensorIdentifier += `,${sensor.identifier}`;
        }
      });
    }
    // add capabilities
    if (capabilities && capabilities.length) {
      capabilities.forEach((list, i) => {
        if (i === 0) params.variables = list?.length ? list.join(',') : [];
        else params[`variables${i}`] = list?.join(',');
      });
    }
    // add dates
    if (startDate) params.startDate = startDate.toISOString();
    if (endDate) params.endDate = endDate.toISOString();

    let paramStr = Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');
    if (paramStr.length) paramStr = `?${paramStr}`;
    return paramStr;
  }

  getCurrentUrl = () => {
    const { location: { pathname } } = this.props;
    const { protocol, host } = window.location;
    const paramStr = this.getParamStr();
    return `${protocol}//${host}${pathname}${paramStr}`;
  }

  showPageLink = () => {
    const url = this.getCurrentUrl();
    // addonAfter={<Button onClick={this.copyToClipboard(url))}><Icon type="copy" /></Button>}
    message.open({
      content: <Input addonBefore="Link" value={url} />,
    });
  }

  getDownloadUrl = async () => {
    const {
      startDate,
      endDate,
      sensors,
      capabilities,
    } = this.state;
    if (!capabilities || !capabilities.length
      || !sensors.length || (!sensors[0].id && !sensors[0].identifier)
      || !startDate || !endDate) {
      message.warn('Missing parameters');
      return null;
    }
    this.setState({
      isLoading: true,
    });
    sensors.forEach(async (sensor, i) => {
      if (capabilities[i]?.length) {
        const baseUrl = 'https://map.farmx.co/api/sensors/data/csv/';
        const sensorIdParameter = sensor.identifier ? `sensorIdentifier=${sensor.identifier}` : `sensorId=${sensor.id}`;
        const sensorsParameter = `sensorType=${sensor.type}&${sensorIdParameter}`;
        const variablesArr = capabilities[i].join(',');
        const variablesParameter = `variables=${variablesArr}`;
        const datesParameters = `startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
        const url = `${baseUrl}?${sensorsParameter}&${variablesParameter}&${datesParameters}&cached=true/`;

        await axios.get(
          url,
        ).then((response) => {
          const hrefUrl = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = hrefUrl;
          link.setAttribute('download', 'FarmXDataExport.csv');
          link.click();
        }).catch(() => {
          message.error('Failed to download');
        });
      }
    });
    this.setState({
      isLoading: false,
    });
  }

  callback = (param, chart, sensors) => {
    if (chart?.series) {
      const keyColorMap = chart.series
        .map((d) => [d.userOptions.actualKey, d.color])
        .reduce((acc, p) => {
          const [key, color] = p;
          acc[key] = color;
          return acc;
        }, {});

      const keyList = Object.keys(keyColorMap);
      // TODO remove after test
      // console.log('keyColorMap', keyColorMap, param, chart, 'keyList', keyList);
      const { capabilities } = this.state;
      if (keyList.length) {
        const idColorsMap = {};
        sensors.forEach((sensor, i) => {
          const newColors = [];
          if (capabilities[i]) {
            capabilities[i].forEach((variable) => {
              const key = `${getName(sensor)}_${variable}`;
              // TODO remove after test
              // console.log('key', key);
              if (!keyList.includes(key)) {
                newColors.push({ [key]: null });
              } else {
                newColors.push({ [key]: keyColorMap[key] });
              }
            });
          }
          // TODO remove after test
          // console.log('newColors', newColors, 'capabilities', capabilities);
          idColorsMap[sensor?.identifier] = newColors;
        });
        // TODO remove after test
        // console.log('idColorsMap', idColorsMap);
        this.setState({ newColors: idColorsMap });
      }
    } else {
      const { colors } = (param && param.options) || { colors: [] };
      if (colors) this.setState((prev) => ({ ...prev, colors: [...colors] }));
    }
  };

  onPreviousClick = () => {
    const { startDate, endDate } = this.state;
    const difference = endDate.diff(startDate, 'days');

    this.setState({
      startDate: moment(startDate.subtract(difference, 'days')),
      endDate: moment(endDate.subtract(difference, 'days')),
    });
  };

  onNextClick = () => {
    const { startDate, endDate } = this.state;
    const difference = endDate.diff(startDate, 'days');

    this.setState({
      startDate: moment(startDate.add(difference, 'days')),
      endDate: moment(endDate.add(difference, 'days')),
    });
  };

  onTodayClick = () => {
    this.setState({
      startDate: moment().startOf('day'),
      endDate: moment().startOf('day').add(1, 'day'),
    });
  };

  onWeekClick = () => {
    this.setState({
      startDate: moment().startOf('day').subtract(6, 'days'),
      endDate: moment().startOf('day').add(1, 'day'),
    });
  };

  onAddRow = () => {
    const { rows } = this.state;
    this.setState({
      rows: rows + 1,
    });
  }

  render() {
    const {
      startDate,
      endDate,
      sensor,
      sensors,
      capabilities,
      mergeAxes,
      isLoading,
      newColors,
    } = this.state;
    const { showBack, history } = this.props;
    // TODO remove after test
    // console.log('state', this.state);
    return (
      <div className="graph-page">
        <Helmet>
          <title>Graph</title>
        </Helmet>
        <PageHeader
          title="Sensor Graph"
          subTitle={(
            <SensorBreadCrumb
              sensor={sensor}
            />
          )}
          onBack={() => history.goBack()}
          backIcon={
            showBack
              ? <ArrowLeftOutlined /> : false
          }
          extra={[
            <Button
              key="toggle-merge"
              onClick={() => this.setState({ mergeAxes: !mergeAxes })}
            >
              <SwapOutlined />
              {!mergeAxes ? 'Merge Axes' : 'Unmerge Axes'}
            </Button>,
            <Button
              key="download"
              onClick={this.getDownloadUrl}
              disabled={isLoading}
            >
              <FaDownload />
              <span style={{ marginLeft: '10px' }}>
                {isLoading ? 'Downloading' : 'Download' }
              </span>
            </Button>,
          ]}
        >
          <div className="flex-input-group">
            <div className="sensor-capabilities-list">
              <SelectSensorCapabilitiesList
                onSensorSelect={this.onSensorSelect}
                onCapabilitiesChange={this.onCapabilitiesChange}
                values={{ sensors, capabilities }}
                colors={newColors}
              />
            </div>
            <div className="graph-date-range">
              <DatePicker.RangePicker
                defaultValue={[startDate, endDate]}
                onChange={this.onDatesChanged}
                showSecond={false}
                value={[startDate, endDate]}
                showTime={{
                  format: 'HH:mm',
                }}
                format="YYYY-MM-DD HH:mm"
              />
              <div className="date-range-shortcuts">
                <Button onClick={this.onTodayClick}>Today</Button>
                <Button onClick={this.onWeekClick}>Week</Button>
                <Button onClick={this.onPreviousClick}>
                  <LeftOutlined />
                  Previous
                </Button>
                <Button onClick={this.onNextClick}>
                  Next
                  <RightOutlined />
                </Button>
              </div>
            </div>
          </div>

        </PageHeader>
        <div className="chart-container">
          <SensorDataChart
            sensor={sensor}
            sensors={sensors}
            variables={capabilities}
            startDate={startDate}
            endDate={endDate}
            mergeAxes={mergeAxes}
            chartCallback={this.callback}
          />
        </div>
      </div>
    );
  }
}

GraphPage.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({}),
    pathname: PropTypes.string,
    search: PropTypes.string,
  }),
  showBack: PropTypes.bool,
};

GraphPage.defaultProps = {
  history: {
    goBack: () => { },
    push: () => { },
  },
  showBack: false,
  location: {
    search: '',
    pathname: '',
    state: {
      showBack: false,
      sensor: null,
    },
  },
};

export default GraphPage;
