import React, { useEffect, useState, useRef } from 'react';
import { RanchSelect } from 'farmx-web-ui';
import { reportsApi } from 'farmx-api';
import {
  Button, Form, DatePicker, message,
} from 'antd';
import moment from 'moment';
import DataTable from '../table/components/DataTable';

function AnomalySummaryTable() {
  const gridRef = useRef(null);
  const [form] = Form.useForm();

  const defaultDateRange = [moment().startOf('year'),
    moment().endOf('year')];
  const [data, setData] = useState(null);

  const columns = data?.length ? Object.keys(data[0]) : [];
  const columnsForTotal = columns.filter((d) => d !== 'anomalyType');

  function calculatePinnedBottomData(target, gridApi) {
    // list of columns fo aggregation
    const newTarget = { ...target };
    const columnsWithAggregation = columnsForTotal;
    columnsWithAggregation.forEach((element) => {
      gridApi.forEachNodeAfterFilter((rowNode) => {
        if (rowNode.data[element]) {
          newTarget[element] += Number(rowNode.data[element].toFixed(2));
        }
      });
    });
    return newTarget;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function generatePinnedBottomData(gridColumnApi, gridApi) {
    const result = {};
    gridColumnApi.getAllGridColumns().forEach((item) => {
      result[item.colId] = null;
      if (item.colId === 'anomalyType') result[item.colId] = 'Total';
    });

    return calculatePinnedBottomData(result, gridApi);
  }

  useEffect(() => {
    if (!gridRef.current) return;

    const gridApi = gridRef.current.api;
    const gridColumnApi = gridRef.current.columnApi;
    const pinnedBottomData = generatePinnedBottomData(gridColumnApi, gridApi);
    if (pinnedBottomData) {
      gridRef.current.api.setPinnedBottomRowData([pinnedBottomData]);
    }
  }, [generatePinnedBottomData, gridRef]);

  function onFinish(values) {
    const anomalyReportObj = {
      ranch: values?.ranch,
      dateStart: values?.dateRange?.[0]?.toISOString(),
      dateEnd: values?.dateRange?.[1]?.toISOString(),
    };

    // API call to get anomaly summary report
    reportsApi.getAnomalySummaryReport(anomalyReportObj).then((response) => {
      if (response?.data) {
        setData(response.data);
      }
    }, () => {
      message.error('Failed to download anomaly report.');
    });
  }

  function checkRanch(rule, value) {
    if (value) {
      return Promise.resolve();
    }
    return Promise.reject(Error('Please select ranch'));
  }

  function renderAnomalySummaryFormControl() {
    return (
      <Form
        form={form}
        onFinish={onFinish}
        layout="inline"
        style={{ display: 'flex' }}
        initialValues={{ ranch: null, dateRange: defaultDateRange }}
      >
        <Form.Item
          name="ranch"
          validateTrigger="onFinish"
          style={{ width: '250px' }}
          rules={[
            {
              validator: checkRanch,
            },
          ]}
        >
          <RanchSelect placeholder="Select Ranch" useAllRanchData />
        </Form.Item>
        <Form.Item
          name="dateRange"
          rules={[{ required: true, message: 'Please select date range' }]}
          style={{ flex: '1 1' }}
        >
          <DatePicker.RangePicker
            format="YYYY-MM-DD"
            showTime={false}
          />
        </Form.Item>
        <Form.Item style={{ marginRight: '0px' }}>
          <Button type="primary" htmlType="submit">
            <span>Show</span>
          </Button>
        </Form.Item>
      </Form>
    );
  }

  return (
    <>
      <div className="flex-input-group">
        {renderAnomalySummaryFormControl()}
      </div>
      <div className="data-table-cont" style={{ marginTop: '10px' }}>
        {data ? (
          <DataTable
            ref={gridRef}
            loading={false}
            fullscreen={false}
            columns={columns}
            rowData={data}
            showIndex={false}
          />
        ) : null}
      </div>
    </>
  );
}

export { AnomalySummaryTable };
