import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { SensorSelect } from 'farmx-web-ui';
import SensorCapabilitySelect from '../sensor/components/SensorCapabilitySelect';

export function SensorGraphForm(props) {
  const {
    onSubmit,
    onChangeFilters,
  } = props;
  const [form] = Form.useForm();
  const [, forceUpdate] = useState();
  const [sensor, setSensor] = useState();

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  function onFinish(values) {
    onSubmit(values);
  }

  function checkSensor(rule, value) {
    if (value && value.type && value.identifier) {
      return Promise.resolve();
    }
    return Promise.reject(Error('Please select sensor'));
  }

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="inline"
      style={{ display: 'flex' }}
    >
      <Form.Item
        name="sensor"
        validateTrigger="onFinish"
        rules={[
          {
            validator: checkSensor,
          },
        ]}
      >
        <SensorSelect
          onChange={setSensor}
          onChangeFilters={onChangeFilters}
          useAllFarmData
        />
      </Form.Item>
      <Form.Item
        name="variable"
        rules={[{ required: true, message: 'Please select variable' }]}
        style={{ flex: '1 1' }}
      >
        <SensorCapabilitySelect
          sensor={sensor}
          disabled={!sensor}
          mode={null}
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          <PlusOutlined />
          <span>Add</span>
        </Button>
      </Form.Item>
    </Form>
  );
}

SensorGraphForm.propTypes = {
  onSubmit: PropTypes.func,
  onChangeFilters: PropTypes.func,
};

SensorGraphForm.defaultProps = {
  onSubmit: () => {},
  onChangeFilters: null,
};
