import React from 'react';
import StatsPage from '../../table/components/StatsPage';

import { connect } from 'react-redux';
import { actions, selectors } from 'farmx-redux-core';

const { loadSensorImagesList } = actions;
const {
  selectAllSensorImages,
  selectLoadingSensorImages,
} = selectors;

const columns = [
  'date',
  'sensor',
  'identifier',
  'title',
  'image',
  'location',
  'username',
];

export class SensorImagesPage extends React.Component {

  constructor(props) {
    super(props);

    const params = new URLSearchParams(props.location.search);
    const urlFilters = {};

    for (const [key, value] of params.entries()) {
      urlFilters[key] = {
        filterType: "text",
        type: "equals",
        filter: value,
      };
    };

    const defaultFilters = {};
    const filters = Array.from(params).length ? urlFilters : defaultFilters;

    this.state = {
      filters,
      sort: [
        { colId: 'date', sort: 'dsc' },
      ],
    };
  }

  clearFilters = () => {
    this.setState({
      filters: {},
      sort: null,
    });
  }

  filtersChanged = (filters) => {
    this.setState({
      filters: filters,
    });
  }

  render () {
    const { filters, sort } = this.state;
    const { lastUpdated, data, loading, loadData } = this.props;
    return (
      <StatsPage
        title="Sensor Images"
        columns={ columns }
        loadData={loadData}  
        data={lastUpdated ? data : undefined}
        loading={loading}
        lastUpdated={lastUpdated}
        filters={ filters }
        sort={ sort }
        filtersChangedCallback={ this.filtersChanged }
        sizeToFit
      />
    );
  }
}

const mapStateToProps = (state) => {
  const images = selectAllSensorImages(state);
  const sensorImagesLoading = selectLoadingSensorImages(state);
  const { loading, lastUpdated } = sensorImagesLoading;

  return {
    loading,
    lastUpdated,
    data: images,
  }
};

const mapDispatchToProps = {
  loadData: loadSensorImagesList,
};

export default connect(mapStateToProps, mapDispatchToProps)(SensorImagesPage);
