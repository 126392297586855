import explode from '@turf/explode';
import concave from '@turf/concave';
import buffer from '@turf/buffer';
import booleanContains from '@turf/boolean-contains';
import simplify from '@turf/simplify';
import cloneDeep from 'lodash/cloneDeep';
import { logger } from 'farmx-api';
import { notification } from 'antd';

/**
 * https://www.w3resource.com/javascript-exercises/javascript-math-exercise-23.php
 */
export const createUUID = () => {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    // eslint-disable-next-line no-bitwise,no-mixed-operators
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
  return uuid;
};

export const isLat = (lat) => lat !== '' && isFinite(lat) && Math.abs(lat) <= 90;

export const isLng = (lng) => lng !== '' && isFinite(lng) && Math.abs(lng) <= 180;

export const areValidCoords = ([lng, lat]) => isLat(lat) && isLng(lng);

export const getCoordsTxt = ([lng, lat]) => {
  if (isLat(lat) && isLng(lng)) {
    return `${lat},${lng}`;
  }

  return `${lat}${lng}`;
};

export const sortAndGroupBy = (a, b) => {
  const typeA = a.properties.type;
  const typeB = b.properties.type;
  const nameA = a.properties.name.toUpperCase();
  const nameB = b.properties.name.toUpperCase();
  if (typeA === 'block' && typeB === 'block') {
    if (a.properties.noBlock) {
      return 1;
    }
    return nameA.localeCompare(nameB);
  }

  if (typeA === 'sensor' && typeB === 'sensor') {
    const sensorTypeA = a.properties.sensorType;
    const sensorTypeB = b.properties.sensorType;
    if (sensorTypeA === sensorTypeB) {
      return nameA.localeCompare(nameB);
    }
    return sensorTypeA.localeCompare(sensorTypeB);
  }

  if (typeA === 'object' && typeB === 'sensor') {
    return 1;
  }

  if (typeA === 'sensor' && typeB === 'object') {
    return -1;
  }

  return nameA.localeCompare(nameB);
};

const areItemsInBounds = (bounds, itemsFeatureCollection) => {
  let itemsFitInBounds = true;
  const gJSONFeaturesLength = itemsFeatureCollection.features.length;
  for (let i = 0; i < gJSONFeaturesLength; i += 1) {
    itemsFitInBounds = itemsFitInBounds
      && booleanContains(bounds, itemsFeatureCollection.features[i]);
    if (!itemsFitInBounds) {
      return false;
    }
  }
  return true;
};

const calculateBonds = (gJSON, boundsFeature) => {
  const boundsFC = {
    type: 'FeatureCollection',
    features: [],
  };
  let points;
  try {
    if (boundsFeature) {
      if (boundsFeature.type !== 'Feature') {
        gJSON.features.push({
          type: 'Feature',
          geometry: boundsFeature,
          properties: { type: 'bounds' },
        });
      } else {
        gJSON.features.push(boundsFeature);
      }

      points = explode(gJSON.features
        .reduce(
          (acc, curr) => {
            acc.features.push(curr);
            return acc;
          },
          {
            type: 'FeatureCollection',
            features: [],
          },
        ));
      const boundary = concave(points);
      const ranchGeoJSON = simplify(boundary, {
        tolerance: 0.00001,
        highQuality: true,
      });
      ranchGeoJSON.id = '1';
      ranchGeoJSON.geometry.type = 'MultiLineString';
      boundsFC.features.push(ranchGeoJSON);
      return boundsFC;
    }

    const blocks = gJSON.features.filter((f) => f.properties.type === 'block');
    const nonBlocks = gJSON.features.filter((f) => f.properties.type !== 'block');
    if (!blocks.length && nonBlocks.length < 3) {
      points = explode(buffer({
        type: 'FeatureCollection',
        features: nonBlocks,
      }, 0.01, { units: 'kilometers' }));
    } else {
      points = explode(gJSON.features
        .reduce(
          (acc, curr) => {
            acc.features.push(curr);
            return acc;
          },
          {
            type: 'FeatureCollection',
            features: [],
          },
        ));
    }

    const boundary = concave(points);

    const ranchGeoJSON = simplify(boundary, {
      tolerance: 0.00001,
      highQuality: true,
    });
    ranchGeoJSON.id = '1';
    ranchGeoJSON.geometry.type = 'MultiLineString';
    boundsFC.features.push(ranchGeoJSON);
  } catch (error) {
    logger.error(error, { view: 'PlansEdit', op: 'calculateDynamicBoundaries' });
  }
  return boundsFC;
};

export const calculateDynamicBoundaries = (
  fc,
  planBoundaries,
  planLoc,
  editedBoundsFeature,
) => {
  const gJSON = cloneDeep(fc);
  const planBounds = cloneDeep(planBoundaries);
  const planLocation = cloneDeep(planLoc);
  const newBounds = {
    type: 'FeatureCollection',
    features: [],
  };

  if (editedBoundsFeature) {
    const manuallyEditedBoundsFeature = cloneDeep(editedBoundsFeature);
    if (!gJSON?.features?.length) {
      return calculateBonds(gJSON, manuallyEditedBoundsFeature);
    }
    if (gJSON?.features?.length && areItemsInBounds(manuallyEditedBoundsFeature, gJSON)) {
      newBounds.features.push({
        id: '1',
        geometry: {
          coordinates: manuallyEditedBoundsFeature.geometry.coordinates,
          type: 'MultiLineString',
        },
        type: 'Feature',
      });
      return newBounds;
    }
    if (gJSON?.features?.length && !areItemsInBounds(manuallyEditedBoundsFeature, gJSON)) {
      return calculateBonds(gJSON, manuallyEditedBoundsFeature);
    }
  }

  const ranchesGeoJSON = {
    type: 'FeatureCollection',
    features: [],
  };

  if (!gJSON?.features?.length) {
    if (planBounds) {
      ranchesGeoJSON.features.push({
        id: 1,
        geometry: {
          type: 'MultiLineString',
          coordinates: planBounds.coordinates,
        },
        type: 'Feature',
      });
      return ranchesGeoJSON;
    }

    if (planLocation) {
      const points = explode(buffer({
        id: 1,
        geometry: {
          type: planLocation.type,
          coordinates: [planLocation.coordinates[1], planLocation.coordinates[0]],
        },
        type: 'Feature',
      }, 0.03, { units: 'kilometers' }));

      const boundary = concave(points);
      const boundaryExpanded = buffer(boundary, 0.03, { units: 'kilometers' });
      const ranchGeoJSON = simplify(boundaryExpanded, {
        tolerance: 0.00001,
        highQuality: true,
      });
      ranchGeoJSON.id = '1';
      ranchGeoJSON.geometry.type = 'MultiLineString';
      ranchesGeoJSON.features.push(ranchGeoJSON);

      return ranchesGeoJSON;
    }

    return undefined;
  }

  if (gJSON?.features?.length && planBounds) {
    if (areItemsInBounds(planBounds, gJSON)) {
      newBounds.features.push({
        id: '1',
        geometry: {
          coordinates: planBounds.coordinates,
          type: 'MultiLineString',
        },
        type: 'Feature',
      });
      return newBounds;
    }
    return calculateBonds(gJSON, planBounds);
  }
  return calculateBonds(gJSON);
};

/*
SENSORS
type - string - (required) - sensor type string e.g. aquacheck_soil
name - string
location - geojson point
state - string - planned or installed, defaults to planned (we can ignore this field for now)

BLOCKS
block - integer - block id
name - (required) - block name
bounds - geojson polygon

OBJECTS - array of PlannedObject objects
name - string - (required)
location - geojson point
description - string
*/
export const mapDataForPatching = ({
  dataGeoJSON,
  dataOriginal,
  ranchName,
  ranchBoundsGeoJSON,
  featureToRemove,
  featureToUpdate,
}) => {
  const data = cloneDeep(dataOriginal);

  if (ranchName) {
    data.ranch_name = ranchName;
  }

  if (ranchBoundsGeoJSON && ranchBoundsGeoJSON === Object(ranchBoundsGeoJSON)
    && ranchBoundsGeoJSON.features.length) {
    data.ranch_bounds = cloneDeep(ranchBoundsGeoJSON.features[0].geometry);
    data.ranch_bounds.type = 'Polygon';
  }

  const objectsExisting = {};
  data.objects.forEach((v) => {
    objectsExisting[v.id] = {
      id: v.id,
      name: v.name,
      location: v.location,
      description: v.description,
    };
  });

  const sensorsExisting = {};
  data.sensors.forEach((v) => {
    sensorsExisting[v.id] = {
      id: v.id,
      type: v.type,
      name: v.name,
      location: v.location,
      state: v.state,
    };
  });

  const blocksExisting = {};
  data.blocks.forEach((v) => {
    blocksExisting[v.id] = {
      id: v.id,
      block: v.block,
      name: v.name,
      bounds: v.bounds,
    };
  });

  dataGeoJSON.features
    .forEach((f) => {
      if (f.properties.type === 'sensor') {
        sensorsExisting[f.properties.id] = {
          id: f.properties.id,
          type: f.properties.sensorType,
          name: f.properties.name || '',
          location: f.geometry,
          state: f.properties.state,
        };
      } else if (f.properties.type === 'object') {
        objectsExisting[f.properties.id] = {
          id: f.properties.id,
          name: f.properties.name,
          location: f.geometry,
          description: f.properties.description || '',
        };
      } else if (f.properties.type === 'block') {
        blocksExisting[f.properties.id] = {
          id: f.properties.id,
          block: f.properties.block,
          name: f.properties.name || '',
          bounds: f.geometry,
        };
      }
    });

  data.sensors = Object.keys(sensorsExisting)
    .map((id) => {
      if (isNaN(Number(id))) {
        return {
          type: sensorsExisting[id].type,
          name: sensorsExisting[id].name,
          location: sensorsExisting[id].location,
        };
      }
      return sensorsExisting[id];
    });

  data.objects = Object.keys(objectsExisting)
    .map((id) => {
      if (isNaN(Number(id))) {
        return {
          name: objectsExisting[id].name,
          location: objectsExisting[id].location,
          description: objectsExisting[id].description,
        };
      }
      return objectsExisting[id];
    });

  data.blocks = Object.keys(blocksExisting)
    .map((id) => {
      if (isNaN(Number(id))) {
        return {
          name: blocksExisting[id].name,
          bounds: blocksExisting[id].bounds,
        };
      }
      return {
        id,
        block: blocksExisting[id].block,
        name: blocksExisting[id].name,
        bounds: blocksExisting[id].bounds,
      };
    });

  if (featureToRemove) {
    const { id, type } = featureToRemove.properties;
    switch (type) {
      case 'sensor':
        data.sensors = data.sensors.filter((item) => Number(item.id) !== Number(id));
        break;
      case 'object':
        data.objects = data.objects.filter((item) => Number(item.id) !== Number(id));
        break;
      case 'block':
        data.blocks = data.blocks.filter((item) => Number(item.id) !== Number(id));
        break;
      default:
        logger.error(`Unable to remove item with ${type} and id ${id}`);
    }
  }

  if (featureToUpdate) {
    const { id, type, name } = featureToUpdate.properties;
    switch (type) {
      case 'sensor':
        data.sensors = data.sensors.map((itm) => {
          const item = itm;
          if (Number(item.id) !== Number(id)) {
            return item;
          }
          item.name = name;
          item.type = featureToUpdate.properties.sensorType;
          return item;
        });
        break;
      case 'object':
        data.objects = data.objects.map((itm) => {
          const item = itm;
          if (Number(item.id) !== Number(id)) {
            return item;
          }
          item.name = name;
          item.description = featureToUpdate.properties.description || null;
          return item;
        });
        break;
      case 'block':
        data.blocks = data.blocks.map((itm) => {
          const item = itm;
          if (Number(item.id) !== Number(id)) {
            return item;
          }
          item.name = name;
          return item;
        });
        break;
      default:
        logger.error(`Unable to remove item with ${type} and id ${id}`);
    }
  }

  return data;
};

export const notifyError = (msg, className = 'notification-error') => {
  notification.error({
    message: 'A problem occurred',
    description: msg || 'Unknown Error.',
    className,
  });
};
