import React, { useState, useEffect } from 'react';
import { Button, Form, message } from 'antd';
import { reportsApi } from 'farmx-api';
import moment from 'moment';
import { UserSelect } from './UserSelect';
import { downloadJsonAsCsv } from './jsonToCsv';

export function CustomerReportForm() {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState();

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  function onFinish(values) {
    const { userIds } = values;
    reportsApi.getCustomerReport(userIds).then((response) => {
      downloadJsonAsCsv(
        response.data,
        {
          sessionCount: 'Sessions',
          sessionDurationMean: 'Avg Session Duration',
          sessionDurationMedian: 'Median Session Duration',
          sessionDurationMax: 'Longest Session Duration',
          apiAccessedCount: '# of API calls',
          apiAccessedTime: 'API access time',
          apiFailedCount: 'Failed API count',
        },
        `FarmXCustomerReport_${moment().format('YYYY-MM-DD HH:mm')}.csv`,
      );
    }, () => {
      message.error('Failed to download report.');
    });
  }

  function onUsersChanged(userIds) {
    form.setFieldsValue({ userIds });
  }

  return (
    <Form
      form={form}
      onFinish={onFinish}
      className="page-form"
    >
      <Form.Item
        name="userIds"
        validateTrigger="onFinish"
        rules={[{
          required: true,
          message: 'Please select one or more users',
        }]}
      >
        <UserSelect
          onChange={onUsersChanged}
          placeholder="Select User(s)"
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Download
        </Button>
      </Form.Item>
    </Form>
  );
}
