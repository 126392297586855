/* global L */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Input,
  Select,
  List,
  Collapse,
  Typography,
  Modal,
} from 'antd';
import { useDispatch } from 'react-redux';
import { actions } from 'farmx-redux-core';
import { FaList, FaTable } from 'react-icons/fa';
import cloneDeep from 'lodash/cloneDeep';
import { isMobile } from 'react-device-detect';
import { sensorTitles } from 'farmx-web-ui';
import center from '@turf/center';
import { plansEditSlice, plansEditStore } from './local-redux';
import { options } from './helpers-map';
import { mapDataForPatching, notifyError } from './utils';

const { loadPlanById, patchPlan } = actions;
const {
  setSelectedFeature,
  setFeatureCollection,
} = plansEditSlice.actions;
const { Panel } = Collapse;
const { updateFeature } = plansEditSlice.actions;

export function PlansEditList({
  blockListData,
  sensorListData,
  sensorOkListData,
  sensorErrListData,
  objectListData,
  nestedListData,
  setViewType,
  featureCollectionBufState,
  ranchesGeoJSONState,
  planId,
  selPlan,
}) {
  const dispatch = useDispatch();
  const [listType, setListType] = useState('tables');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(undefined);

  const nestedListOpen = nestedListData.map((_, idx) => `${idx + 1}`);
  return (
    <>
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            height: '60px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              height: '140px',
              marginTop: '10px',
              marginRight: '5px',
            }}
          >
            <Button
              size="large"
              type={listType === 'tables' ? 'primary' : 'secondary'}
              ghost={false}
              icon={<FaTable style={{ marginTop: '5px' }} />}
              onClick={() => {
                if (listType !== 'tables') {
                  setListType('tables');
                }
              }}
            />
            <Button
              size="large"
              type={listType === 'nested' ? 'primary' : 'secondary'}
              ghost={false}
              icon={<FaList style={{ marginTop: '5px' }} />}
              onClick={() => {
                if (listType !== 'nested') {
                  setListType('nested');
                }
              }}
            />
          </div>
        </div>

        <div
          className="plans-edit-list"
          style={{
            width: '100%',
            marginTop: isMobile ? '60px' : '0',
          }}
        >
          <Modal
            title={(modalContent && `${modalContent.properties.type}: ${modalContent.properties.name}`) || 'Edit Item'}
            visible={isModalVisible}
            onOk={() => {
              const feature = cloneDeep(modalContent);
              const data = mapDataForPatching({
                dataGeoJSON: cloneDeep(featureCollectionBufState),
                dataOriginal: selPlan.data.plan,
                ranchBoundsGeoJSON: ranchesGeoJSONState,
                featureToUpdate: feature,
              });

              dispatch(patchPlan(data))
                .then((res) => {
                  if (res.error) {
                    notifyError(res?.error?.message);
                    return;
                  }
                  setIsModalVisible(false);
                  plansEditStore.dispatch(updateFeature(feature));
                  setModalContent(undefined);
                  dispatch(loadPlanById(planId));
                });
            }}
            onCancel={() => {
              setIsModalVisible(false);
              setModalContent(undefined);
            }}
          >
            <div>
              <div>
                <Input
                  style={{ width: '150px' }}
                  value={(modalContent && modalContent.properties.name) || ''}
                  onChange={(e) => {
                    const feature = cloneDeep(modalContent);
                    feature.properties.name = e.target.value;
                    setModalContent(feature);
                  }}
                />
              </div>

              {modalContent && modalContent.properties.type === 'sensor' && (
                <div style={{ marginTop: '10px' }}>
                  <Select
                    style={{ width: 150 }}
                    placeholder="Type of Sensor"
                    value={modalContent.properties.sensorType}
                    onChange={(v) => {
                      const feature = cloneDeep(modalContent);
                      feature.properties.sensorType = v;
                      setModalContent(feature);
                    }}
                  >
                    {options}
                  </Select>
                </div>
              )}

              {modalContent && modalContent.properties.type === 'object' && (
                <div style={{ marginTop: '10px' }}>

                  <Input
                    style={{ width: '150px', marginTop: '10px' }}
                    value={(modalContent && modalContent.properties.description) || ''}
                    onChange={(e) => {
                      const feature = cloneDeep(modalContent);
                      feature.properties.description = e.target.value;
                      setModalContent(feature);
                    }}
                  />
                </div>
              )}

            </div>
          </Modal>

          {listType === 'tables' && (
            <Collapse defaultActiveKey={['1', '2', '3']}>
              <Panel header={<Typography.Text>{`Blocks (${blockListData.length})`}</Typography.Text>} key="1">
                <List
                  size="large"
                  bordered
                  dataSource={blockListData}
                  renderItem={(item) => (
                    <List.Item
                      actions={[
                        <Button
                          style={{ width: '80px' }}
                          type="primary"
                          key={JSON.stringify(item)}
                          onClick={() => {
                            setIsModalVisible(true);
                            setModalContent(cloneDeep(item));
                          }}
                        >
                          Edit
                        </Button>,
                      ]}
                    >
                      <div className="flex-grid">
                        <div className="col" style={{ width: '200px' }}>
                          {item.properties.name}
                        </div>
                        <div className="col" style={{ width: '200px' }}>
                          {`${item.properties.acres} acres`}
                        </div>
                      </div>
                    </List.Item>
                  )}
                />
              </Panel>
              <Panel header={<Typography.Text>{`Sensors (${sensorListData.length})`}</Typography.Text>} key="2">
                {!sensorErrListData.length && (
                  <List
                    size="large"
                    bordered
                    dataSource={sensorListData}
                    renderItem={(item) => (
                      <List.Item
                        actions={[
                          <Button
                            type="primary"
                            style={{ width: '80px' }}
                            key={JSON.stringify(item)}
                            onClick={() => {
                              setIsModalVisible(true);
                              setModalContent(cloneDeep(item));
                            }}
                          >
                            Edit
                          </Button>,
                        ]}
                      >
                        <div className="flex-grid">
                          <div className="col" style={{ width: '200px' }}>
                            {item.properties.name}
                          </div>
                          <div className="col" style={{ width: 250 }}>
                            {sensorTitles[item.properties.sensorType].singular}
                          </div>
                          <div className="col" style={{ width: '200px' }}>
                            {item.properties.block ? item.properties.block.name : 'No Block'}
                          </div>
                        </div>
                      </List.Item>
                    )}
                  />
                )}
                {!!sensorErrListData.length && (
                  <>
                    <Collapse defaultActiveKey={['1']}>
                      <Panel header={<Typography.Text>{`Sensors erroneous (${sensorErrListData.length})`}</Typography.Text>} key="1">
                        <List
                          size="large"
                          bordered
                          dataSource={sensorErrListData}
                          renderItem={(item) => (
                            <List.Item
                              actions={[
                                <Button
                                  style={{ width: '80px' }}
                                  danger
                                  type="primary"
                                  key={JSON.stringify(item)}
                                  onClick={() => {
                                    const {
                                      ranchesGeoJSON,
                                      expandedGeoJSON,
                                      featureCollection,
                                    } = plansEditStore.getState();

                                    const fc = ranchesGeoJSON || expandedGeoJSON;
                                    let centerPoint;
                                    if (fc) {
                                      centerPoint = center(fc);
                                    } else {
                                      centerPoint = {
                                        geometry: {
                                          coordinates: [-121.016, 37.646],
                                          type: 'Point',
                                        },
                                      };
                                    }

                                    const marker = cloneDeep(item);
                                    marker.geometry = centerPoint.geometry;
                                    marker.properties.location = centerPoint.geometry;
                                    const fColl = cloneDeep(featureCollection);
                                    fColl.features.push(marker);
                                    let selFeat;
                                    (new L.GeoJSON(marker)).eachLayer((layer) => {
                                      selFeat = layer;
                                    });
                                    plansEditStore.dispatch(setSelectedFeature(selFeat));
                                    plansEditStore.dispatch(setFeatureCollection(fColl));
                                    setViewType('map');
                                  }}
                                >
                                  Set location
                                </Button>,
                              ]}
                            >
                              <div className="flex-grid">
                                <div className="col" style={{ width: '200px' }}>
                                  {item.properties.name}
                                </div>
                                <div className="col" style={{ width: 250 }}>
                                  {sensorTitles[item.properties.sensorType].singular}
                                </div>
                                <div className="col" style={{ width: '200px' }}>
                                  {item.properties.block ? item.properties.block.name : 'No Block'}
                                </div>
                              </div>
                            </List.Item>
                          )}
                        />
                      </Panel>
                    </Collapse>
                    <Collapse defaultActiveKey={['1']}>
                      <Panel header={<Typography.Text>{`Sensors (${sensorOkListData.length})`}</Typography.Text>} key="1">
                        <List
                          size="large"
                          bordered
                          dataSource={sensorOkListData}
                          renderItem={(item) => (
                            <List.Item
                              actions={[
                                <Button
                                  style={{ width: '80px' }}
                                  type="primary"
                                  key={JSON.stringify(item)}
                                  onClick={() => {
                                    setIsModalVisible(true);
                                    setModalContent(cloneDeep(item));
                                  }}
                                >
                                  Edit
                                </Button>,
                              ]}
                            >
                              <div className="flex-grid">
                                <div className="col" style={{ width: '200px' }}>
                                  {item.properties.name}
                                </div>
                                <div className="col" style={{ width: 250 }}>
                                  {sensorTitles[item.properties.sensorType].singular}
                                </div>
                                <div className="col" style={{ width: '200px' }}>
                                  {item.properties.block ? item.properties.block.name : 'No Block'}
                                </div>
                              </div>
                            </List.Item>
                          )}
                        />
                      </Panel>
                    </Collapse>
                  </>
                )}

              </Panel>
              <Panel header={<Typography.Text>{`Objects (${objectListData.length})`}</Typography.Text>} key="3">
                <List
                  size="large"
                  bordered
                  dataSource={objectListData}
                  renderItem={(item) => (
                    <List.Item
                      actions={[
                        <Button
                          style={{ width: '80px' }}
                          type="primary"
                          key={JSON.stringify(item)}
                          onClick={() => {
                            setIsModalVisible(true);
                            setModalContent(cloneDeep(item));
                          }}
                        >
                          Edit
                        </Button>,
                      ]}
                    >
                      <div className="flex-grid">
                        <div className="col" style={{ width: '200px' }}>
                          {item.properties.name}
                        </div>
                        <div className="col" style={{ width: '200px' }}>
                          {item.properties.block ? item.properties.block.name : '-'}
                        </div>
                      </div>
                    </List.Item>
                  )}
                />
              </Panel>
            </Collapse>

          )}

          {listType === 'nested' && (
            <Collapse
              defaultActiveKey={nestedListOpen}
              key={JSON.stringify(nestedListOpen)}
            >
              {nestedListData.map((d, idx) => (
                <Panel
                  header={(
                    <>
                      {d.properties.noBlock && <Typography.Text>No Block</Typography.Text>}
                      {!d.properties.noBlock && (
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'baseline',
                            justifyContent: 'space-between',
                          }}
                        >
                          {d.properties.name}
                          <div>
                            {`${d.properties.acres} acres`}
                          </div>

                        </div>
                      )}
                    </>
                  )}
                  key={`${idx + 1}`}
                >
                  <List
                    size="large"
                    bordered
                    dataSource={d.properties.markers}
                    renderItem={(item) => (
                      <List.Item
                        actions={[
                          <Button
                            style={{ width: '80px' }}
                            type="primary"
                            key={JSON.stringify(item)}
                            onClick={() => {
                              setIsModalVisible(true);
                              setModalContent(cloneDeep(item));
                            }}
                          >
                            Edit
                          </Button>,
                        ]}
                      >
                        <div className="flex-grid">
                          <div className="col" style={{ minWidth: '200px' }}>
                            {item.properties.name}
                          </div>

                          {item.properties.type === 'sensor' && (
                            <div className="col" style={{ width: '200px' }}>
                              {sensorTitles[item.properties.sensorType].singular}
                            </div>
                          )}

                        </div>
                      </List.Item>
                    )}
                  />
                </Panel>
              ))}
            </Collapse>
          )}
        </div>
      </div>
    </>
  );
}

PlansEditList.propTypes = {
  blockListData: PropTypes.arrayOf(PropTypes.object).isRequired,
  sensorListData: PropTypes.arrayOf(PropTypes.object).isRequired,
  sensorOkListData: PropTypes.arrayOf(PropTypes.object).isRequired,
  sensorErrListData: PropTypes.arrayOf(PropTypes.object).isRequired,
  objectListData: PropTypes.arrayOf(PropTypes.object).isRequired,
  nestedListData: PropTypes.arrayOf(PropTypes.object).isRequired,
  setViewType: PropTypes.func.isRequired,
  featureCollectionBufState: PropTypes.shape({
    type: PropTypes.string,
    features: PropTypes.array,
  }).isRequired,
  ranchesGeoJSONState: PropTypes.shape({
    type: PropTypes.string,
    features: PropTypes.array,
  }).isRequired,
  planId: PropTypes.number.isRequired,
  selPlan: PropTypes.shape({
    data: PropTypes.object,
  }).isRequired,
};
