import React from 'react';
import PropTypes from 'prop-types';
import {
  PageHeader,
  Layout,
  Button,
  Form,
  Select,
  message,
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import './sensorForm.css';

import { connect } from 'react-redux';
import { actions, selectors } from 'farmx-redux-core';
import { SensorSelect } from 'farmx-web-ui';

const {
  selectAllSensors,
} = selectors;

export class SensorRemovePage extends React.Component {
  formRef = React.createRef();

  onFinish = async (values) => {
    const { removeSensor } = this.props;
    const { newState, sensor } = values;
    const { type, identifier } = sensor;
    const success = await removeSensor({
      sensorType: type,
      sensorIdentifier: identifier,
      newState,
    });
    // Check if async action is fulfilled
    if (success.type.split('/').at(-1) === 'fulfilled') {
      message.success('Sensor removed');
      this.formRef.current.resetFields();
    } else {
      message.error('Sensor remove failed');
    }
  };

  render() {
    const {
      sensors,
      loadSensors,
      history,
      location: {
        state: {
          showBack,
          sensor,
        } = { showBack: false, sensor: null },
      },
    } = this.props;

    return (
      <Layout>
        <PageHeader
          title="Remove Sensor"
          onBack={() => history.goBack()}
          backIcon={
            showBack
              ? <ArrowLeftOutlined /> : false
          }
        />
        <div className="page-body">
          <Form ref={this.formRef} className="page-form" onFinish={this.onFinish} initialValues={{ sensor }}>
            <Form.Item
              label="Choose Sensor"
              name="sensor"
              rules={[
                {
                  required: true,
                  message: 'Please select sensor!',
                },
                () => ({
                  validator(rule, value) {
                    if (
                      value === undefined
                      || value.type === undefined
                      || value.identifier === undefined
                    ) {
                      return new Error('Please select sensor!');
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <SensorSelect
                sensorType={sensor ? sensor.type : undefined}
                sensorIdentifier={sensor ? sensor.identifier : undefined}
                items={sensors}
                loadItems={loadSensors}
                useAllFarmData
              />
            </Form.Item>
            <Form.Item
              label="Choose removed/withdrawn"
              name="newState"
              rules={[{ required: true, message: 'Please select removed/withdrawn!' }]}
            >
              <Select placeholder="Select new state">
                <Select.Option value="withdrawn">Withdrawn (seasonal)</Select.Option>
                <Select.Option value="removed">Removed</Select.Option>
                <Select.Option value="maintenance">Maintenance</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">Confirm</Button>
            </Form.Item>
          </Form>
        </div>
      </Layout>
    );
  }
}

SensorRemovePage.propTypes = {
  sensors: PropTypes.arrayOf(
    PropTypes.shape({
      identifier: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  loadSensors: PropTypes.func.isRequired,
  removeSensor: PropTypes.func.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({}),
  }),
};

SensorRemovePage.defaultProps = {
  sensors: [],
  history: {
    goBack: () => {},
  },
  location: {
    state: {
      showBack: false,
      sensor: null,
    },
  },
};

const mapStateToProps = (state) => {
  const sensors = selectAllSensors(state);
  return { sensors };
};

const mapDispatchToProps = {
  loadSensors: actions.loadAllSensors,
  removeSensor: actions.removeSensor,
};

export default connect(mapStateToProps, mapDispatchToProps)(SensorRemovePage);
