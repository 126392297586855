import React from 'react';
import { Breadcrumb } from 'antd';
import { helpers } from 'farmx-api';
import { hooks } from 'farmx-redux-core';

const { useSensor } = hooks;
const { getBreadcrumbTitle } = helpers;

function SensorBreadcrumbItem({ sensor }) {
  const sensorLoaded = useSensor(sensor);
  if (!sensorLoaded) return null;
  return (
    <Breadcrumb.Item>{getBreadcrumbTitle(sensorLoaded.type, sensorLoaded)}</Breadcrumb.Item>
  );
}

// eslint-disable-next-line no-underscore-dangle
SensorBreadcrumbItem.__ANT_BREADCRUMB_ITEM = true;

export default SensorBreadcrumbItem;
