import moment from 'moment';

const ERROR_COLOR = '#E23549';
const WARNING_COLOR = '#FFCC00';
const OK_COLOR = '#3DC873';

const ERROR_STYLE = {
  backgroundColor: ERROR_COLOR,
  color: 'white',
};

const WARNING_STYLE = {
  backgroundColor: WARNING_COLOR,
  color: 'black',
};

const OK_STYLE = {
  backgroundColor: OK_COLOR,
};

const DEFAULT_STYLE = {};

const secondaryErrorStyle = {
  borderBottomColor: 'red',
  borderBottomWidth: '3px',
};

const secondaryWarningStyle = {
  borderBottomColor: 'orange',
  borderBottomWidth: '3px',
};

export const sensorStatsCellStyle = (params) => {
  const status = params.value;
  const hasPlan = params.data.has_plan;
  const sensorType = params.colDef.field.replace('sensor_stats_', '');
  const sensorTypePlanned = sensorType === 'cavalier' ? sensorType : `${sensorType}_sensor`;
  const plannedCount = hasPlan ? params.data[`planned_${sensorTypePlanned}_count`] : 0;

  if (!status || (!status.count && !plannedCount)) return null;

  let backgroundStyle = OK_STYLE;
  if (!status.count) backgroundStyle = DEFAULT_STYLE;
  else if (status.online === 0) backgroundStyle = ERROR_STYLE;
  else if (status.online < status.count) backgroundStyle = WARNING_STYLE;

  if (status.count < plannedCount) {
    backgroundStyle = {
      ...backgroundStyle,
      ...secondaryErrorStyle,
    };
  } else if (status.count > plannedCount) {
    backgroundStyle = {
      ...backgroundStyle,
      ...secondaryWarningStyle,
    };
  }
  return backgroundStyle;
};

export const sensorStatsCellClass = (params) => {
  const status = params.value;
  const hasPlan = params.data.has_plan;
  const sensorType = params.colDef.field.replace('sensor_stats_', '');
  const sensorTypePlanned = sensorType === 'cavalier' ? sensorType : `${sensorType}_sensor`;
  const plannedCount = hasPlan ? params.data[`planned_${sensorTypePlanned}_count`] : 0;

  if (!status || (!status.count && !plannedCount)) return null;
  if (!status.count) return null;
  return 'status-cell';
};

export const pingCellStyle = (params) => {
  const pingValue = params.data.ping;
  if (pingValue === 0) {
    return ERROR_STYLE;
  } if (pingValue === 1) {
    return WARNING_STYLE;
  } if (pingValue === 2) {
    return OK_STYLE;
  }

  return DEFAULT_STYLE;
};

export const dataDateCellStyle = (params) => {
  let cutoffMin = 30;
  if (params.colDef.field === 'weather_date') {
    cutoffMin = 45;
  }
  if (params.value === undefined) return null;
  const minAgo = moment.duration(moment().diff(moment(params.value))).asMinutes();
  if (minAgo > 120) {
    return ERROR_STYLE;
  } if (minAgo > cutoffMin) {
    return WARNING_STYLE;
  } if (minAgo <= cutoffMin) {
    return OK_STYLE;
  }
  return null;
};

export const statusBoolCellStyle = (params) => {
  if (params.value === undefined) return;
  const value = !params.value;
  if (value) {
    return ERROR_STYLE;
  }
  return OK_STYLE;
};

export const statusCountCellStyle = (params) => {
  if (params.value === undefined) return;
  const value = !params.value;
  if (value) {
    return OK_STYLE;
  }
  return ERROR_STYLE;
};

export const numProvisionedCellStyle = (params) => {
  if (params.data.num_provisioned === undefined) return null;
  const value = params.data.num_sensors - params.data.num_provisioned;
  if (value <= 0) {
    return OK_STYLE;
  }
  return ERROR_STYLE;
};
