import React, {
  useEffect, useState, useRef, useMemo,
  useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { ChartComponent } from 'farmx-web-ui';
import Moment from 'moment';
import MomentComponent from 'react-moment';
import { sensorApi, helpers, userApi } from 'farmx-api';
import { actions, selectors } from 'farmx-redux-core';
import Modal from 'antd/lib/modal/Modal';
import { Typography } from 'antd';
import { isEmpty } from 'lodash';
import { buildConfig, buildConfigV1, getName } from './helper';
import gatewayRssiData from './__mock__/gatewayRssi'; // mock data for testing


const { fieldToHeader } = helpers;
const { loadSensorData, getSensorNotes } = sensorApi;
const { loadSensorDetail } = actions;
const { selectSensor } = selectors;

export default function SensorDataChart(props) {
  const {
    sensors,
    variables,
    startDate,
    endDate,
    mergeAxes,
    compact,
    chartCallback,
  } = props;

  // const { type, identifier } = sensorProps;
  const [sensorDetailsLoaded, setSensorDetailsLoaded] = useState([]);
  const [sensorDataLoaded, setSensorDataLoaded] = useState({});
  // const sensor = useSelector((state) => selectSensor(state, type, identifier));
  const sensorDetails = useSelector((state) => sensors.map(
    (d) => selectSensor(state, d.type, d.identifier),
  ));
  const dispatch = useDispatch();
  // const { id: sensorId } = sensor || {};
  const chartRef = useRef(React.createRef());
  const [data, setData] = useState(null);
  const [newData, setNewData] = useState(null); // To store data from new api version
  const [sensorNotes, setSensorNotes] = useState([]);
  const [selectedSensorNote, setSelectedSensorNote] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [users, setUsers] = useState();
  const [config, setConfig] = useState();
  const [dateRange, setDateRange] = useState([]);

  const validSensorsCount = sensors.filter((sensor) => sensor?.type && sensor?.identifier)?.length;
  const totalVariableCount = variables.reduce((acc, p) => acc + p?.length, 0);

  // This use effect will handle the edge case of deleting
  // all variables in ensor capabilties selection row
  useEffect(() => {
    const existingVariablesCount = Object.values(sensorDataLoaded)
    ?.reduce((acc, p) => acc + p?.length, 0);
    if (existingVariablesCount > totalVariableCount) {
      const noVariablesSensor = variables.reduce((acc, p, index) => {
        if (!p.length) {
          acc.sensor = sensorDetails[index];
        }
        return acc;
      }, { sensor: null });
      // TODO remove after testing
      // console.log('variables changed!!', noVariablesSensor);
      if (noVariablesSensor.sensor) {
        setSensorDataLoaded((prev) => ({ ...prev, [noVariablesSensor.sensor.identifier]: [] }));
        setData((prev) => {
          if (prev) {
            const newState = { ...prev };
            if (newState[getName(noVariablesSensor.sensor)]) {
              newState[
                getName(noVariablesSensor.sensor)] = {};
            }
            return newState;
          }
          return prev;
        });
        setNewData((prev) => {
          if (prev) {
            const newState = { ...prev };
            if (newState[getName(noVariablesSensor.sensor)]) {
              newState[
                getName(noVariablesSensor.sensor)] = {};
            }
            return newState;
          }
          return prev;
        });
      }
    }
  }, [sensorDataLoaded, sensorDetails, totalVariableCount, variables]);

  useEffect(() => {
    if (Object.keys(sensorDataLoaded)?.length > validSensorsCount) {
      setSensorDataLoaded((prev) => {
        // console.log('sensors data loaded', prev);
        const validSelectedSensors = sensors.filter((sensor) => sensor?.type && sensor?.identifier);
        const identifiers = validSelectedSensors.map((sensor) => sensor.identifier);
        const keys = Object.keys(prev);
        const newState = { ...prev };
        keys.forEach((key) => {
          if (!identifiers.includes(key)) {
            delete newState[key];
          }
        });
        // TODO remove after testing
        // console.log('newState', newState);
        return newState;
      });
    }
  }, [sensorDataLoaded, sensors, validSensorsCount]);

  // TODO remove after testing
  // useEffect(() => {
  //   console.log('sensorDetails', sensorDetails, sensors);
  //   console.log('config', config);
  //   console.log('data', data);
  // }, [config, data, sensorDetails, sensors]);


  const validInput = !!(
    sensors
    && sensors.length
    // && sensorId
    // && type
    && variables
    && variables.length
    && startDate
    && endDate
  );

  const areVariablesSame = useCallback((list1, list2) => (list1.length === list2.length
      && list1.reduce((acc, p) => acc && list2.includes(p), true)
  ), []);

  const areDatesSame = useCallback(
    (start, end) => Moment(start).isSame(dateRange[0])
      && Moment(end).isSame(dateRange[1]),
    [dateRange],
  );

  // A function to check if new api call is to be used
  const newApiCallNeeded = useCallback((
    type,
    capabilities,
  ) => {
    if (type !== 'cavalier') return false;
    const gatewayVarList = ['gateway_rssi', 'gateway_snr'];
    const containsRssiOrSnr = capabilities && capabilities.reduce(
      (acc, variable) => (acc || gatewayVarList.includes(variable)), false,
    );
    return containsRssiOrSnr;
  }, []);

  // load initial data
  useEffect(() => {
    if (sensors.length) {
      sensors.forEach((d) => {
        if (!sensorDetailsLoaded.includes(d.identifier)) {
          dispatch(loadSensorDetail({ type: d.type, identifier: d.identifier }));
          setSensorDetailsLoaded((prev) => [...prev, d.identifier]);
        }
      });
    }
  }, [dispatch, sensors, sensorDetailsLoaded]);

  // function to check if data and sensors list mismatch
  const isSensorRemoved = useCallback(() => {
    // get keys of old api data
    const keysOld = data ? Object.keys(data) : [];
    // get keys of new api data
    const keysNew = newData ? Object.keys(newData) : [];
    // get names of sensors
    const names = sensorDetails.map((sensor) => getName(sensor));
    // if (keys.length > names) return true;
    return [(keysOld.length + keysNew.length) > names.length,
      keysOld, keysNew, names,
    ];
  }, [sensorDetails, data, newData]);

  // handle removal of selected sensor
  // and so its data from state
  useEffect(() => {
    const [sensorRemoved, keysOld, keysNew, names] = isSensorRemoved();
    if (sensorRemoved) {
      setData((prev) => {
        let dataObject;
        if (prev) {
          dataObject = { ...prev };
          keysOld.forEach((key) => {
            // TODO remove after testing
            // console.log('key', key, names.includes(key));
            if (!names.includes(key)) {
              delete dataObject[key];
            }
          });
        }
        // TODO remove after testing
        // console.log('setting data', dataObject, prev);
        return dataObject || prev;
      });
      setNewData((prev) => {
        let dataObject;
        if (prev) {
          dataObject = { ...prev };
          keysNew.forEach((key) => {
            // TODO remove after testing
            // console.log('key', key, names.includes(key));
            if (!names.includes(key)) {
              delete dataObject[key];
            }
          });
        }
        // TODO remove after testing
        // console.log('setting data', dataObject, prev);
        return dataObject || prev;
      });
    }
  }, [isSensorRemoved]);

  // update data when config changes
  useEffect(() => {
    function refreshData() {
      const chartObj = chartRef.current.highchartsComponent.current.chart;
      if (!validInput) {
        // TODO remove after testing
        // console.log('Invalid input');
        if (chartObj) chartObj.hideLoading();
        if (data) setData(null);
        if (newData) setNewData(null);
        if (config) setConfig(null);
        if (!isEmpty(sensorDataLoaded)) setSensorDataLoaded({});
        return;
      }

      if (sensors.length && variables.length) {
        sensorDetails.forEach((sensor, i) => {
          if (sensor && variables[i]?.length
            && (!sensorDataLoaded[sensor.identifier]
            || !areVariablesSame(sensorDataLoaded[sensor.identifier], variables[i])
            || (dateRange.length === 2
                && !areDatesSame(startDate, endDate)))) {
            // Call API if
            // input sensor and variables exists
            // AND (sensor graph data is not already loaded
            // OR there are changes in variables
            // OR date range is chaged)
            if (chartObj) chartObj.showLoading();
            if (newApiCallNeeded(sensor.type, variables[i])) {
              // call new version of API
              loadSensorData(sensor.type, sensor.id, variables[i], startDate, endDate, undefined, 1)
                .then((response) => {
                  if (response && response.status === 200) {
                    setNewData((prev) =>
                      // TODO remove after testing
                      // console.log('RSSI/SNR data', response);
                      ({ ...prev, [getName(sensor)]: response.data.graph_data }));
                  }
                  if (chartObj) chartObj.hideLoading();
                }).catch((e) => {
                  console.log('Error while calling API...', e);
                });
            } else {
              // Use this till this will become obsolete
              loadSensorData(sensor.type, sensor.id, variables[i], startDate, endDate).then((response) => {
                if (response && response.status === 200) {
                  setData((prev) => ({ ...prev, [getName(sensor)]: response.data }));
                }
                if (chartObj) chartObj.hideLoading();
              });
            }
            setSensorDataLoaded((prev) => ({ ...prev, [sensor.identifier]: variables[i] }));
            setDateRange([Moment(startDate), Moment(endDate)]);
          }
        });
      }
    }
    refreshData();
  }, [areDatesSame, areVariablesSame, config, data, newData, dateRange.length,
    endDate, newApiCallNeeded, sensorDataLoaded, sensorDetails, sensors.length,
    startDate, validInput, variables]);

  // Config building inside useEffect
  // and storing config in local state
  useEffect(() => {
    if (data || newData) {
      // TODO remove after testing
      console.log('data', data, newData);
      // for old api data
      const data1 = data ? Object.keys(data).reduce((acc, p) => {
        Object.keys(data[p]).forEach((key) => {
          acc[`${p}_${key}`] = data[p][key];
        });
        return acc;
      }, {}) : null;
      // for new api data
      const data2 = newData ? Object.keys(newData).reduce((acc, p) => {
        Object.keys(newData[p]).forEach((key) => {
          acc[`${p}_${key}`] = newData[p][key];
        });
        return acc;
      }, {}) : null;
      // TODO remove after testing
      console.log('data1', data1);
      console.log('data2', data2);
      let configForOldApiData;
      let configForNewApiData;
      const yAxis = [];
      const series = [];
      if (data1) {
        configForOldApiData = buildConfig(data1, mergeAxes, compact);
        if (configForOldApiData?.yAxis?.length) {
          yAxis.push(...configForOldApiData?.yAxis);
        }
        if (configForOldApiData?.series?.length) {
          series.push(...configForOldApiData?.series);
        }
      }
      if (data2) {
        configForNewApiData = buildConfigV1(data2, mergeAxes, compact);
        if (configForNewApiData?.yAxis?.length) {
          yAxis.push(...configForNewApiData?.yAxis);
        }
        if (configForNewApiData?.series?.length) {
          series.push(...configForNewApiData?.series);
        }
      }
      setConfig({
        yAxis,
        series,
      });
      // TODO remove after testing
      console.log('conf', yAxis, series);
    }
  }, [compact, data, mergeAxes, newData]);

  /** ********************************************************************
   *  This will keep all the colors of lines same for new version of api
   *  Enable this if needed in future
   *  Can restrict this on specific series name
   ************************************************************************ */
  // useEffect(() => {
  //   let color;
  //   const { series } = chartRef?.current?.highchartsComponent?.current?.chart;
  //   if (newData) {
  //     if (series && series.length) {
  //       color = series[series.length - 1].color;
  //     }
  //     series.forEach(
  //       (series, i) => {
  //         if (i !== (series.length - 1)) {
  //           console.log(`updating series...${series.name}`, color);
  //           series.update({ color });
  //         }
  //       },
  //     );
  //   }
  // }, [newData, chartRef?.current?.highchartsComponent?.current?.chart?.series?.length]);


  const xAxisLabels = useMemo(() => (
    compact
      ? {
        y: 10,
        x: 1,
        align: 'left',
      } : {}
  ), [compact]);

  // TODO remove after testing
  // console.log(`sensorNotes ${sensors[0]?.name}`, sensorNotes);

  const onPlotlineLabelClick = useCallback((index) => {
    setIsModalOpen(true);
    setSelectedSensorNote(sensorNotes[index] || {});
  }, [sensorNotes]);

  const renderUser = useCallback((userId) => {
    if (users) {
      const userData = users.find((user) => user.id === userId);
      if (userData) {
        return `${userData.first_name} ${userData.last_name}`;
      }
    }
    return null;
  });

  useEffect(() => {
    window.onPlotlineLabelClick = onPlotlineLabelClick;
  }, [onPlotlineLabelClick]);

  const xAxis = useMemo(() => {
    const xAxisObj = {
      type: 'datetime',
      ordinal: false,
      gridLineWidth: 1,
      labels: xAxisLabels,
      alternateGridColor: 'rgba(200,200,200,0.1)',
    };
    if (sensorNotes && sensorNotes.length) {
      xAxisObj.plotLines = sensorNotes.map((sensorNote, index) => {
        const formattedDate = new Moment(sensorNote.date).format('Do MMMM YYYY');
        return {
          color: 'orange', // Color value
          value: new Date(sensorNote.date).getTime(), // Value of where the line will appear
          width: 2, // Width of the line
          label: {
            y: sensorNote.y, // Y position for label, required to avoid label overlapping.
            enabled: true,
            useHTML: true,
            formatter: () => `
              <div class="sensor-note-plotline-label" onclick="onPlotlineLabelClick('${index}')">
                <span class="sensor-note-plotline-label-title">${sensorNote.action}</span>
                <div class="tooltip">
                  <div class="tooltip-inner">
                    <div class="tooltip-action">
                      ${renderUser(sensorNote.user)}
                    </div>
                    <div class="tooltip-text">
                      ${formattedDate}
                    </div>
                  </div>
                </div>
              </div>`,
            style: {
              transform: 'rotate(0deg)',
            },
          },
        };
      });
    }
    return xAxisObj;
  }, [renderUser, sensorNotes, xAxisLabels]);

  const spacing = compact
    ? [1, 1, 1, 1] : [10, 10, 15, 10];

  /**
   * Get sensor notes
   */
  useEffect(() => {
    const fetchNotes = async (type, identifier) => {
      try {
        const response = await getSensorNotes({
          type,
          identifier,
          dateStart: startDate,
          dateEnd: endDate,
        });
        if (response && response.status === 200 && response.data) {
          const { results } = response.data;
          const datesObj = {};
          /**
           * If there are multiple notes on same day, we need to provide a y value.
           * It ensures that the labels don't overlap.
           */
          setSensorNotes((results || []).map((r) => {
            const d = new Moment(r.date).format('MMM Do YY');
            if (datesObj[d]) {
              datesObj[d] += 1;
            } else {
              datesObj[d] = 1;
            }
            return {
              ...r,
              y: datesObj[d] * 30,
            };
          }));
        }
      } catch {
        setSensorNotes([]);
      }
    };

    sensors.forEach((sensor) => {
      fetchNotes(sensor.type, sensor.identifier);
    });
  }, [endDate, sensors, startDate]);

  // fetch users
  useEffect(() => {
    userApi.getUsers().then((response) => {
      if (response && response.data) {
        setUsers(response.data);
      }
    });
  }, []);

  useEffect(() => {
    // TODO remove after testing
    // console.log('chartRef changed');
    // if (config?.series) console.log('chartRef.current.series...', config?.series);
    if (config?.series) {
      chartCallback(
        null,
        chartRef?.current?.highchartsComponent?.current?.chart,
        sensorDetails,
      );
      // this is temporary workaround. Needs refactoring of graph page for admin-web
    }
    // purposefully disabling for chartCallback changes not to be reflected
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config,
    chartRef?.current?.highchartsComponent?.current?.chart,
  ]);


  return (
    <>
      <ChartComponent
        ref={chartRef}
        options={{
          legend: {
            enabled: !compact,
          },
          chart: {
            zoomType: 'xy',
            spacing,
          },
          xAxis,
          series: config?.series || [],
          yAxis: config?.yAxis || [],
          ...config,
        }}
        callback={chartCallback}
      />
      <Modal
        className="sensor-note-modal"
        closable
        title={selectedSensorNote.action}
        visible={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <p>{selectedSensorNote.text}</p>
        <div className="sensor-note-user-and-date">
          <div>
            <Typography.Text type="secondary">
              {renderUser(selectedSensorNote.user)}
            </Typography.Text>
          </div>
          <div>
            <Typography.Text type="secondary">
              {selectedSensorNote.date && (
                <MomentComponent format="Do MMMM YYYY, h:mma">{selectedSensorNote.date}</MomentComponent>
              )}
            </Typography.Text>
          </div>
        </div>
      </Modal>
    </>
  );
}

SensorDataChart.propTypes = {
  compact: PropTypes.bool,
  variables: PropTypes.arrayOf(PropTypes.string),
  startDate: PropTypes.instanceOf(Moment),
  endDate: PropTypes.instanceOf(Moment),
  sensor: PropTypes.shape({
    type: PropTypes.string,
    identifier: PropTypes.string,
    id: PropTypes.number,
  }),
  sensors: PropTypes.arrayOf({
    type: PropTypes.string,
    identifier: PropTypes.string,
    id: PropTypes.number,
  }),
  mergeAxes: PropTypes.bool,
  chartCallback: PropTypes.func,
};

SensorDataChart.defaultProps = {
  compact: false,
  variables: [],
  startDate: null,
  endDate: null,
  sensor: null,
  sensors: [],
  mergeAxes: false,
  chartCallback: () => { },
};
