import React, {
  useCallback, useEffect, useState, useRef,
} from 'react';
import { Button } from 'antd';
import { SensorSelect } from 'farmx-web-ui';
import { DeleteFilled, PlusOutlined } from '@ant-design/icons';
import { SensorCapabilitySelect } from '../sensor';
import { SelectSensorCapabilitiesProps, Sensor } from './type';

const SelectSensorAndCapabilities = (
  props: SelectSensorCapabilitiesProps,
) => {
  const {
    index,
    onSensorSelectChange,
    onCapabilitiesChange,
    onAdd,
    onDelete,
    colors,
    selectedFilters,
    sensor: sensorFromProps,
    capabilities: capabilitiesFromProps,
  } = props;
  const prevSensor = useRef<Sensor | undefined>(undefined);
  const [sensor, setSensor] = useState <Sensor | undefined>(sensorFromProps || undefined);
  const [capabilities, setCapabilities] = useState(capabilitiesFromProps || []);

  const onClear = useCallback(() => {
    onDelete(index);
  }, [index, onDelete]);

  const onClickSensorSelect = useCallback((value) => {
    if (value?.identifier && value?.type) {
      setSensor(() => value);
      onSensorSelectChange(index, value);
      // Store the selected sensor in ref variable
      if (!prevSensor.current) prevSensor.current = value;

      // Compare with current selection and clear the selected capabilities if type is not same
      if (prevSensor.current && prevSensor.current?.type !== value?.type) {
        prevSensor.current = value;
        setCapabilities([]);
        onCapabilitiesChange(index);
      }
    } else {
      setSensor(() => undefined);
      onSensorSelectChange(index);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, onSensorSelectChange, setSensor]);

  // TODO remove after testing
  // console.log('selected sensor ', index, sensor);
  // index, sensor, capabilities, capabilitiesFromProps);

  const onClickCapabilitiesSelect = useCallback((value) => {
    if (value?.length) {
      setCapabilities(value);
      onCapabilitiesChange(index, value);
    } else {
      setCapabilities([]);
      onCapabilitiesChange(index);
    }
  }, [index, onCapabilitiesChange]);

  // remove if not useful
  // const filterOption = useCallback(
  //   (input: any, option: any) => option?.label?.indexOf(input) >= 0, [],
  // );

  useEffect(() => {
    setSensor(sensorFromProps);
  }, [sensorFromProps]);

  useEffect(() => {
    setCapabilities(capabilitiesFromProps);
  }, [capabilitiesFromProps]);

  return (
    <div className="sensor-capabilities" id={`sc-row-${index}}`}>
      <SensorSelect
        style={{ flex: '0 0 350px' }}
        sensorType={sensor?.type ? sensor.type : ''}
        sensorIdentifier={sensor?.identifier ? sensor.identifier : ''}
        sensorId={sensor?.id}
        onChange={onClickSensorSelect}
        // onChangeFilters={(d) => setSelectedFilters(d)}
        selectedFilters={selectedFilters}
        useAllFarmData
      />
      <SensorCapabilitySelect
        defaultValue={capabilities}
        value={capabilities}
        sensor={sensor}
        style={{ flex: '1 0 150px' }}
        onChange={onClickCapabilitiesSelect}
        colors={sensor?.identifier
          ? colors[sensor?.identifier]?.map((d: object) => Object.values(d)[0]) : []}
      />
      {index === 0 ? (
        <Button
          type="ghost"
          onClick={onAdd}
        >
          <PlusOutlined />

        </Button>
      ) : (
        <div
          className="graph-page-sensor-select-clear"
          role="button"
          onClick={onClear}
          tabIndex={0}
          onKeyDown={onClear}
        >
          <DeleteFilled style={{ fontSize: '1.2rem' }} />
        </div>
      )}
    </div>
  );
};

export default SelectSensorAndCapabilities;
