import React from 'react';
import './mapView.css';
import { Button, Dropdown } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { Map, prepareZeroIslandSensors, MapSettingsMenu } from 'farmx-web-ui';
// import { MapSettingsMenu } from './MapSettingsMenu';

const isEqual = require('react-fast-compare');

export default class MapView extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showLabels: true };
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.data, this.props.data)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ data: this.props.data });
    }

    if (!isEqual(prevProps.selected, this.props.selected)) {
      const sensorInfo = this.getSensorData(this.props.selected);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ popupInfo: sensorInfo });
    }
  }

  restructureToGeoJSON = (data) => {
    if (!data) {
      // eslint-disable-next-line no-param-reassign
      data = [];
    }
    const sensorsGeoJSON = {
      type: 'FeatureCollection',
      features: data.map((sensor) => ({
        id: sensor.identifier,
        geometry: sensor.geometry,
        properties: sensor,
        type: 'Feature',
      })),
    };

    return prepareZeroIslandSensors(sensorsGeoJSON);
  };

  isSelected = (sensor) => {
    if (!this.props.selected) return false;
    return this.props.selected.identifier === sensor.identifier;
  };

  _getSensorData = (sensor, sensors) => {
    if (!sensors) {
      return null;
    }
    return sensors.find(function (s) {
      return s.identifier === sensor.identifier && sensor.type === s.type;
    });
  };

  getSensorData = (sensor) => {
    if (!this.props.data) {
      return null;
    }
    return this._getSensorData(sensor, this.props.data);
  };

  selectSensor = (sensor) => {
    this.props.handleSelection(sensor.type, sensor.identifier);
  };

  render() {
    const { popupInfo, showLabels } = this.state;
    const { data, paddingLeft, handleSelection } = this.props;

    return (
      <div className="map-parent">
        <Map
          selectSensor={(sensorType, sensorIdentifier) => {
            handleSelection(sensorType, sensorIdentifier);
          }}
          showAllSensorLabels={showLabels}
          activePopUp={popupInfo}
          sensorsGeoJSON={this.restructureToGeoJSON(data)}
          mapBoxPadding={{
            top: 100,
            bottom: 100,
            left: paddingLeft + 30 + 50,
            right: 100,
          }}
        >
          <div className="top-controls">
            <Dropdown
              trigger={['click']}
              overlay={
                () => (
                  <MapSettingsMenu
                    showLabels={showLabels}
                    toggleShowLabels={() => {
                      this.setState({ showLabels: !showLabels });
                    }}
                    renderItemShowLabels
                    renderItemPresentationMode={false}
                    onChangePresentationMode={() => { }}
                  />
                )
              }
            >
              <Button icon={<SettingOutlined />} type="link" size="large" />
            </Dropdown>
          </div>
        </Map>
      </div>
    );
  }
}
