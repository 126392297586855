import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';

import { sensorApi, constants } from 'farmx-api';

import { connect } from 'react-redux';
import { actions, selectors } from 'farmx-redux-core';

import { SensorTypeSelect } from 'farmx-web-ui';
import SensorIdentifierSelect from './SensorIdentifierSelect';

const InputGroup = Input.Group;
const {
  getSensorKey,
  getSensorObjectFromSensorKey,
} = sensorApi;
const {
  selectAllSensors,
  selectLoadingSensors,
  selectSensor,
  selectSensorById,
} = selectors;

function SensorSelect(props) {
  const {
    value,
    sensors,
    loading,
    style,
    sensorTypeFilter,
    loadAllSensors,
    loadSensor,
    onChange,
  } = props;

  const [sensor, setSensor] = useState(value);
  const { type: sensorType, identifier: sensorIdentifier } = sensor;

  useEffect(() => {
    loadAllSensors();
  }, [loadAllSensors]);

  useEffect(() => {
    if (sensorType && sensorIdentifier) {
      loadSensor({
        type: sensorType,
        identifier: sensorIdentifier,
      });
    }
  }, [sensorType, sensorIdentifier, loadSensor]);

  const onSensorTypeSelect = (type) => {
    const newValue = {
      type,
      identifier: undefined,
    };
    setSensor(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  const onSensorSelect = (sensorKey) => {
    const sensorObject = getSensorObjectFromSensorKey(sensorKey);
    setSensor(sensorObject);
    if (onChange) onChange(sensorObject);
  };

  const getSensorTypes = () => {
    const allTypes = Object.entries(constants.SENSOR_TYPES);
    if (sensorTypeFilter) {
      return allTypes.filter(
        ([sType, data]) => sType === sensorTypeFilter,
      );
    }
    return allTypes;
  };

  const sensorTypes = getSensorTypes();
  const selectedType = sensorTypes.length === 1 ? sensorTypes[0][0] : sensorType;

  return (
    <InputGroup style={{ display: 'flex', flex: '1', ...style }} compact>
      {sensorTypes.length !== 1
        ? (
          <SensorTypeSelect
            onChange={onSensorTypeSelect}
            selectedType={selectedType}
            sensorTypes={sensorTypes}
          />
        ) : null}
      <SensorIdentifierSelect
        sensors={sensors.filter((s) => (!selectedType) || s.type === selectedType)}
        showSensorType={!selectedType}
        onChange={onSensorSelect}
        selectedSensor={getSensorKey({ type: sensorType, identifier: sensorIdentifier })}
        isFetching={loading}
      />
    </InputGroup>
  );
}

const sensorPropType = PropTypes.shape({
  type: PropTypes.string,
  identifier: PropTypes.string,
  id: PropTypes.number,
});

SensorSelect.propTypes = {
  value: sensorPropType,
  sensors: PropTypes.arrayOf(sensorPropType),
  loading: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  sensorTypeFilter: PropTypes.string,
  loadAllSensors: PropTypes.func,
  loadSensor: PropTypes.func,
  onChange: PropTypes.func,
};

SensorSelect.defaultProps = {
  value: {},
  sensors: [],
  loading: false,
  style: {},
  sensorTypeFilter: undefined,
  loadAllSensors: () => {},
  loadSensor: () => {},
  onChange: () => {},
};

const mapStateToProps = (state, props) => {
  const { sensorType, sensorIdentifier, sensorId } = props;
  let sensor;
  if (sensorIdentifier) {
    sensor = selectSensor(state, sensorType, sensorIdentifier);
  } else {
    sensor = selectSensorById(state, sensorType, sensorId);
  }

  if (!sensor) {
    sensor = { type: sensorType, identifier: sensorIdentifier };
  }

  const sensors = selectAllSensors(state);
  const { loading } = selectLoadingSensors(state);
  return { sensors, loading, value: sensor };
};

// TODO: remove these from props and use useDispatch
const mapDispatchToProps = {
  loadAllSensors: actions.loadAllSensors,
  loadSensor: actions.loadSensorDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(SensorSelect);
