import React from 'react';
import { Breadcrumb } from 'antd';
import { helpers } from 'farmx-api';
import { hooks } from 'farmx-redux-core';

const { useEntity } = hooks;
const { getBreadcrumbTitle } = helpers;

function EntityBreadcrumbItem(props) {
  const farmObject = useEntity(props.id);
  if (!farmObject) return null;
  return (
    <Breadcrumb.Item>{getBreadcrumbTitle("entity", farmObject)}</Breadcrumb.Item>
  )
}

EntityBreadcrumbItem.__ANT_BREADCRUMB_ITEM = true;

export default EntityBreadcrumbItem;
