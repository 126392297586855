import React, { useState, useEffect } from 'react';
import { Button, Form, message } from 'antd';
import { selectors, actions } from 'farmx-redux-core';
import { reportsApi } from 'farmx-api';
import { RanchSelect } from 'farmx-web-ui';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { downloadJsonAsCsv } from './jsonToCsv';

export function RanchBlockReportForm() {
  const [form] = Form.useForm();
  const [ranchId, setRanchId] = useState(null);
  const [, forceUpdate] = useState();
  const dispatch = useDispatch();

  const ranch = useSelector((state) => selectors.selectRanchById(state, ranchId));
  const ranchName = ranch?.name;
  const blocks = useSelector((state) => selectors.selectBlocksForRanchId(state, ranchId));
  const blockIds = blocks.map((block) => block.id);

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  // load ranches and blocks
  useEffect(() => {
    dispatch(actions.loadFarmData());
  }, [dispatch]);

  function onFinish() {
    reportsApi.getBlockReport(blockIds).then((response) => {
      downloadJsonAsCsv(response.data, {
        name: 'Block Name',
        schedulesCreatedCount: 'Schedules Created',
        schedulesDeletedCount: 'Schedules Deleted',
        schedulesModifiedCount: 'Schedules Modified',
        schedulesTotalCurrentCount: 'Current Schedules',
        schedulesIrrigationCount: 'Scheduled Irrigation Events',
        schedulesManualOverridesCount: 'Manual Overrides',
        schedulesPumpOnOffCount: 'Pump On/Off events',
      }, `FarmXBlockReport_${ranchName}_${moment().format('YYYY-MM-DD HH:mm')}.csv`);
    }, () => {
      message.error('Failed to download report.');
    });
  }

  return (
    <Form
      form={form}
      onFinish={onFinish}
      className="page-form"
    >
      <Form.Item
        name="ranchId"
        validateTrigger="onFinish"
        rules={[{
          required: true,
          message: 'Please select a Ranch',
        }]}
      >
        <RanchSelect
          onSelect={setRanchId}
          placeholder="Select Ranch"
          useAllRanchData
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Download
        </Button>
      </Form.Item>
    </Form>
  );
}
