import React from 'react';
import { Breadcrumb } from 'antd';

import { connect } from 'react-redux';
import { actions, selectors } from 'farmx-redux-core';

import SensorBreadcrumbItem from './SensorBreadcrumbItem';
import {
  EntityBreadcrumbItem,
  RanchBreadcrumbItem,
  BlockBreadcrumbItem,
} from '../../farm';

const { loadSensorDetail } = actions;
const { selectSensor } = selectors;

const getSensorObject = (type, value) => {
  if (value === undefined) return undefined;
  if (value === null) return null;
  return {
    type,
    id: value,
  };
};

class SensorBreadCrumb extends React.Component {

  componentDidMount() {
    this.reloadData();
  }

  componentDidUpdate(prevProps) {
    const { sensor } = this.props;
    if (
      sensor.type !== prevProps.sensor.type
      || sensor.identifier !== prevProps.sensor.identifier
    ) {
      this.reloadData();
    }
  }

  reloadData () {
    const { sensor, loadSensorDetail } = this.props;
    const { type, identifier } = sensor;
    if (type && identifier) {
      loadSensorDetail({ type, identifier });
    }
  }

  render() {
    const { sensor } = this.props;
    if (!sensor || !sensor.identifier) return null;
    return (
      <Breadcrumb>
        <EntityBreadcrumbItem id={sensor.entity} />
        <RanchBreadcrumbItem id={sensor.ranch} />
        <BlockBreadcrumbItem id={sensor.block} />
        <SensorBreadcrumbItem sensor={getSensorObject('gateway', sensor.gateway)} />
        <SensorBreadcrumbItem sensor={getSensorObject('cavalier', sensor.node)} />
        <SensorBreadcrumbItem sensor={sensor} />
      </Breadcrumb>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { sensor: sensorProps } = props;
  const { type, identifier } = sensorProps;
  const sensor = selectSensor(state, type, identifier) || {};
  return { sensor };
};

const mapDispatchToProps = {
  loadSensorDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(SensorBreadCrumb);
