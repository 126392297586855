import { ArrowLeftOutlined } from "@ant-design/icons";
import { Layout, PageHeader, Spin } from "antd";
import React from "react";
import { SensorCopySoilMoisturePropertiesForm } from "./components/SensorCopySoilMoisturePropertiesForm";

interface SensorCopySoilMoisturePropertiesPageProps {
  sensors: any[];
  go_back: () => void;  
  on_submit_copy_form: (selected_sensors: any[], selected_ranch: any, selected_block: any) => void;
  processing: boolean;
}

/**
 * SensoryCopySoilMoisturePropertiesForm is a purely presentational component that renders a form to copy soil moisture properties from one sensor to another.
 * @param {any[]} props.sensors - The list of sensors available to copy to
 * @param {() => void} props.go_back - The function to go back to the previous page
 * @param {(selected_sensors: any[], selected_ranch: any, selected_block: any) => void} props.on_submit_copy_form - The function to call when the form is submitted
 * @param {boolean} props.processing - The processing state of the form
 * @returns {ReactElement} - The form component
 */
export const SensorCopySoilMoisturePropertiesPage: React.FC<SensorCopySoilMoisturePropertiesPageProps> = ({ sensors, on_submit_copy_form, go_back, processing }) => {

  return (
        <Layout>
          <PageHeader
            title="Copy Soil Moisture Properties"
            onBack={go_back}
            backIcon={<ArrowLeftOutlined /> }
          />
          <div className="page-body">
            {processing && <Spin />}
            <SensorCopySoilMoisturePropertiesForm disabled={processing} sensors={sensors} on_submit_copy_form={on_submit_copy_form} />
          </div>
      </Layout>
  );

}
