import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Modal,
  Menu,
  message,
} from 'antd';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { actions } from 'farmx-redux-core';
import { helpers } from 'farmx-api';

const { getSensorParams } = helpers;
const {
  uninstallSensor,
  loadSensorDetail,
  installSensor,
  copySensorMetadataToCav,
  copySensorMetadataFromCav,
  setSensorMoistureData,
  rebootMilesightNode,
} = actions;

export function SensorActionsMenu(props) {
  const { t } = useTranslation();
  
  const [processing, setProcessing] = useState(false);
  const dispatch = useDispatch();
  const { sensor } = props;
  const hasCavalier = sensor.node != null;
  const isMilesightNode = sensor.status?.subType === 'lora_sensor_node';
  const isSoilSensor = sensor.type == 'pixl_soil' || sensor.type == 'aquacheck_soil';

  const runAction = async (actionName, action) => {
    const sensorParams = getSensorParams(sensor);
    setProcessing(true);
    const success = await dispatch(action(sensorParams));
    setProcessing(false);
    // Check if async action is fulfilled
    if (success.type.split('/').at(-1) === 'fulfilled') {
      message.success(`${actionName} succeeded`);
    } else {
      message.error(`${actionName} failed`);
    }
    loadSensorDetail(sensor);
  };

  const rebootAction = () => {
    runAction('Reboot initiation', rebootMilesightNode);
  };

  const installAction = () => {
    runAction('Install', installSensor);
  };

  const uninstallAction = () => {
    runAction('Uninstall', uninstallSensor);
  };

  const copyMetaDataToCavAction = () => {
    runAction('Copy Metadata to Cav', copySensorMetadataToCav);
  };

  const copyMetaDataFromCavAction = () => {
    runAction('Copy Metadata from Cav', copySensorMetadataFromCav);
  };

  /**
   * Action to initiate soil moisture data will automatically look up and assign the sensor soil properties
   */
  const setMoistureData = () => {
    Modal.confirm({
      title: t('Set Soil Moisture Data'),
      content: t('This action will override soil moisture (soil_type, field_capacity, & wilting_point) settings currently on the sensor. Are you sure you want to continue?'),
      onOk() {
        runAction('Set Soil Moisture Data', setSensorMoistureData);
        // close the modal
        return new Promise((resolve, reject) => {
          resolve(null);
        });
      },
    });
  }

  /**
   * Confirm node reboot action
   */
  const confirmRebootAction = () => {
    Modal.confirm({
      title: t('Reboot'),
      content: t('This action will initiate a reboot, which will take place the next time the node wakes up.'),
      onOk() {
        rebootAction();
      },
    });
  };
  
  return (
    <Menu selectable={false}>
      <Menu.Item key="install" onClick={installAction} disabled={processing || sensor.install_state === 'installed'}>
        Install
      </Menu.Item>
      <Menu.Item key="uninstall" onClick={uninstallAction} disabled={processing}>
        Uninstall
      </Menu.Item>
      <Menu.Item key="replace" disabled={processing}>
        <Link to={{ pathname: '/sensor/replace', state: { sensor, showBack: true } }}>
          Replace
        </Link>
      </Menu.Item>
      <Menu.Item key="remove" disabled={processing}>
        <Link to={{ pathname: '/sensor/remove', state: { sensor, showBack: true } }}>
          Remove / Withdraw
        </Link>
      </Menu.Item>
      {hasCavalier ? (
        <>
          <Menu.Item key="copy-metadata-from-cav" onClick={copyMetaDataFromCavAction} disabled={processing}>
            Copy metadata from cavalier
          </Menu.Item>
          <Menu.Item key="copy-metadata-to-cav" onClick={copyMetaDataToCavAction} disabled={processing}>
            Copy metadata to cavalier
          </Menu.Item>
        </>
      ) : null}
      {isSoilSensor ? (
        <Menu.Item key="copy-soil-moisture-properties" disabled={processing}>
          <Link to={{ pathname: '/sensor/' + sensor.type + "/" + sensor.identifier + '/copy-soil-moisture-properties', state: { sensor, showBack: true } }}>
            Copy Soil Moisture Properties
          </Link>
        </Menu.Item>
      ) : null}
      {isSoilSensor ? (
        <Menu.Item key="soil-moisture" onClick={setMoistureData} disabled={processing}>
          Set Soil Moisture Data
        </Menu.Item>
      ) : null}
      {isMilesightNode && (
        <Menu.Item key="reboot" onClick={confirmRebootAction} disabled={processing}>
          Reboot
        </Menu.Item>
      )}
    </Menu>
  );
}


SensorActionsMenu.propTypes = {
  sensor: PropTypes.shape({
    install_state: PropTypes.string,
  }).isRequired,
};


export default SensorActionsMenu;
