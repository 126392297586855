/* eslint-disable class-methods-use-this */
import React from 'react';
import { Redirect } from 'react-router';
import { Form, Input, Button, Typography, message } from 'antd';
import { UserOutlined, LockFilled } from '@ant-design/icons';
import { authService } from 'farmx-api';
import './login.css';

const { Text, Title } = Typography;

export default class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fontLoaded: false,
      username: '',
      usernameValid: null,
      password: '',
      loginFailed: false,
      showLoading: false,
      buttonTitle: 'LOG IN',
      redirectToReferrer: false,
    };
  }

  validateUsername(email) {
    const re = /^[a-zA-Z0-9]{1,}$/;
    return re.test(email);
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: "/" } };
    const { username, password, usernameValid, redirectToReferrer, showLoading, loginFailed, buttonTitle } = this.state;

    if (redirectToReferrer === true) return <Redirect to={from} />;

    return (
      <div className="container">
        <div className="login-container">
          <Form className="login-form">
            <Title level={3} className="login-title">
              FarmX Admin Login
            </Title>
            <Input
              placeholder="Username"
              data-test-id="login-username"
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              onChange={(e) => { this.setState({username: e.target.value}) }}
              value={username}
            />
            <Input.Password
              placeholder="Password"
              data-test-id="login-password"
              prefix={<LockFilled style={{ color: 'rgba(0,0,0,.25)' }} />}
              onChange={(e) => this.setState({ password: e.target.value })}
              value={password}
              onPressEnter={this._signInAsync}
            />
            <Text type="danger">
              { loginFailed ? "Login failed" : null }
            </Text>
            <Button
              className="btn"
              onClick={this._signInAsync}
              loading={showLoading}
              data-test-id="login-submit"
              disabled={!usernameValid && password.length < 5}
            >
              { buttonTitle }
            </Button>
          </Form>
        </div>
      </div>
    );
  }

  _signInAsync = async () => {
    const { showLoading, username, password } = this.state;

    this.setState({
      showLoading: !showLoading,
      loginFailed: false,
      buttonTitle: 'LOGGING IN',
    });

    const loginFailedState = {
      showLoading: false,
      password: '',
      buttonTitle: 'LOG IN',
      loginFailed: true,
    };

    const success = await authService.login(username, password);
    if (success) {
      const userInfo = await authService.getUserInfo();

      if (userInfo && userInfo.admin) {
        this.setState({
          showLoading: false,
          buttonTitle: 'SUCCESS',
          redirectToReferrer: true
        });
      } else {
        await authService.logout();
        this.setState({
          ...loginFailedState,
          username: '',
        });
        message.error("User is not an admin");
      }
    } else {
      this.setState(loginFailedState);
    }
  };
}
