import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from 'antd';
import {
  FaTimes,
} from 'react-icons/fa';
import Icon from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { actions, selectors } from 'farmx-redux-core';
import StatsPage from './StatsPage';
import { SensorActionCellRenderer } from './cellRendererComponents';

const { loadSensorStatsList } = actions;
const {
  selectAllSensorStats,
  selectLoadingSensorStats,
} = selectors;

const reduceUrlParam = (obj, item) => {
  if (!item.value.includes(',')) {
    return {
      ...obj,
      [item.key]: {
        filterType: 'text',
        type: 'equals',
        filter: item.value,
      },
    };
  }

  const values = item.value.split(',');
  const multiFilter = values.reduce((filterObj, variable, currentIndex) => ({
    ...filterObj,
    [`condition${currentIndex + 1}`]: {
      filterType: 'text',
      type: 'equals',
      filter: variable,
    },
  }), {
    filterType: 'text',
    operator: 'OR',
  });

  return {
    ...obj,
    [item.key]: multiFilter,
  };
};

const defaultFilters = {
  install_state: { filterType: 'text', type: 'equals', filter: 'installed' },
};

function SensorStatsPage(props) {
  const {
    location: { search },
  } = props;

  const params = new URLSearchParams(search);
  const urlFilters = Array.from(params.entries(), ([key, value]) => (
    { key, value }
  )).reduce(reduceUrlParam, {});

  const [filters, setFilters] = useState(Array.from(params).length ? urlFilters : defaultFilters);

  const sensorStats = useSelector((state) => selectAllSensorStats(state));
  const sensorStatsLoading = useSelector((state) => selectLoadingSensorStats(state));
  const { loading, lastUpdated } = sensorStatsLoading;
  const dispatch = useDispatch();

  function filtersChanged(newFilters) {
    setFilters(newFilters);
  }

  function loadData() {
    dispatch(loadSensorStatsList());
  }

  const clearFilters = () => {
    setFilters({});
  };

  return (
    <StatsPage
      title="Sensor Stats"
      loadData={loadData}
      data={lastUpdated ? sensorStats : undefined}
      loading={loading}
      lastUpdated={lastUpdated}
      filters={filters}
      filtersChangedCallback={filtersChanged}
      sort={null}
      columns={[
        'up',
        'id',
        'identifier',
        'name',
        'sensor_type',
        'sensor_sub_type',
        'gateway',
        'node',
        'entity',
        'ranch',
        'block',
        'visible',
        'install_state',
        'provisioned',
        'calibrated',
        'location',
        'graph',
        'data_count',
        'data_latest_date',
        'latest_date_posted',
        'notes',
        'images',
        'health_check',
        'events',
        'alarms',
        'anomaly_count_24hr',
        'anomaly_count_2wk',
        'last_service_date',
        'sensorActions',

      ]}
      headerContent={(
        <span className="header-btns">
          <Button type="primary" onClick={clearFilters} disabled={!Object.keys(filters).length}>
            <Icon component={FaTimes} />
            <span>Clear Filters</span>
          </Button>
        </span>
      )}
      frameworkComponents={{
        sensorActionsMenu: SensorActionCellRenderer,
      }}
    />
  );
}

SensorStatsPage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({}),
    pathname: PropTypes.string,
    search: PropTypes.string,
  }),
};

SensorStatsPage.defaultProps = {
  location: {
    search: '',
    pathname: '',
    state: {
      showBack: false,
      sensor: null,
    },
  },
};

export default SensorStatsPage;
