import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'antd';
import { sensorPropType } from '../propTypes';

export function SensorAttributeListItem(props) {
  const {
    sensor,
    attr,
    force,
    title,
  } = props;
  const value = attr in sensor ? sensor[attr] : undefined;

  if (value === undefined && !force) return null;

  return (
    <List.Item>
      <List.Item.Meta
        title={title || attr}
      />
      <div>{value}</div>
    </List.Item>
  );
}

SensorAttributeListItem.propTypes = {
  sensor: sensorPropType.isRequired,
  attr: PropTypes.string.isRequired,
  force: PropTypes.bool,
  title: PropTypes.string,
};

SensorAttributeListItem.defaultProps = {
  force: false,
  title: null,
};
