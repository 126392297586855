import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions, selectors } from 'farmx-redux-core';
import StatsPage from './StatsPage';


export default function BlockStatsPage() {
  const dispatch = useDispatch();

  const loadData = useCallback(() => {
    dispatch(actions.loadBlockStatsList());
  }, [dispatch]);

  const blockStats = useSelector((state) => selectors.selectAllBlockStats(state));
  const blockStatsLoading = useSelector((state) => selectors.selectBlockStatsState(state));
  const { loading, lastUpdated } = blockStatsLoading;

  return (
    <StatsPage
      title="Block Stats"
      loadData={loadData}
      data={lastUpdated ? blockStats : undefined}
      loading={loading}
      lastUpdated={lastUpdated}
      sort={null}
      columns={[
        'id',
        'ranchId',
        'name',
        'areaAcres',
        'controlEnabled',
        'schedulingEnabled',
        'editBlock',
      ]}
    />
  );
}
