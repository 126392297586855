import Home from './Home';
import { MapPage } from '../map';
import { GraphPage, MultiGraphPage } from '../graph';
import { SettingsPage } from '../settings';
import { SearchPage } from '../search';
import { ReportsPage } from '../reports';
import {
  EntityList,
  EntityDetail,
  RanchPlans,
  PlansNew,
  PlansEdit,
  RanchGeojsonCreatePage,
} from '../installation-tool';
import {
  SensorDetailPage,
  SensorRemovePage,
  SensorReplacePage,
  SensorNotesPage,
  SensorImagesPage,
  CreateNotePage,
  SensorErrorsPage,
  SensorCopySoilMoisturePropertiesPage
} from '../sensor';
import {
  GatewayStatsPage,
  NodeStatsPage,
  SensorStatsPage,
} from '../table';
import BlockStatsPage from '../table/components/BlockStatsPage';
import { BlockDetailPage } from '../sensor/components/BlockDetailPage';
import SensorNotesPageWrapper from '../sensor/components/SensorNotesPageWrapper';
import EventsPage from '../stream/EventsPage';

const SENSOR_TYPE_REGEX = '[A-Za-z_]+';
const SENSOR_IDENTIFIER_REGEX = '[A-Za-z0-9:_.-]+';

export const ROUTE_KEYS = {
  ROOT: '/',
  MAP_ROOT: '/map',
  MAP_PARTIAL: '/map/:sensorType(gateway)',
  MAP: '/map/:sensorType(gateway)/:sensorIdentifier([A-Za-z0-9:_.]+)',
  SETTINGS: '/settings',
  GATEWAY_STATS: '/manage/gateway',
  CAVALIER_STATS: '/manage/cavalier',
  NODE_STATS: '/manage/node',
  BLOCK_STATS: '/manage/blocks',
  SENSOR_STATS: '/manage/sensor',
  // GRAPH: `/graph/:sensorType(${SENSOR_TYPE_REGEX})/:sensorIdentifier(${SENSOR_IDENTIFIER_REGEX})`,
  GRAPH: '/manage/graph',
  MULTI_GRAPH: '/graph',
  SENSOR_DETAIL: `/detail/:sensorType(${SENSOR_TYPE_REGEX})/:sensorIdentifier(${SENSOR_IDENTIFIER_REGEX})`,
  BLOCK_DETAIL: '/block/:id',
  SENSOR_ERRORS: `/errors/:sensorType(cavalier)/:sensorIdentifier(${SENSOR_IDENTIFIER_REGEX})`,
  SENSOR_REPLACE: '/sensor/replace/',
  SENSOR_REMOVE: '/sensor/remove/',
  SENSOR_COPY_SOIL_MOISTURE_PROPERTIES: `/sensor/:sensorType/:sensorIdentifier/copy-soil-moisture-properties`,
  MANAGE_NOTES: '/manage/notes/',
  MANAGE_IMAGES: '/manage/images/',
  NOTE_CREATE: '/notes/create',
  SEARCH: '/search',
  ENTITIES: '/entities',
  ENTITIES_DETAIL: '/entities/:entity_id',
  RANCH_PLANS: '/ranch-plans/:ranch_id',
  INSTALLATION_PLAN_NEW: '/installation-plan/new/:ranch_id',
  INSTALLATION_PLAN_EDIT: '/installation-plan/edit/:plan_id',
  RANCH_GEOJSON_CREATE: '/ranch/create/',
  REPORTS: '/reports',
  EVENTS: '/events',
};

export const ROUTES = {
  ROOT: {
    component: Home,
    exact: true,
    key: ROUTE_KEYS.ROOT,
    path: ROUTE_KEYS.ROOT,
  },
  SETTINGS: {
    component: SettingsPage,
    exact: true,
    key: ROUTE_KEYS.SETTINGS,
    path: ROUTE_KEYS.SETTINGS,
  },
  SEARCH: {
    component: SearchPage,
    exact: true,
    key: ROUTE_KEYS.SEARCH,
    path: ROUTE_KEYS.SEARCH,
  },
  GATEWAY_STATS: {
    component: GatewayStatsPage,
    exact: true,
    key: ROUTE_KEYS.GATEWAY_STATS,
    path: ROUTE_KEYS.GATEWAY_STATS,
  },
  BLOCK_STATS: {
    component: BlockStatsPage,
    exact: true,
    key: ROUTE_KEYS.BLOCK_STATS,
    path: ROUTE_KEYS.BLOCK_STATS,
  },
  CAVALIER_STATS: {
    component: NodeStatsPage,
    exact: true,
    key: ROUTE_KEYS.CAVALIER_STATS,
    path: ROUTE_KEYS.CAVALIER_STATS,
  },
  NODE_STATS: {
    component: NodeStatsPage,
    exact: true,
    key: ROUTE_KEYS.NODE_STATS,
    path: ROUTE_KEYS.NODE_STATS,
  },
  SENSOR_STATS: {
    component: SensorStatsPage,
    exact: true,
    key: ROUTE_KEYS.SENSOR_STATS,
    path: ROUTE_KEYS.SENSOR_STATS,
  },
  MAP_ROOT: {
    exact: true,
    component: MapPage,
    key: ROUTE_KEYS.MAP_ROOT,
    path: ROUTE_KEYS.MAP_ROOT,
  },
  MAP_PARTIAL: {
    exact: true,
    component: MapPage,
    key: ROUTE_KEYS.MAP_ROOT,
    path: ROUTE_KEYS.MAP_PARTIAL,
  },
  MAP: {
    exact: false,
    component: MapPage,
    key: ROUTE_KEYS.MAP_ROOT,
    path: ROUTE_KEYS.MAP,
  },
  GRAPH: {
    exact: false,
    component: GraphPage,
    key: ROUTE_KEYS.GRAPH,
    path: ROUTE_KEYS.GRAPH,
  },
  MULTI_GRAPH: {
    exact: false,
    component: MultiGraphPage,
    key: ROUTE_KEYS.MULTI_GRAPH,
    path: ROUTE_KEYS.MULTI_GRAPH,
  },
  SENSOR_DETAIL: {
    exact: true,
    component: SensorDetailPage,
    key: ROUTE_KEYS.SENSOR_DETAIL,
    path: ROUTE_KEYS.SENSOR_DETAIL,
  },
  BLOCK_DETAIL: {
    exact: true,
    component: BlockDetailPage,
    key: ROUTE_KEYS.BLOCK_DETAIL,
    path: ROUTE_KEYS.BLOCK_DETAIL,
  },
  SENSOR_ERRORS: {
    exact: true,
    component: SensorErrorsPage,
    key: ROUTE_KEYS.SENSOR_ERRORS,
    path: ROUTE_KEYS.SENSOR_ERRORS,
  },
  SENSOR_REPLACE: {
    exact: true,
    component: SensorReplacePage,
    key: ROUTE_KEYS.SENSOR_REPLACE,
    path: ROUTE_KEYS.SENSOR_REPLACE,
  },
  SENSOR_REMOVE: {
    exact: true,
    component: SensorRemovePage,
    key: ROUTE_KEYS.SENSOR_REMOVE,
    path: ROUTE_KEYS.SENSOR_REMOVE,
  },
  SENSOR_COPY_SOIL_MOISTURE_PROPERTIES: {
    exact: true,
    component: SensorCopySoilMoisturePropertiesPage,
    key: ROUTE_KEYS.SENSOR_COPY_SOIL_MOISTURE_PROPERTIES,
    path: ROUTE_KEYS.SENSOR_COPY_SOIL_MOISTURE_PROPERTIES,
  },
  MANAGE_NOTES: {
    exact: true,
    component: SensorNotesPageWrapper,
    key: ROUTE_KEYS.MANAGE_NOTES,
    path: ROUTE_KEYS.MANAGE_NOTES,
  },
  MANAGE_IMAGES: {
    exact: true,
    component: SensorImagesPage,
    key: ROUTE_KEYS.MANAGE_IMAGES,
    path: ROUTE_KEYS.MANAGE_IMAGES,
  },
  NOTE_CREATE: {
    exact: true,
    component: CreateNotePage,
    key: ROUTE_KEYS.NOTE_CREATE,
    path: ROUTE_KEYS.NOTE_CREATE,
  },
  ENTITIES: {
    exact: true,
    component: EntityList,
    key: ROUTE_KEYS.ENTITIES,
    path: ROUTE_KEYS.ENTITIES,
  },
  ENTITIES_DETAIL: {
    exact: true,
    component: EntityDetail,
    key: ROUTE_KEYS.ENTITIES_DETAIL,
    path: ROUTE_KEYS.ENTITIES_DETAIL,
  },
  RANCH_PLANS: {
    exact: true,
    component: RanchPlans,
    key: ROUTE_KEYS.RANCH_PLANS,
    path: ROUTE_KEYS.RANCH_PLANS,
  },
  INSTALLATION_PLAN_NEW: {
    exact: true,
    component: PlansNew,
    key: ROUTE_KEYS.INSTALLATION_PLAN_NEW,
    path: ROUTE_KEYS.INSTALLATION_PLAN_NEW,
  },
  INSTALLATION_PLAN_EDIT: {
    exact: true,
    component: PlansEdit,
    key: ROUTE_KEYS.INSTALLATION_PLAN_EDIT,
    path: ROUTE_KEYS.INSTALLATION_PLAN_EDIT,
  },
  RANCH_GEOJSON_CREATE: {
    exact: true,
    component: RanchGeojsonCreatePage,
    key: ROUTE_KEYS.RANCH_GEOJSON_CREATE,
    path: ROUTE_KEYS.RANCH_GEOJSON_CREATE,
  },
  REPORTS: {
    exact: true,
    component: ReportsPage,
    key: ROUTE_KEYS.REPORTS,
    path: ROUTE_KEYS.REPORTS,
  },
  EVENTS: {
    component: EventsPage,
    exact: true,
    key: ROUTE_KEYS.EVENTS,
    path: ROUTE_KEYS.EVENTS,
  },
};
