import React from 'react';
import { Layout, PageHeader } from 'antd';
import { authService } from 'farmx-api';
import AppConfig from './AppConfig';
import './settings.css';

export default class SettingsPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      userInfo: {},
    };
  }

  componentDidMount = async () => {
    const userInfo = await authService.getUserInfo();
    this.setState({
      userInfo,
    });
  }

  render() {
    const { userInfo } = this.state;
    return (
      <Layout>
        <PageHeader
          title="Settings"
        >
          { userInfo.username
            ? <p>{`Logged in as ${userInfo.username}`}</p>
            : null}
        </PageHeader>
        <Layout.Content className="content-container padded">
          <AppConfig />
        </Layout.Content>
      </Layout>
    );
  }
}
