import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { constants } from 'farmx-api';
import { hooks } from 'farmx-redux-core';

const { useBlock } = hooks;

const getEditUrl = (id) => `${constants.ADMIN_URL}/farm/block/${id}/`;

function BlockLink({ id }) {
  const block = useBlock(id);
  const { t } = useTranslation();
  if (!block) return null;
  return (
    <>
      <Link to={`/manage/sensor/?block=${block.name}`}>
        {`${block.name} [${block.id}]`}
      </Link>
      &nbsp;
      <Button href={getEditUrl(id)} size="small">{t('Edit')}</Button>
    </>
  );
}

BlockLink.propTypes = {
  id: PropTypes.number.isRequired,
};

export default BlockLink;
