import React from 'react';
import { Breadcrumb } from 'antd';
import { helpers } from 'farmx-api';
import { hooks } from 'farmx-redux-core';

const { useBlock } = hooks;
const { getBreadcrumbTitle } = helpers;

function BlockBreadcrumbItem(props) {
  const farmObject = useBlock(props.id);
  if (!farmObject) return null;
  return (
    <Breadcrumb.Item>{getBreadcrumbTitle("block", farmObject)}</Breadcrumb.Item>
  )
}

BlockBreadcrumbItem.__ANT_BREADCRUMB_ITEM = true;

export default BlockBreadcrumbItem;
