import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { userApi } from 'farmx-api';

export function UserSelect({ onSelect, onChange }) {
  const [users, setUsers] = useState(undefined);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);

  // load users
  useEffect(() => {
    setIsLoadingUsers(true);
    userApi.getUsers().then((response) => {
      setUsers(response.data);
      setIsLoadingUsers(false);
    }).catch(() => {
      setIsLoadingUsers(false);
    });
  }, [setUsers, setIsLoadingUsers]);

  function renderItem(item) {
    return (
      <Select.Option key={item.id} value={item.id} label={item.username}>
        {item.username}
      </Select.Option>
    );
  }

  const items = [];
  if (users && users.length > 0) {
    users.sort((a, b) => b.id - a.id).forEach((d) => {
      items.push(renderItem(d));
    });
  }

  return (
    <Select
      placeholder="Select User(s)"
      mode="multiple"
      onSelect={onSelect}
      onChange={onChange}
      loading={isLoadingUsers}
      showSearch
      filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
    >
      {items}
    </Select>
  );
}

UserSelect.propTypes = {
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
};

UserSelect.defaultProps = {
  onSelect: null,
  onChange: null,
};
