import React from 'react';
import { RouteComponentProps } from 'react-router';
import { SensorCopySoilMoisturePropertiesPage } from './SensorCopySoilMoisturePropertiesPage';
import { connect, useDispatch } from 'react-redux';
//@ts-ignore
import { actions, selectors } from 'farmx-redux-core';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

const { selectAllSensors } = selectors;

const { copySoilSensorProperties } = actions;

// Define types for custom props
interface CustomProps {
  sensors: any[];
  ranches: any[];
  blocks: any[];
}

// Define types for route params
interface MatchParams {
  sensorIdentifier: string;
  sensorType: string;
}

// Combine RouteComponentProps with CustomProps
interface Props extends RouteComponentProps<MatchParams>, CustomProps {}

/**
 * SensorCopySoilMoisturePropertiesPage is a container component that renders the SensorCopySoilMoistureProperties Page, including the form to copy soil moisture properties from one sensor to another, and manages the form state.
 * @param {Props} props - The combined props including route and custom props
 * @returns {ReactElement}
 */
const SensorCopySoilMoisturePropertiesPageContainer: React.FC<Props> = ({ match, history, sensors, ranches, blocks }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { sensorIdentifier: sensorId, sensorType: sensorType } = match.params;
  const [processing, setProcessing] = React.useState(false);

  const onSubmitCopyForm = async (selectedSensors: any[], selectedRanch: any, selectedBlock: any) => {
    setProcessing(true);
    const success = await dispatch(copySoilSensorProperties({
      sensorType,
      sensorId,
      selectedSensors,
      selectedBlock,
      selectedRanch
    }));
    setProcessing(false);
    // Check if async action is fulfilled
    if (success?.type?.split('/').at(-1) === 'fulfilled') {
      message.success(`${success.payload.data.sensors_updated} ` + t(' sensors updated.'));
    } else {
      message.error(t('Copy Soil Sensor Properties Failed'));
    }
  }

  return (
    <div>
      <SensorCopySoilMoisturePropertiesPage 
        go_back={history.goBack} 
        sensors={sensors} 
        on_submit_copy_form={onSubmitCopyForm}
        processing={processing}
      />
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  sensors: selectAllSensors(state),
});

export default connect(mapStateToProps, {})(SensorCopySoilMoisturePropertiesPageContainer);
