import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { PrivateRoute } from "farmx-web-ui";
import { authService } from "farmx-api";
import { LoginPage, LogoutRedirect } from "../authentication";
import Main from "./Main";
import { SoilDemoPage } from "../demo";
import "./app.css";

function App() {
  return (
    <Router>
      <Switch>
        <Route component={LoginPage} exact path="/login" />
        <Route component={LogoutRedirect} exact path="/logout" />
        <PrivateRoute
          component={SoilDemoPage}
          path="/soil-demo/:sensorIdentifier([A-Za-z0-9:_.]+)"
          adminRequired={true}
          authService={authService}
        />
        <Route component={Main} />
      </Switch>
    </Router>
  );
}

export default App;
