import React from 'react';
import { Button } from 'antd';
import { actions, selectors } from 'farmx-redux-core';
import { withRouter } from 'react-router';
import StatsPage from '../../table/components/StatsPage';

const columns = [
  'date',
  'sensorType',
  'sensorIdentifier',
  'action',
  'text',
  'user',
];

export class SensorNotesPage extends React.Component {
  showCreateNote = () => {
    const { history } = this.props;
    history.push('/notes/create');
  };

  render() {
    const {
      lastUpdated, data, loading, loadData, filters, sort, filtersChanged, onGridReady, rowCount, onPaginationChanged,
    } = this.props;

    return (
      <StatsPage
        title="Sensor Notes"
        columns={columns}
        loadData={loadData}
        data={lastUpdated ? data : undefined}
        loading={loading}
        lastUpdated={lastUpdated}
        filters={filters}
        sort={sort}
        filtersChangedCallback={filtersChanged}
        sizeToFit
        extra={[
          <Button type="primary" key="create-note" onClick={this.showCreateNote}>Create Note</Button>,
        ]}
        pagination
        onGridReady={onGridReady}
        onPaginationChanged={onPaginationChanged}
        rowModelType="serverSide"
      />
    );
  }
}

export default withRouter(SensorNotesPage);
