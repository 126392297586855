import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select, Tag } from 'antd';
import { helpers } from 'farmx-api';
import { connect } from 'react-redux';
import { actions, selectors } from 'farmx-redux-core';

const { selectSensorCapabilities } = selectors;
const { fieldToHeader } = helpers;

export function SensorCapabilitySelect(props) {
  const {
    sensor,
    loadSensorCapabilities,
    disabled,
    capabilities,
    isFetching,
    value,
    defaultValue,
    style,
    onChange,
    mode,
    colors,
  } = props;

  const { identifier, type } = sensor;

  useEffect(() => {
    if (type && identifier) {
      loadSensorCapabilities({ type, identifier });
    }
  }, [type, identifier, loadSensorCapabilities]);

  const getCapabilityName = (capability) => fieldToHeader(capability);

  /**
   * Render a custom tag for selected values.
   * The tag picks color from the list of colors received as props.
   * The colors props contain graph colors. The first value gets the first color
   * from the list and so on.
   */
  const renderTag = (params) => {
    const {
      label, closable, onClose, value: tagValue,
    } = params;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    // Determine which color to be used for a value.
    const tagValueIndex = value && value?.length && value.findIndex((v) => v === tagValue);
    const color = (colors && colors.length >= (tagValueIndex + 1)) ? colors[tagValueIndex] : null;
    return (
      <Tag
        color={color}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
        }}
      >
        {label}
      </Tag>
    );
  };

  return (
    <Select
      disabled={disabled}
      allowClear
      mode={mode}
      defaultValue={defaultValue}
      value={value}
      style={style}
      placeholder={mode === 'multiple' ? 'Select Variables' : 'Select Variable'}
      optionLabelProp="label"
      dropdownMatchSelectWidth={false}
      onChange={onChange}
      notFoundContent={isFetching ? 'loading...' : 'None available'}
      className="aw-sensor-capability-select"
      tagRender={renderTag}
    >
      {capabilities && capabilities.map((capability) => {
        const name = getCapabilityName(capability);
        return (
          <Select.Option key={capability} label={name}>{name}</Select.Option>
        );
      })}
    </Select>
  );
}

SensorCapabilitySelect.propTypes = {
  sensor: PropTypes.shape({
    type: PropTypes.string,
    identifier: PropTypes.string,
    id: PropTypes.number,
  }),
  loadSensorCapabilities: PropTypes.func,
  disabled: PropTypes.bool,
  capabilities: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  isFetching: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  defaultValue: PropTypes.arrayOf(PropTypes.string),
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func,
  mode: PropTypes.oneOf(['multiple', 'tags']),
  colors: PropTypes.arrayOf(PropTypes.string),
};

SensorCapabilitySelect.defaultProps = {
  mode: 'multiple',
  sensor: {},
  loadSensorCapabilities: () => { },
  disabled: false,
  capabilities: [],
  isFetching: false,
  value: undefined,
  defaultValue: undefined,
  style: {},
  onChange: () => { },
  colors: [],
};

const mapStateToProps = (state, props) => {
  const { identifier, type } = props.sensor || {};
  const { defaultValue } = props;
  const defaultCapabilities = defaultValue || [];

  const sensorCapabilities = selectSensorCapabilities(state, type, identifier);
  const capabilities = sensorCapabilities || defaultCapabilities;
  return {
    capabilities,
  };
};

const mapDispatchToProps = {
  loadSensorCapabilities: actions.loadSensorCapabilities,
};

export default connect(mapStateToProps, mapDispatchToProps)(SensorCapabilitySelect);
