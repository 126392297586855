import { min } from 'lodash';

export default {
  sensor_details: {
    sensor_uid: 18877,
    sensor_name: '6454E25600830009',
    sensor_id: '24e124454e256008',
    sensor_type: 'cavalier',
  },
  graph_data: {
    gateway_rssi: {
      variable: 'gateway_rssi',
      data: [
        {
          gateway: '24e124fffef8235e',
          data: [
            [
              1732493158000,
              -115.0,
            ],
            [
              1732495858000,
              -101.0,
            ],
            [
              1732496758000,
              -102.0,
            ],
            [
              1732497658000,
              -108.0,
            ],
            [
              1732498558000,
              -104.0,
            ],
            [
              1732499458000,
              -104.0,
            ],
            [
              1732500358000,
              -108.0,
            ],
            [
              1732501258000,
              -102.0,
            ],
            [
              1732502162000,
              -106.0,
            ],
            [
              1732503058000,
              -102.0,
            ],
            [
              1732503958000,
              -107.0,
            ],
            [
              1732504858000,
              -108.0,
            ],
            [
              1732505758000,
              -102.0,
            ],
            [
              1732506662000,
              -101.0,
            ],
            [
              1732507558000,
              -105.0,
            ],
            [
              1732508458000,
              -107.0,
            ],
            [
              1732509362000,
              -108.0,
            ],
            [
              1732510258000,
              -101.0,
            ],
          ],
          min: -115.0,
          max: -100.0,
        },
        {
          gateway: '24e124fffefacb0f',
          data: [
            [
              1732494958000,
              -100.0,
            ],
            [
              1732497658000,
              -108.0,
            ],
            [
              1732498558000,
              -104.0,
            ],
            [
              1732499458000,
              -104.0,
            ],
            [
              1732500358000,
              -108.0,
            ],
            [
              1732501258000,
              -102.0,
            ],
            [
              1732502162000,
              -106.0,
            ],
            [
              1732503058000,
              -102.0,
            ],
            [
              1732503958000,
              -107.0,
            ],
            [
              1732504858000,
              -108.0,
            ],
            [
              1732505758000,
              -102.0,
            ],
          ],
          max: -100.0,
          min: -110.0,
        },
      ],
      units: 'dB',
    },
  },
};
