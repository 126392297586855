import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Menu,
  Dropdown,
  Button,
} from 'antd';
import Icon, { DownOutlined } from '@ant-design/icons';

import {
  FaTimes,
} from 'react-icons/fa';

import { actions, selectors } from 'farmx-redux-core';
import StatsPage from './StatsPage';
import { SensorActionCellRenderer } from './cellRendererComponents';

const { loadGatewayStatsList } = actions;
const {
  selectAllGatewayStats,
  selectLoadingGatewayStats,
} = selectors;

const allColumns = [
  'name',
  'last_ping',
  'heartbeat_date',
  'epsolar_date',
  'weather_date',

  'sensor_stats_cavalier',
  'sensor_stats_soil',
  'sensor_stats_pressure',
  'sensor_stats_other',

  'num_provisioned',
  'validation_errors',

  'identifier',
  'priority',
  'entity',
  'ranch',
  'block',
  'sensor_sub_type',
  'id',
  'ip_address',
  'hostname',
  'visible',
  'install_state',
  'gatewayLocation',

  'notes',
  'images',

  'cell_rssi',
  'cell_rssi_min',
  'signal_quality',
  'uptime',
  'last_boot_time',
  'internet_access',
  'update_keys',

  'cavalier_gateway_sw',
  'aquacheck_sw',
  'basestation_utilities_sw',
  'healthcheck_sw',
  'epsolar_sw',
  'weathergw_sw',
  'dalmatian-web_sw',

  'num_sensors',
  'num_cavaliers',
  'num_weather_stations',

  'events',
  'alarms',
  'sensor_anomalies_24hr',
  'sensor_anomalies_2wk',
  'last_service_date',

  'cgsn',
  'phone',
  'vendor_product',
  'frame_error_rate',
  'memory_free',
  'memory_total',
  'loadavg_1m',
  'loadavg_5m',
  'loadavg_15m',

  'cpu_freq',
  'cpu_temp',
  'case_temp',

  'has_acpower',
  'battery_type',
  'solarpanel_config',
  'battery_temp',
  'battery_voltage',
  'min_battery_volt',
  'max_battery_volt',
  'battery_current',
  'generated_energy_today',
  'consumed_energy_today',
  'charge_controller_current',
  'charge_controller_voltage',
  'health_check',

  'sensorActions',
];

const summaryCols = [
  'hostname',
  'last_ping',
  'heartbeat_date',
  'epsolar_date',
  'weather_date',

  'sensor_stats_cavalier',
  'sensor_stats_soil',
  'sensor_stats_pressure',
  'sensor_stats_other',

  'name',
  'identifier',
  'sensor_sub_type',
  'id',
  'ip_address',
  'entity',
  'ranch',
  'block',
  'install_state',
  'gatewayLocation',

  'notes',
  'images',

  'sensorActions',
];

const batteryCols = [
  'hostname',
  'battery_temp',
  'battery_voltage',
  'min_battery_volt',
  'max_battery_volt',
  'battery_current',
  'generated_energy_today',
  'consumed_energy_today',
  'charge_controller_current',
  'charge_controller_voltage',
  'has_acpower',
  'battery_type',
  'solarpanel_config',
  'sensorActions',
];

const stationHealthCols = [
  'hostname',
  'cell_rssi',
  'cell_rssi_min',
  'signal_quality',
  'uptime',
  'last_boot_time',
  'internet_access',

  'frame_error_rate',
  'memory_free',
  'memory_total',
  'loadavg_1m',
  'loadavg_5m',
  'loadavg_15m',

  'cpu_freq',
  'cpu_temp',
  'case_temp',
];

const stationDataCols = [
  'hostname',
  'identifier',
  'ip_address',
  'cgsn',
  'phone',
  'vendor_product',
  'update_keys',
];

const defaultFilters = {
  visible: { filterType: 'text', type: 'equals', filter: 'yes' },
  health_check: { filterType: 'text', type: 'equals', filter: 'yes' },
  install_state: { filterType: 'text', type: 'equals', filter: 'installed' },
};

function GatewayStatsPage(props) {
  const { location } = props;
  const data = useSelector((state) => selectAllGatewayStats(state));

  const gatewayStatsLoading = useSelector((state) => selectLoadingGatewayStats(state));
  const { loading, lastUpdated } = gatewayStatsLoading;
  const dispatch = useDispatch();

  const params = new URLSearchParams(location.search);

  const urlFilters = Array.from(params.entries(), ([key, value]) => (
    { key, value }
  )).reduce((obj, item) => ({
    ...obj,
    [item.key]: {
      filterType: 'text',
      type: 'equals',
      filter: item.value,
    },
  }), {});

  const [columnsVisible, setColumnsVisible] = useState(allColumns);
  const [filters, setFilters] = useState(Array.from(params).length ? urlFilters : defaultFilters);
  const [sort, setSort] = useState([
    { colId: 'entity', sort: 'asc' },
  ]);

  const clearFilters = () => {
    setFilters({});
    setSort(null);
  };

  function filtersChanged(newFilters) {
    setFilters(newFilters);
  }

  function loadData() {
    dispatch(loadGatewayStatsList());
  }

  const presetMenu = (
    <Menu>
      <Menu.Item key="summary" onClick={() => setColumnsVisible(summaryCols)}>Summary</Menu.Item>
      <Menu.Item key="battery" onClick={() => setColumnsVisible(batteryCols)}>Battery Info</Menu.Item>
      <Menu.Item key="health" onClick={() => setColumnsVisible(stationHealthCols)}>Health</Menu.Item>
      <Menu.Item key="metadata" onClick={() => setColumnsVisible(stationDataCols)}>Metadata</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="all" onClick={() => setColumnsVisible(allColumns)}>View All</Menu.Item>
    </Menu>
  );

  return (
    <StatsPage
      title="Gateway Stats"
      columns={allColumns}
      columnsVisible={columnsVisible}
      loadData={loadData}
      data={lastUpdated ? data : undefined}
      loading={loading}
      lastUpdated={lastUpdated}
      filters={filters}
      sort={sort}
      filtersChangedCallback={filtersChanged}
      headerContent={(
        <span className="header-btns">
          <Button type="primary" onClick={clearFilters} disabled={!Object.keys(filters).length}>
            <Icon component={FaTimes} />
            <span>Clear Filters</span>
          </Button>
          <Dropdown overlay={presetMenu}>
            <Button>
              Presets
              {' '}
              <DownOutlined />
            </Button>
          </Dropdown>
        </span>
      )}
      frameworkComponents={{
        sensorActionsMenu: SensorActionCellRenderer,
      }}
    />
  );
}

GatewayStatsPage.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({}),
    pathname: PropTypes.string,
    search: PropTypes.string,
  }),
};

GatewayStatsPage.defaultProps = {
  history: {
    goBack: () => { },
    push: () => { },
  },
  location: {
    search: '',
    pathname: '',
    state: {
      showBack: false,
      sensor: null,
    },
  },
};

export default GatewayStatsPage;
