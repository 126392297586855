import moment from 'moment';

/**
 * @param {*} data - array of objects that from API response
 * Function to convert JSON array to CSV format
 */
export function convertToCSV(data, headers) {
  let csv;
  if (data && data.length) {
    const json = data;
    let fields = [];
    if (json && json[0]) {
      fields = Object.keys(json[0]);
    }
    const replacer = (_key, value) => (value === null ? '' : value);
    csv = json.map((row) => fields.map((fieldName) => JSON.stringify(row[fieldName], replacer))
      .join(','));
    if (fields.length) {
      // add header column
      let csvHeaders = fields;
      if (headers) csvHeaders = fields.map((field) => headers[field] || field);
      csv.unshift(csvHeaders.join(','));
    }
    csv = csv.join('\r\n');
  }

  return csv;
}

export function downloadCSVFile(jsonData, csvFileName) {
  const defaultName = `CSV-Data-${moment().format('YYYY-MM-DD HH:mm:ss')}.csv`;
  const url = window.URL.createObjectURL(new Blob([jsonData]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', csvFileName || defaultName);
  document.body.appendChild(link);
  link.click();
}

export function downloadJsonAsCsv(data, headers, csvFileName) {
  return downloadCSVFile(convertToCSV(data, headers), csvFileName);
}
