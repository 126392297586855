import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions, selectors } from 'farmx-redux-core';
import cloneDeep from 'lodash/cloneDeep';
import { createSelector } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom';
import { Table, Layout, PageHeader } from 'antd';
import { notifyError } from './plans-edit/utils';

const { loadEntityList, loadRanchList } = actions;
const {
  selectAllEntities,
  selectAllRanches,
  selectRanchesLoadingState,
  selectEntitiesLoadingState,
} = selectors;

const selectEntitiesRanches = createSelector(
  [
    selectEntitiesLoadingState,
    selectRanchesLoadingState,
    selectAllEntities,
    selectAllRanches,
  ],
  (entitiesProgress, ranchesProgress, entities, ranches) => {
    const result = {
      loading: true,
      data: [],
      error: false,
    };

    if (entitiesProgress.loading || ranchesProgress.loading) {
      return result;
    }

    result.loading = false;

    if (entitiesProgress.error || ranchesProgress.error) {
      result.error = entitiesProgress.error || ranchesProgress.error;
      return result;
    }
    const entitiesObj = entities.reduce(
      (acc, entity) => {
        acc[entity.id] = cloneDeep(entity);
        return acc;
      },
      {},
    );

    const entitiesRanchesObj = ranches.reduce(
      (acc, ranch) => {
        if (!acc[ranch.entity.id].ranches) {
          acc[ranch.entity.id].ranches = [];
        }
        acc[ranch.entity.id].ranches.push(ranch);
        return acc;
      },
      entitiesObj,
    );

    result.data = Object.keys(entitiesRanchesObj)
      .map((k) => {
        let ranchesText = '0 ranches';
        if (entitiesRanchesObj[k].ranches instanceof Array) {
          ranchesText = entitiesRanchesObj[k].ranches.length === 1
            ? entitiesRanchesObj[k].ranches[0].name
            : `${entitiesRanchesObj[k].ranches.length} ranches`;
        }
        const row = {
          key: k,
          name: entitiesRanchesObj[k].name,
          ranches: ranchesText,
        };
        return row;
      });

    return result;
  },
);

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => String(a.name).localeCompare(b.name),
  },
  {
    title: 'Ranches',
    dataIndex: 'ranches',
    key: 'ranches',
    sorter: (a, b) => String(a.ranches).localeCompare(b.ranches),
  },
];

export function EntityList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const selData = useSelector((state) => selectEntitiesRanches(state));
  const [didDispatch, setDidDispatch] = useState(false);
  useEffect(() => {
    dispatch(loadEntityList());
    dispatch(loadRanchList()).then(() => { setDidDispatch(true); });
  }, [dispatch]);

  useEffect(() => {
    if (didDispatch && selData?.error) {
      notifyError(selData?.error?.message);
    }
  }, [selData, didDispatch]);

  return (
    <Layout className="entitylist-page" style={{ overflowX: 'scroll' }}>
      <PageHeader title="Entities" />

      {!selData.error && (
        <Table
          dataSource={selData.data}
          columns={columns}
          pagination={{ pageSize: 100 }}
          loading={selData.loading}
          onRow={(record) => ({
            onClick: () => {
              history.push(`/entities/${record.key}`);
            },
          })}
        />
      )}
    </Layout>
  );
}
