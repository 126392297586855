import React from 'react';
import PropTypes from 'prop-types';
import {
  PageHeader,
  Layout,
  Button,
  Descriptions,
} from 'antd';
import Icon from '@ant-design/icons';

import {
  FaSyncAlt,
} from 'react-icons/fa';

import './sensorDetailPage.css';

import Moment from 'react-moment';
import { constants, helpers } from 'farmx-api';
import { BooleanTag, InstallStateTag } from 'farmx-web-ui';

import { connect } from 'react-redux';
import { actions, selectors } from 'farmx-redux-core';

import SensorActionsDropdown from './SensorActionsDropdown';
import SensorLink from './SensorLink';
import {
  EntityLink,
  RanchLink,
  BlockLink,
} from '../../farm';


const { getSensorEditUrl } = helpers;
const {
  selectSensor,
  selectSensorStatus,
} = selectors;

function notFound() {
  return (
    <div className="detail-page-content">
      Sensor Not Found
    </div>
  );
}

function renderOptionalDescription(label, value, optional) {
  if (optional && value === undefined) return null;
  return (
    <Descriptions.Item label={label}>{value}</Descriptions.Item>
  );
}

export class SensorDetailPage extends React.Component {
  componentDidMount() {
    const { loadSensorDetail, loadSensorStatus, match } = this.props;
    const { sensorIdentifier, sensorType } = match.params;
    const sensorParams = ({ type: sensorType, identifier: sensorIdentifier });
    loadSensorDetail(sensorParams);
    loadSensorStatus(sensorParams);
  }

  sensorTitle = () => {
    const { sensor } = this.props;
    if (!sensor) return 'Not Found';
    const { type, identifier } = sensor;
    const sensorTypeName = type;
    return `[${sensorTypeName}] ${identifier}`;
  }

  renderLastUpdated = () => {
    const { sensor } = this.props;
    const { lastUpdated } = sensor || null;
    if (!lastUpdated) return null;
    return (
      <span key="last-updated">
        {'Last updated '}
        <Moment fromNow>{lastUpdated}</Moment>
      </span>
    );
  }


  onRefresh = async () => {
    const { loadSensorDetail, loadSensorStatus, match } = this.props;
    const { sensorIdentifier, sensorType } = match.params;
    const sensorParams = ({ type: sensorType, identifier: sensorIdentifier });
    loadSensorDetail(sensorParams);
    loadSensorStatus(sensorParams);
  }

  renderSensorLocation = (sensor) => {
    if (sensor.location == null) return null;
    return `${sensor.location.lat}, ${sensor.location.lng}`;
  }

  renderPageContent = (sensor) => {
    if (!sensor) return notFound();
    return (
      <div className="detail-page-content">
        <div className="detail-buttons">
          <SensorActionsDropdown sensor={sensor} />
          <Button disabled={!getSensorEditUrl(sensor)} href={getSensorEditUrl(sensor)}>Edit</Button>
        </div>
        <Descriptions title=" " column={1}>
          {renderOptionalDescription('Name', sensor.name)}
          {renderOptionalDescription('Sensor Type', sensor.type)}
          {renderOptionalDescription('Identifier', sensor.identifier)}
          {renderOptionalDescription('Database id', sensor.id)}
          <Descriptions.Item label="Online">
            {sensor.status ? <BooleanTag value={sensor.status.online} /> : 'unknown'}
          </Descriptions.Item>
          <Descriptions.Item label="First Post">
            {sensor.status && sensor.status.earliestDate
              ? <Moment>{sensor.status.earliestDate}</Moment> : 'None'}
          </Descriptions.Item>
          <Descriptions.Item label="Last Post">
            {sensor.status && sensor.status.latestDate
              ? <Moment>{sensor.status.latestDate}</Moment> : 'None'}
          </Descriptions.Item>
          {renderOptionalDescription(
            'Install State',
            <InstallStateTag state={sensor.install_state} />,
          )}
          {renderOptionalDescription('Visible', <BooleanTag value={sensor.visible} />)}
          <Descriptions.Item label="Location">
            {this.renderSensorLocation(sensor)}
          </Descriptions.Item>
          {renderOptionalDescription('Entity', <EntityLink id={sensor.entity} />)}
          {renderOptionalDescription('Ranch', <RanchLink id={sensor.ranch} />)}
          {renderOptionalDescription('Block', <BlockLink id={sensor.block} />)}
          {renderOptionalDescription(
            'Gateway',
            <SensorLink sensor={{ type: 'gateway', id: sensor.gateway }} />,
            sensor.type === 'gateway',
          )}
          {renderOptionalDescription(
            'Node',
            (sensor.type !== 'gateway' && sensor.type !== 'cavalier') && <SensorLink sensor={{ type: 'cavalier', id: sensor.node }} />,
            sensor.type === 'gateway' || sensor.type === 'cavalier',
          )}
        </Descriptions>
      </div>
    );
  }

  render() {
    const { sensor } = this.props;
    const { loading } = sensor || true;
    return (
      <Layout className="detail-page">
        <PageHeader
          title="Sensor Detail"
          subTitle={[
            this.sensorTitle(),
          ]}
          extra={[
            this.renderLastUpdated(),
            <Button key="refresh" onClick={this.onRefresh} disabled={loading}>
              {loading ? 'Loading...' : 'Refresh'}
              <Icon component={FaSyncAlt} className={loading ? 'fa-spin' : ''} />
            </Button>,
          ]}
        />
        {this.renderPageContent(sensor)}
      </Layout>
    );
  }
}

SensorDetailPage.propTypes = {
  sensor: PropTypes.shape({
    identifier: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.number,
  }),
  loadSensorDetail: PropTypes.func.isRequired,
  loadSensorStatus: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      sensorIdentifier: PropTypes.string,
      sensorType: PropTypes.string,
    }),
  }),
};

SensorDetailPage.defaultProps = {
  sensor: null,
  match: {
    params: {},
  },
};

const mapStateToProps = (state, props) => {
  const { sensorIdentifier, sensorType } = props.match.params;
  const detail = selectSensor(state, sensorType, sensorIdentifier);
  const status = selectSensorStatus(state, sensorType, sensorIdentifier);
  const sensor = {
    ...detail,
    status,
  };
  return {
    sensor,
  };
};

const mapDispatchToProps = {
  loadSensorDetail: actions.loadSensorDetail,
  loadSensorStatus: actions.loadSensorStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(SensorDetailPage);
