import React, { useState, useEffect } from 'react';
import {
  Layout,
  PageHeader,
  Button,
  Form,
  message,
} from 'antd';
import CodeEditor from '@uiw/react-textarea-code-editor';
import { EntitySelect } from 'farmx-web-ui';
import { farmApi } from 'farmx-api';

export function RanchGeojsonCreatePage() {

  const [, setEntityId] = useState(null);
  const [, setRanchGeojson] = useState('');
  const [form] = Form.useForm();
  const [, forceUpdate] = useState();

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  function onFinish(values) {
    const { ranchGeojson, entityId } = values;
    farmApi.postRanchGeoJson({ ranchGeojson, entityId }).then(() => {
      message.success('Ranch created');
      form.resetFields();
    }, () => {
      message.error('Failed to create ranch. Please check your formatting.');
    });
  }

  return (
    <Layout className="create-ranch-page" style={{ overflowX: 'scroll' }}>
      <PageHeader
        title="Create Ranch from GeoJSON"
      />
      <div className="page-body">
        <Form
          form={form}
          onFinish={onFinish}
          className="page-form"
        >
          <Form.Item
            name="entityId"
            validateTrigger="onFinish"
            rules={[{
              required: true,
              message: 'Please select an Entity',
            }]}
          >
            <EntitySelect
              onSelect={setEntityId}
              placeholder="Select Entity"
            />
          </Form.Item>
          <Form.Item
            name="ranchGeojson"
            rules={[{
              required: true,
              message: 'Please enter ranch geojson',
            }]}
          >
            <CodeEditor
              language="json"
              placeholder="Please paste ranch GeoJSON here"
              onChange={(evn) => setRanchGeojson(evn.target.value)}
              padding={15}
              style={{
                fontSize: 12,
                backgroundColor: '#fff',
                color: '#bfbfbf',
                border: '1px solid #ddd',
                borderRadius: '2px',
                fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create Ranch
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Layout>
  );
}
