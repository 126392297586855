import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

import {
  Menu,
  Dropdown,
  Button,
} from 'antd';
import Icon, { DownOutlined } from '@ant-design/icons';

import {
  FaTimes,
} from 'react-icons/fa';

import { connect } from 'react-redux';
import { actions, selectors } from 'farmx-redux-core';

import { SensorActionCellRenderer } from './cellRendererComponents';
import StatsPage from './StatsPage';

const { loadCavalierStatsList } = actions;
const {
  selectAllCavalierStats,
  selectLoadingCavalierStats,
} = selectors;

const allColumns = [
  'identifier',
  'sensor_sub_type',
  'visible',
  'install_state',
  'commissioned',
  'up',
  'offline_for_24hrs',
  'offline_for_2hrs',
  'graph',
  'entity',
  'ranch',
  'block',
  'gateway',
  'location',
  'fw_revision',
  'hw_revision',
  'data_count',
  'data_latest_date',
  'latest_date_posted',
  'last_service_date',
  'rssi',
  'battery_voltage_min',
  'cap_charge',
  'serial',
  'sensors',
  'errors',
  'notes',
  'images',
  'maintenance',
  'sensorActions',
];

const productionTestingColumns = [
  'identifier',
  'sensor_sub_type',
  'fw_revision',
  'hw_revision',
  'data_count',
  'sensors',
  'data_latest_date',
  'battery_voltage_med',
  'rssi',
  'gateway',
  'install_state',
  // other columns
  'visible',
  'commissioned',
  'up',
  'offline_for_24hrs',
  'offline_for_2hrs',
  'graph',
  'entity',
  'ranch',
  'block',
  'location',
  'latest_date_posted',
  'battery_voltage_min',
  'serial',
  'errors',
  'notes',
  'images',
  'maintenance',
  'sensorActions',
];

class NodeStatsPage extends Component {
  filterPresetMenu = (
    <Menu>
      <Menu.Item key="offline" onClick={() => this.setFilterOffline()}>Offline</Menu.Item>
      <Menu.Item key="online" onClick={() => this.setFilterOnline()}>Online</Menu.Item>
      <Menu.Item key="visible" onClick={() => this.setFilterVisible()}>Visible</Menu.Item>
      <Menu.Item key="prod-test" onClick={() => this.setFilterProductionTesting()}>Production Testing</Menu.Item>
    </Menu>
  );

  constructor(props) {
    super(props);

    const params = new URLSearchParams(props.location.search);
    const urlFilters = Array.from(params.entries(), ([key, value]) => (
      { key, value }
    )).reduce((obj, item) => ({
      ...obj,
      [item.key]: {
        filterType: 'text',
        type: 'equals',
        filter: item.value,
      },
    }), {});

    const defaultFilters = {
      visible: {
        filterType: 'text',
        type: 'equals',
        filter: 'yes',
      },
      install_state: {
        filterType: 'text',
        type: 'equals',
        filter: 'installed',
      },
    };

    const filters = Array.from(params).length ? urlFilters : defaultFilters;

    this.state = {
      columnsVisible: allColumns,
      filters,
      sort: null,
    };
  }

  filtersChanged = (filters) => {
    this.setState({
      filters,
    });
  }

  setFilterOnline = () => {
    this.setState({
      columnsVisible: allColumns,
      filters: {
        offline_for_24hrs: {
          filterType: 'text',
          filter: 'no',
          type: 'equals',
        },
        visible: {
          filterType: 'text',
          filter: 'yes',
          type: 'equals',
        },
      },
    });
  }

  setFilterOffline = () => {
    this.setState({
      columnsVisible: allColumns,
      filters: {
        offline_for_24hrs: {
          filterType: 'text',
          filter: 'yes',
          type: 'equals',
        },
        visible: {
          filterType: 'text',
          filter: 'yes',
          type: 'equals',
        },
      },
    });
  }

  setFilterVisible = () => {
    this.setState({
      columnsVisible: allColumns,
      filters: {
        visible: {
          filterType: 'text',
          filter: 'yes',
          type: 'equals',
        },
      },
    });
  }

  setFilterProductionTesting = () => {
    this.setState({
      filters: {},
      columnsVisible: productionTestingColumns,
    });
  }

  clearFilters = () => {
    this.setState({
      columnsVisible: allColumns,
      filters: {},
    });
  }

  render() {
    const { filters, sort, columnsVisible } = this.state;
    const {
      lastUpdated,
      data,
      loading,
      loadData,
    } = this.props;
    const columns = allColumns;
    const clearDisabled = !(Object.keys(filters).length || !isEqual(columnsVisible, allColumns));
    return (
      <StatsPage
        title="Node Status"
        columns={columns}
        columnsVisible={columnsVisible}
        loadData={loadData}
        data={lastUpdated ? data : undefined}
        loading={loading}
        lastUpdated={lastUpdated}
        filters={filters}
        filtersChangedCallback={this.filtersChanged}
        sort={sort}
        headerContent={(
          <span className="header-btns">
            <Button type="primary" onClick={this.clearFilters} disabled={clearDisabled}>
              <Icon component={FaTimes} />
              <span>Clear Filters</span>
            </Button>
            <Dropdown overlay={this.filterPresetMenu}>
              <Button>
                Presets
                {' '}
                <DownOutlined />
              </Button>
            </Dropdown>
          </span>
        )}
        frameworkComponents={{
          sensorActionsMenu: SensorActionCellRenderer,
        }}
      />
    );
  }
}

NodeStatsPage.propTypes = {
  lastUpdated: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  loadData: PropTypes.func,
  location: PropTypes.shape({
    state: PropTypes.shape({}),
    pathname: PropTypes.string,
    search: PropTypes.string,
  }),
};

NodeStatsPage.defaultProps = {
  lastUpdated: null,
  data: null,
  loading: false,
  loadData: () => { },
  location: {
    search: '',
    pathname: '',
    state: {
      showBack: false,
      sensor: null,
    },
  },
};

const mapStateToProps = (state) => {
  const cavalierStats = selectAllCavalierStats(state);
  const cavalierStatsLoading = selectLoadingCavalierStats(state);
  const { loading, lastUpdated } = cavalierStatsLoading;
  return {
    loading,
    lastUpdated,
    data: cavalierStats,
  };
};

const mapDispatchToProps = {
  loadData: loadCavalierStatsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(NodeStatsPage);
